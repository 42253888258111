<!--
- Paper Edit > Editor Tab > Refs & Cits Drawer > Ref Row > Citation Edit
-- this needs two tabs for turabian 9
-->

<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title
				title="Edit Citation"
				close-to-route-name="EditorReferencesCitations" />
		</div>

		<div class="drawer-body">

			<div v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.Turabian9" key="divFootnote2">
				<nav-tabs class="mb-3">
					<nav-tab
						:to="{
							name:'EditorCitationEdit',
							params: {
								citationUid: findCitation.citationData.citationUniqueID,
								openCitationTab: $enums.CitationTab.CITATION,
							}
						}">
						Citation
					</nav-tab>
					<nav-tab
						:to="{
							name:'EditorCitationEdit',
							params: {
								citationUid: findCitation.citationData.citationUniqueID,
								openCitationTab: $enums.CitationTab.FOOTNOTE,
							}
						}">
						Footnote Text
					</nav-tab>
				</nav-tabs>

				<div v-show="$route.params.openCitationTab === $enums.CitationTab.FOOTNOTE" key="tabFootnote">
					<div ref="divHeader">
						<!-- <p>Something to explain what this is and how to use it.</p> -->
					</div>
					
					<div class="p-relative">
						<footnote-editor-ribbon />
						<textarea id="ckPaperFootnotes" :value="thisFootnoteContent" autocomplete="off" class="d-none" />
					</div><!--p-relative-->

					<div class="row mt-3" ref="divFooter">
						<div class="col-6">
							<div class="d-grid">
								<button class="btn btn-outline-danger" type="button" @click.prevent="btnFootnoteCancel">
									Cancel
								</button>
							</div>
						</div>
						<div class="col-6">
							<div class="d-grid">
								<button type="submit" class="btn btn-success" @click.prevent="btnUpdateFootnote">
									Update
								</button>
							</div>
						</div>
					</div>
					
				</div><!--tabFootnote-->

				<div v-show="$route.params.openCitationTab === $enums.CitationTab.CITATION" key="tabCitation">
					<form>
						<citation-form
							:load-citation-data="findCitation"
							:paper-format-version-id="paperFormatVersionID"
							:reference-unique-id="referenceUniqueId"
							@enableCitationFormButton="isDisabled = false"
							@disableCitationFormButton="isDisabled = true" />

						<div class="row">
							<div class="col-6">
								<div class="d-grid">
									<router-link 
											:to="{name: 'EditorReferencesCitations'}"
											class="btn btn-outline-danger">
											Cancel
									</router-link>
								</div>
							</div>
							<div class="col-6">
								<div class="d-grid">
									<button type="submit" class="btn btn-success" @click.prevent="btnUpdateCitation" :disabled="isDisabled">
										Update
									</button>
								</div>
							</div>
						</div>

					</form>
				</div><!--tabCitation-->

			</div><!--divFootnote2-->

			<div v-else key="divCitation2">
				<form>
					<citation-form
						:load-citation-data="findCitation"
						:paper-format-version-id="paperFormatVersionID"
						:reference-unique-id="referenceUniqueId"
						@enableCitationFormButton="isDisabled = false"
						@disableCitationFormButton="isDisabled = true" />

					<div class="row">
						<div class="col-6">
							<div class="d-grid">
								<router-link 
										:to="{name: 'EditorReferencesCitations'}"
										class="btn btn-outline-danger">
										Cancel
								</router-link>
							</div>
						</div>
						<div class="col-6">
							<div class="d-grid">
								<button type="submit" class="btn btn-success" @click.prevent="btnUpdateCitation" :disabled="isDisabled">
									Update Citation
								</button>
							</div>
						</div>
					</div>

				</form>
			</div><!--divCitation2-->
		</div>
		
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import CitationForm from '@/modules/CitationForm';
import config from '@/config';
import configCkEditor from '@/ck/config-ck-editor';
import PCKS_OnChange from '@/services/paper/ck/onChange';
import PCKS_OnFocus from '@/services/paper/ck/onFocus';
import ErrS_Reroute from '@/services/app/error/reroute.js';
import FootnoteEditorRibbon from './CitationEdit/Ribbon';
import NavTab from '@/components/NavTab';
import NavTabs from '@/components/NavTabs';
import PCKS_WrapCitation from '@/services/paper/ck/wrapCitation';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import PS_SaveDocument from '@/services/paper/saveDocument';
import TDS_Open from '@/services/paper/toolDrawer/open';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'EditorCitationEdit',
	data() {
		return {
			ckInstance: null,
			isDisabled: true,
			thisFootnoteContent: '',
			rzHeightOffsetFootnoteCkInstance: 450
		}
	},
	watch: {
		$route() {
			if(this.$store.state.paperEdit.config.renderCitationsAsFootnotes){
				this.loadFootnoteEditor();
			}
		}
	},
	computed:{
		isGroupCitation(){
			if(this.findCitation && _has(this.findCitation, 'groupUniqueID')){
				return true;
			}
			return false;
		},
		findCitation(){
			// look this citation up from the route params
			let returnCitationObject = {};

			this.$store.state.paperEdit.referencesInPaper.forEach((referenceObject)=>{
				if(_has(referenceObject, 'citations') && referenceObject.citations.length > 0){
					referenceObject.citations.forEach((citationObject)=>{
						if(citationObject.citationData.citationUniqueID.toUpperCase() === this.$route.params.citationUid.toUpperCase()){
							returnCitationObject = citationObject;
						}
					});
				}
			});//e:forEach

			return returnCitationObject;
		},
		paperFormatVersionID(){
			// on load the paper hasn't loaded it's meta data yet
			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === ''){
				return 0;
			}
			return this.$store.state.paperEdit.meta.PaperFormatVersionID;
		},
		referenceUniqueId(){
			if(this.findCitation && this.findCitation.citationData){
				return this.findCitation.citationData.referenceUniqueID.toUpperCase()
			}
			return '0';
		},
	},
	methods: {
		btnFootnoteCancel(){
			this.$router.push({
				name: 'EditorReferencesCitations'
			}).catch(()=>{});
		},//e:btnFootnoteCancel

		btnUpdateCitation(){
			// grab the temp citation data from citation form
			let newCitationResult = _cloneDeep(this.$store.state.paperEdit.citations.tempCitationData);

			try {
				newCitationResult.citationData.citationUniqueID = this.$route.params.citationUid.toUpperCase();
				newCitationResult.citationData.referenceUniqueID = this.referenceUniqueId;
			} catch {
				ErrS_Reroute({
					pushToName: 'EditorReferencesCitations'
				});

				return false;	// on catch stop
			}
			
			// clear tempCitationData now that you cloned it
			this.$store.commit('paperEdit/citations/SET_TEMP_CITATION_DATA', {});

			// calculate the citation value here so i can pass it to save message
			let citationOutputValue = '';
			if(this.paperFormatVersionID === config.enums.Format.APA7){
				// APA7 
				if(_has(newCitationResult, 'apa7') && _has(newCitationResult.apa7, 'first') && newCitationResult.apa7.first != ''){
					citationOutputValue = newCitationResult.apa7.first;
				}
			} else if(this.paperFormatVersionID === config.enums.Format.MLA9){
				// MLA9
				if(_has(newCitationResult, 'mla9') && _has(newCitationResult.mla9, 'first') && newCitationResult.mla9.first != ''){
					citationOutputValue = newCitationResult.mla9.first;
				}
			} else if(this.paperFormatVersionID === config.enums.Format.Turabian9){
				// Turabian9
				if(_has(newCitationResult, 'turabian9') && _has(newCitationResult.turabian9, 'first') && newCitationResult.turabian9.first != ''){
					citationOutputValue = newCitationResult.turabian9.first;
				}
			}

			//
			let findCitationIndex = this.$store.state.paperEdit.citations.inPaper.findIndex((citation)=>{
				return citation.citationData.citationUniqueID.toUpperCase() === this.$route.params.citationUid.toUpperCase();
			});
			
			if(findCitationIndex != -1){
				// console.log('newCitationResult');
				// console.log(newCitationResult);

				this.$store.commit('paperEdit/citations/UPDATE_AT_INDEX',{
					findIndex: findCitationIndex,
					newCitationResult: newCitationResult,
				});

				PCKS_WrapCitation({
					citationUniqueID: newCitationResult.citationData.citationUniqueID
				}).then(()=>{
					PS_BuildReferencesInPaper().then(()=>{
						PS_SaveDocument({
							isMetaDataChanges: true,
							message: 'Edited Citation - ' + citationOutputValue,
						}).then(()=>{
							this.$router.push({
								name: 'EditorReferencesCitations'
							}).catch(()=>{});
						});//e:PS_SaveDocument
					});//e:PS_BuildReferencesInPaper
				});//e:PCKS_WrapCitation
			}
		},//e:btnUpdateCitation

		btnUpdateFootnote(){
			// anything i need to do to process this 'save'
			// console.log('btnUpdateFootnote');

			if(!CKEDITOR.instances.ckPaperFootnotes){
				return false;
			}
			
			let footnoteContent = '';
			let ckInstnaceData = CKEDITOR.instances.ckPaperFootnotes.getData();
			
			if(ckInstnaceData === ''){
				// ckInstance is empty, user prob deleted everythhin
				footnoteContent = '<p>@@@</p>'
			} else {
				// find the only citation instance in the footnote editor
				let citationElement = CKEDITOR.instances.ckPaperFootnotes.document.findOne('span.perrla_citation');
				if(citationElement && citationElement.getParent()){
					// replace the citation widget with a text node
					let textNode = new CKEDITOR.dom.text(document.createTextNode('@@@'));
					textNode.replace(citationElement.getParent());
					footnoteContent = CKEDITOR.instances.ckPaperFootnotes.getData();
					
				} else {
					// a valid citation element was not found - the user could have deleted it - maybe put the @@@ at the end then
					let footnoteEditorData = CKEDITOR.instances.ckPaperFootnotes.getData();
					footnoteContent = footnoteEditorData.substring(0, footnoteEditorData.length - 4);	// remove the last four characters -  </p>
					footnoteContent += '@@@';
					footnoteContent += '</p>';	// put the end tag back on

				}//e:if
			}//e:if

			// console.log('footnoteContent');
			// console.log(footnoteContent);

			let passFindById;	// use this to pass group or single UID
			
			if(_has(this.findCitation, 'groupUniqueID')){
				// group citation
				passFindById = this.findCitation.groupUniqueID;
			} else {
				// single citation
				passFindById = this.findCitation.citationData.citationUniqueID;
			}


			this.$store.dispatch('paperEdit/footnotes/updateContentAtCitationUid', {
				findById: passFindById,
				newContent: footnoteContent
			}).then(()=>{
				PS_BuildReferencesInPaper().then(()=>{
					PS_SaveDocument({
						message: 'Saved paper - edit footnote text',
					}).then(()=>{
						this.$router.push({
							name: 'EditorReferencesCitations'
						});
					});//e:PS_SaveDocument
				});//e:PS_BuildReferencesInPaper
			});//e:dispatch:paperEdit/footnotes/updateContentAtCitationUid

		},//e:btnUpdateFootnote

		loadFootnoteEditor(){
			// console.log('loadFootnoteEditor');

			if(!_isEmpty(this.findCitation)){
				// console.log(this.findCitation);

				let findFootnoteIndex = -1;

				if(_has(this.findCitation, 'groupUniqueID')){
					// group citation
					findFootnoteIndex = this.$store.state.paperEdit.footnotes.inPaper.findIndex((footnoteObject)=>{
						return footnoteObject.findById.toUpperCase() === this.findCitation.groupUniqueID.toUpperCase();
					});

				} else {
					// single citation
					findFootnoteIndex = this.$store.state.paperEdit.footnotes.inPaper.findIndex((footnoteObject)=>{
						return footnoteObject.findById.toUpperCase() === this.findCitation.citationData.citationUniqueID.toUpperCase();
					});
				}
				
				if(findFootnoteIndex > -1){
					// console.log('findFootnoteIndex');
					// console.log(findFootnoteIndex);
					let citationValue = this.findCitation.displayValue;

					let prepString = this.$store.state.paperEdit.footnotes.inPaper[findFootnoteIndex].content.replace('@@@', ' <span class="perrla_citation">' + citationValue + '</span>');
					
					this.thisFootnoteContent = prepString.slice(0, 3) + (findFootnoteIndex + 1) + '.&nbsp;' + prepString.slice(3);

					if(CKEDITOR.instances.ckPaperFootnotes){
						// Ck Instance - set the content
						CKEDITOR.instances.ckPaperFootnotes.setData(this.thisFootnoteContent);
					
					} else {
						// No Ck Instance - make one
						let configCkPaperFootnotes = configCkEditor.getSettings('ckPaperFootnotes');
						let $this = this;

						configCkPaperFootnotes.on = {
							change(evt) {
								PCKS_OnChange({
									editorName: evt.editor.name,
								});
							},//e:change

							focus(evt) {
								PCKS_OnFocus({
									editorName: evt.editor.name,
								});
							},//e:focus

							instanceReady(evt) {
								evt.editor.$ckEditorType = config.enums.CkEditorType.FOOTNOTES;

								CKEDITOR.on('dialogDefinition', function (ev) {
									ev.data.definition.resizable = CKEDITOR.DIALOG_RESIZE_NONE;
								});

								$this.emitter.emit('setCkFootnoteEditorHeight');
							},//e:instanceReady

						}//e:on
						
						this.ckInstance = CKEDITOR.replace('ckPaperFootnotes', configCkPaperFootnotes);

					}//e:if
				}//e:if:findFootnoteObject
			}//e:!isEmpty:this.findCitation
		},//e:loadFootnoteEditor
		
		setCkFootnoteEditorHeight(){
			// rollbar error about id not being found here - so just add a check to make sure
			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9 && _has(this.ckInstance, 'id')){
				let footnoteEditorInstance = document.getElementById(this.ckInstance.id + '_contents');
				let $divHeader = this.$refs.divHeader;
				let $divFooter = this.$refs.divFooter;

				if(footnoteEditorInstance && $divHeader && $divFooter){
					footnoteEditorInstance.style.height = document.documentElement.clientHeight - $divHeader.clientHeight - $divFooter.clientHeight - this.rzHeightOffsetFootnoteCkInstance + 'px';
				}
			}
		},//e:setCkFootnoteEditorHeight
	},
	created(){
		this.emitter.on('resizeWindow',()=>{
			this.setCkFootnoteEditorHeight();
		});
		
		this.emitter.on('setCkFootnoteEditorHeight',()=>{
			this.setCkFootnoteEditorHeight();
		});
		
		this.emitter.on('saveOpenFootnote', ()=>{
			this.btnUpdateFootnote();
		});
	},
	mounted(){
		if(_isEmpty(this.findCitation)){
			// a citation wasn't found, so i shouldn't try to load anything else
			this.$router.push({
				name: 'EditorReferencesCitations'
			}).catch(()=>{});
			
		} else {
			// citation was found go as normal
			if(this.$store.state.paperEdit.toolDrawer.isOpen){
				if(this.$store.state.paperEdit.config.renderCitationsAsFootnotes && this.$route.params.openCitationTab === config.enums.CitationTab.FOOTNOTE){
					this.$nextTick(()=>{
						this.loadFootnoteEditor();
					});	
				}
	
			} else {
				TDS_Open().then(()=>{
					if(this.$store.state.paperEdit.config.renderCitationsAsFootnotes && this.$route.params.openCitationTab === config.enums.CitationTab.FOOTNOTE){
						this.$nextTick(()=>{
							this.loadFootnoteEditor();
						});
					}
				});
			}
		}
	},
	components: {
		CitationForm,
		FootnoteEditorRibbon,
		NavTab,
		NavTabs,
		ToolDrawerTitle
	},
	destroyed(){
		this.emitter.off('resizeWindow');
		this.emitter.off('setCkFootnoteEditorHeight');
		this.emitter.off('saveOpenFootnote');

		if(CKEDITOR.instances.ckPaperFootnotes){
			CKEDITOR.instances.ckPaperFootnotes.focusManager.blur(true);
			CKEDITOR.instances.ckPaperFootnotes.destroy(true);
		}
	}
}
</script>
