<!--
- Paper Edit > Generic Modal > Page Count 
-->

<template>
	<generic-modal-wrap modal-id="PageCount">
		<h2 class="nodal-title">
			Page Count
		</h2>

		<table class="table table-border table-condensed mb-0" v-if="passData && passData.pageCount">
			<tbody>
				<tr>
					<td>Title Page</td>
					<td class="text-end">
						{{ passData.pageCount.titlePage }}
					</td>
				</tr>
				<tr>
					<td>Abstract</td>
					<td class="text-end">
						{{ passData.pageCount.abstract }}
					</td>
				</tr>
				<tr>
					<td>Body</td>
					<td class="text-end">
						{{ passData.pageCount.body }}
					</td>
				</tr>
				<tr>
					<td>References</td>
					<td class="text-end">
						{{ passData.pageCount.references }}
					</td>
				</tr>
				<tr>
					<td>Appendices</td>
					<td class="text-end">
						{{ passData.pageCount.appendices }}
					</td>
				</tr>
				<tr :class="{'bg-success-100': $store.state.customer.themeId === $enums.Theme.REGULAR, 'bg-success-700': $store.state.customer.themeId === $enums.Theme.DARK}">
					<td><strong>Total</strong></td>
					<td class="text-end">
						<strong>{{ passData.pageCount.total }}</strong>
					</td>
				</tr>
			</tbody>
		</table>
	</generic-modal-wrap>
</template>

<script lang="js">
import _isEmpty from 'lodash/isEmpty';
import GenericModalWrap from '@/components/modals/GenericWrap.vue';

export default {
	name: 'ModalPageCount',
	computed:{
		passData(){
			if(!_isEmpty(this.$store.state.modals.passData)){
				return this.$store.state.modals.passData;
			}
			return null;
		},
	},
	components:{
		GenericModalWrap
	},
}
</script>
