<!--
- Footnotes
-- Appears at the bottom of the Body for Turabian 9 Papers
-- collection of footnote rows
-- citations can get added to the Source of a table, if so they don't count as real footnotes and won't appear here
-->

<template>
	<div id="paperFootnotesBlock" v-show="$store.state.paperEdit.footnotes.inPaper.length > 0">
		<paper-footnotes-row
			v-for="(footnoteData, index) in arrayOfFootnoteToDisplay"
			:key="index"
			:footnote-data="footnoteData"
			:footnote-index="index" />
	</div>
</template>

<script>
import config from '@/config';
import PaperFootnotesRow from './Footnotes/Row';

export default {
	name: 'PaperFootnotesBlock',
	data(){
		return {
			arrayOfFootnoteToDisplay: [],
			paperFootnotesBlockElement: null,
		}
	},
	methods:{
		calcFootnotes(){
			this.arrayOfFootnoteToDisplay = [];
			
			if(CKEDITOR.instances.ckPaperBody){
				// loop through all the footnotes - remove any that are in the Source

				this.$store.state.paperEdit.footnotes.inPaper.forEach((footnoteData)=>{
					// find this citation in the document

					// look for group first
					let citationElement = CKEDITOR.instances.ckPaperBody.document.findOne('span[data-group-unique-id="' + footnoteData.findById + '"]');

					if(!citationElement){
						// if not found check for single
						citationElement = CKEDITOR.instances.ckPaperBody.document.findOne('span[data-citation-unique-id="' + footnoteData.findById + '"]');
					}
					
					this.arrayOfFootnoteToDisplay.push(footnoteData);
				});
			}//e:if

			// set footnote margin top
			
			// get current height of the instancePaperBody
			let $instancePaperBody  = document.getElementById('instancePaperBody');
			if($instancePaperBody){
				if(this.$store.state.paperEdit.meta.DocumentType == config.enums.DocumentType.DISCUSSION_POST){
					// Disc Post don't need an auto page height
					this.paperFootnotesBlockElement.style.marginTop = '100px';

				} else {
					let rect = $instancePaperBody.getBoundingClientRect();
					// 975 is the min-height setting so if this is over that find out by how much and set it
					if(rect.height <= 975){
						this.paperFootnotesBlockElement.style.marginTop = (975 - rect.height) + 'px';
					}
				}
			}
		},//calcFootnotes
	},
	created(){
		this.emitter.on('calculateFootnoteDisplay', ()=>{
			this.calcFootnotes();

		});//e:calculateFootnoteDisplay
	},
	mounted(){
		this.paperFootnotesBlockElement = document.getElementById('paperFootnotesBlock');

		if(this.paperFootnotesBlockElement){

			this.paperFootnotesBlockElement.addEventListener('contextmenu', (e) =>{
				let closestElement = e.target.closest('.paper-footnote-single');

				if(closestElement && closestElement.classList.contains('paper-footnote-single')){

					let citationFindById = closestElement.getAttribute('citation-find-by-id');

					if(citationFindById){
						e.preventDefault();
						
						this.emitter.emit('showPaperFootnoteContextMenu',{
							findById: citationFindById,
						});
					}
					
				}
				
			}, false);
		}
	},
	components: {
		PaperFootnotesRow,
	},
	destroyed(){
		this.emitter.off('calculateFootnoteDisplay');
	},
}
</script>


<style lang="scss" scoped>
	@import "../../../assets/styles/_vars_perrla.scss"; 

	#paperFootnotesBlock {
		background: $white;
		border-top: 1px solid $body-color;
		padding-top: 4px;
	}
</style>