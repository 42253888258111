<!--
- Paper Write > Write Tab
-->

<template>
	<nav-tab-content :is-no-padding="true">
		<editor-filebar />
 
		<editor-ribbon />

		<div class="float-start w-100" ref="paperDocumentOut" :style="calcPaperDocumentOutStyles">
			<div :class="calcPaperDocumentClasses" ref="paperDocumentIn" :style="calcPaperDocumentInStyles" id="paperDocumentIn">
				
				<citation-context-menu
					v-if="$store.state.customer.citationAutoInsert" />

				<div v-if="paperFormatVersionID === $enums.Format.APA7" key="formatAPA7">
					<paper-title-page v-if="documentType == $enums.DocumentType.RESEARCH_PAPER" />
					<paper-abstract v-if="documentType == $enums.DocumentType.RESEARCH_PAPER" />
					<paper-toc v-if="documentType == $enums.DocumentType.RESEARCH_PAPER" />
					<paper-body v-if="documentType == $enums.DocumentType.DISCUSSION_POST || documentType == $enums.DocumentType.RESEARCH_PAPER" />
					<paper-reference-page v-if="documentType != $enums.DocumentType.DISCUSSION_POST" />
					<paper-appendices v-if="documentType == $enums.DocumentType.RESEARCH_PAPER" />
				</div>
				<div v-else-if="paperFormatVersionID === $enums.Format.MLA9" key="formatMLA9">
					<div class="page-group page-group-min-height page-group-mla9" v-if="documentType == $enums.DocumentType.DISCUSSION_POST || documentType == $enums.DocumentType.RESEARCH_PAPER ">
						<paper-title-page v-if="documentType == $enums.DocumentType.RESEARCH_PAPER" />
						<paper-abstract v-if="documentType == $enums.DocumentType.RESEARCH_PAPER" />
						<paper-toc v-if="documentType == $enums.DocumentType.RESEARCH_PAPER" />
						<paper-body v-if="documentType == $enums.DocumentType.DISCUSSION_POST || documentType == $enums.DocumentType.RESEARCH_PAPER" />
					</div>
					<paper-reference-page v-if="documentType != $enums.DocumentType.DISCUSSION_POST" />
					<paper-appendices v-if="documentType == $enums.DocumentType.RESEARCH_PAPER" />
				</div>
				<div v-else-if="paperFormatVersionID === $enums.Format.Turabian9" key="formatTurabian9">
					<paper-title-page v-if="documentType == $enums.DocumentType.RESEARCH_PAPER" />
					<paper-abstract v-if="documentType == $enums.DocumentType.RESEARCH_PAPER" />
					<paper-toc v-if="documentType == $enums.DocumentType.RESEARCH_PAPER" />
					<paper-body v-if="documentType == $enums.DocumentType.DISCUSSION_POST || documentType == $enums.DocumentType.RESEARCH_PAPER" />
					
					<paper-appendices v-if="documentType == $enums.DocumentType.RESEARCH_PAPER" />

					<paper-reference-page v-if="documentType != $enums.DocumentType.DISCUSSION_POST" />
					
				</div>
			</div><!--paperDocumentIn-->
		</div><!--paperDocumentOut-->

		<tool-drawer :is-editor="true" />

		<paper-footer v-if="$store.state.paperEdit.isPaperFooterOn" />

		<modal-page-count />
		<modal-paper-source />
		<modal-replace-figure />
		<modal-stale-paper />
		<modal-word-count />
		
		<paper-footnotes-dropdown />
		

	</nav-tab-content>
</template>

<script>
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import CitationContextMenu from '@/views/Paper/TabEditor/CitationContextMenu';
import config from '@/config';
import EditorFilebar from './TabEditor/Filebar';
import EditorRibbon from './TabEditor/Ribbon';
import ErrS_LoadByRoute from '@/services/app/error/load-by-route';
import ModalPageCount from './modals/PageCount';
import ModalPaperSource from './modals/PaperSource';
import ModalReplaceFigure from './modals/ReplaceFigure';
import ModalStalePaper from './modals/StalePaper';
import ModalWordCount from './modals/WordCount';
import Mousetrap from 'mousetrap';
import NavTabContent from '@/components/NavTabContent';
import PaperAbstract from './TabEditor/Abstract';
import PaperAppendices from './TabEditor/Appendices';
import PaperBody from './TabEditor/Body';
import PaperFooter from './TabEditor/Footer';
import PaperFootnotesDropdown from './TabEditor/Footnotes/Dropdown';
import PaperTitlePage from './TabEditor/TitlePage';
import PaperToc from './TabEditor/Toc';
import PaperReferencePage from './TabEditor/ReferencePage';
import PS_DownloadDocumentVersionV4 from '@/services/paper/downloadDocumentVersionV4';
import PS_InjectContent from '@/services/paper/injectContent';
import PS_LoadByRoute from '@/services/paper/loadByRoute';
import PS_ParseHiddenTags from '@/services/paper/parseHiddenTags';
import PS_SaveDocument from '@/services/paper/saveDocument';
import ToolDrawer from '@/components/ToolDrawer/ToolDrawer';

export default {
	name: 'TabEditor',
	computed:{
		calcPaperDocumentClasses(){
			let returnArray = ['paper-document-wrp overflow-y-auto overflow-x-hidden p-relative'];
			
			if(this.isDrawerOpen){
				returnArray.push('drawer-open');
			}

			switch(parseInt(this.$store.state.paperEdit.meta.FontFamily)){
				case 1:
					returnArray.push('font-family-arial');
					break;
				case 2:
					returnArray.push('font-family-courier');
					break;
				case 4:
					returnArray.push('font-family-trebuchet');
					break;
				case 5:
					returnArray.push('font-family-verdana');
					break;
				default: 
					returnArray.push('font-family-times-new-roman');
					break;
			}
			return returnArray;
		},
		calcPaperDocumentInStyles(){
			if(this.$store.state.paperEdit.toolDrawer.isOpen){
				return {
					marginRight: this.$store.state.paperEdit.toolDrawer.openWidth + 'px',
				}
			}
			return {
				marginRight: this.$store.state.paperEdit.toolDrawer.closedWidth + 'px',
			}
		},
		calcPaperDocumentOutStyles(){
			if(this.$store.state.paperEdit.toolDrawer.isOpen){
				return {
					marginRight: '-' + this.$store.state.paperEdit.toolDrawer.openWidth + 'px',
				}
			}
			return {
				marginRight: '-' + this.$store.state.paperEdit.toolDrawer.closedWidth + 'px',
			}
		},
		documentType(){
			return this.$store.state.paperEdit.meta.DocumentType;
		},
		isDrawerOpen(){
			return this.$store.state.paperEdit.toolDrawer.isOpen;
		},
		paperFormatVersionID(){
			return this.$store.state.paperEdit.meta.PaperFormatVersionID;
		},
	},
	methods: {
		loadPaperFromServer(){
			ApiS_MakeCall({
				method: 'GET',
				params: {
					contentType: config.enums.DownloadPaperContentType.COMPRESSED,
					encryptedId: this.$store.state.paperEdit.openObject.encryptedID,
					platform: 1, // just Online Papers here
				},
				responseType: 'text',
				url: 'v4/Paper',
			}).then((responseData)=>{
				PS_InjectContent({
					compressedPaperData: responseData,
				});
			});//e:ApiS_MakeCall

		},//e:loadPaperFromServer
	},
	created() {
		this.emitter.on('hidePaperSource', ($opts)=>{
			// parse the paper content from hidden tags
			PS_ParseHiddenTags($opts.documentAsHtml).then(()=>{
				if(CKEDITOR.instances.ckPaperBody){
					CKEDITOR.instances.ckPaperBody.setData($opts.documentAsHtml);
				}
			});
		});

		// Print Document
		Mousetrap.bind(['command+p', 'ctrl+p'], () => {
			PS_SaveDocument({
				message: 'Saved - CTRL+P',
			}).then((response)=>{
				PS_DownloadDocumentVersionV4({
					contentType: config.enums.DownloadPaperContentType.PDF,
					isPrint: true,
					platform: 1, // just Online Papers here
				});
			});
			return false;
		});

		// Save Document
		Mousetrap.bind(['command+s', 'ctrl+s'], () => {
			PS_SaveDocument({
				message: 'Saved - CTRL+S',
			}).then((response)=>{
				window.$vm.emitter.emit('globalToasterOpen',{
					textContent:' Paper changes saved',
				});
			});
			return false;
		});
		
		// allow Save Shortcut key to work when inside any field (like keywords for abstract)
		Mousetrap.prototype.stopCallback = () => {
			return false;
		}

	},
	mounted() {
		PS_LoadByRoute().then(()=>{
			// check indexedDb to see if this paper's content is fresh 
			const openRequest = indexedDB.open('po_papers', 2);

			// triggers if the client had no database
			openRequest.onupgradeneeded = () => {
				// console.log('indb:onupgradeneeded 1');
				let db = openRequest.result;

				if(!db.objectStoreNames.contains('papers')){
					db.createObjectStore('papers', {
						autoIncrement: true,	// people online says this will fix an error i'm seeing in rollbar - i can't replicate to error to verify, changing this doesn't seem to matter locally
						keyPath: 'paperId'
					});
				}
			};//e:onupgradeneeded

			openRequest.onerror = () => {
				// console.log('indb:onerror 1');
				// something went wrong trying to access local storage, just load the paper via api
				this.loadPaperFromServer();
			};
			
			openRequest.onsuccess = () => {
				// console.log('indb:success po_papers 1');
				let db = openRequest.result;

				if(db.objectStoreNames.contains('papers')){

					const txn = db.transaction(['papers'], 'readonly');

					const store = txn.objectStore('papers');
					
					// simulate clearing the paper open object (for testing the block right after it)
					// this.$store.commit('paperEdit/SET_OPEN_OBJECT', {});

					// sometimes in rollbar an error comes in about store.get not having a parameter, check and fix that first
					if(!_has(this.$store.state.paperEdit.openObject, 'paperID')){
						// console.log(this.$store.state.paperEdit.openObject.paperID);
						// look up the paper again (this is a copy of LoadByRoute but roll with it for now)
						let findPaper = this.$store.state.paperList.list.find((paperData)=>{
							return paperData.encryptedID === window.$vm.$route.params.encryptedId
						});

						if(findPaper){
							// set this as the open object so indexedDb will pick up the paperID
							// console.log(findPaper);
							this.$store.commit('paperEdit/SET_OPEN_OBJECT', findPaper)
						}
					}
				
					let request = store.get(this.$store.state.paperEdit.openObject.paperID);

					request.onsuccess = () => {
						let requestResult = request.result;

						if(requestResult && _has(requestResult, 'isFresh')){
							if(requestResult.isFresh){
								// Fresh Paper
								this.loadPaperFromServer();

							} else {
								// Stale Paper - show a modal asking the user which option they would like
								this.$store.commit('loaders/REMOVE_ID', 'App');

								this.$store.commit('modals/OPEN', 'StalePaper');
								this.$store.commit('modals/SET_MODAL_DATA', {
									localContentToLoad: requestResult.content,
								});
							}

						} else {
							this.loadPaperFromServer();

						}//e:if

					};//e:onsuccess
				
					// close the database connection
					txn.oncomplete = () => {
						db.close();
					};
					
				} else {
					// indexedDB isn't found
					console.log("indexedDB isn't found");

					this.loadPaperFromServer();

				}//e:contains('papers'))

			};//e:onsuccess
			
		}).catch((error)=>{
			// paper not found by the route (usally the user getting cute with the browsers back button)
			ErrS_LoadByRoute({
				entityType: 'Paper',
				error: 'paper not found by its uid',
			});
			
		});//e:PS_LoadByRoute.then
	},
	beforeRouteLeave (to, from, next) {
		let isSaveBeforeLeaving = false;

		// loop through all active ckEditor Instances (Body, Abstract, Appendices, Annotations, etc)
		_forEach(CKEDITOR.instances, (ckInstance)=>{
			if(ckInstance.checkDirty()){
				isSaveBeforeLeaving = true;
				return false;	// only need to find one to trigger a save
			}
		});

		if(isSaveBeforeLeaving){
			this.$store.commit('loaders/ADD_ID', 'App');
			
			PS_SaveDocument({
				isMetaDataChanges: false,
				isStealthSave: true,
				message: 'Saved paper - on exit',
			}).then(()=>{
				setTimeout(()=>{
					this.$store.commit('loaders/REMOVE_ID', 'App');
					next();
				}, 180); // the timeout here helps the overview tab get the first item in the list to load it's action properly, not sure why
			});	
		} else {
			next();
		}
	},
	destroyed() {
		this.emitter.off('hidePaperSource');
		this.$store.commit('paperEdit/localVersion/USER_TYPE_LOCAL_TIMER_CANCEL');

		Mousetrap.unbind(['command+p', 'ctrl+p']);
		Mousetrap.unbind(['command+s', 'ctrl+s']);
	},
	
	components: {
		CitationContextMenu,
		EditorFilebar,
		EditorRibbon,
		ModalPageCount,
		ModalPaperSource,
		ModalReplaceFigure,
		ModalStalePaper,
		ModalWordCount,
		NavTabContent,
		PaperAbstract,
		PaperAppendices,
		PaperBody,
		PaperFooter,
		PaperFootnotesDropdown,
		PaperReferencePage,
		PaperTitlePage,
		PaperToc,
		ToolDrawer,
	}
}
</script>
