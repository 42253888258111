<!--
- Contact Support
-- first view to use the Front End Layout
-->

<template>
	<div :class="{'layout-pcom': $store.state.app.layoutEnum === $enums.Layout.PCOM}">
		<section class="section-text">
			<transition name="route-fade" mode="out-in">
				<main class="container-text mt-5" v-if="isFormSent" key="divSent">
					<div class="text-center">
						<br />
						<span class="svg svg-sent bi-check-lg text-success" />
						<h3 class="mt-0">
							Thank you! Your submission has been received!
						</h3>
						<p class="small">
							While you wait for our team to write back, you can always&nbsp;<a href="https://www.perrla.com/help">search our Help Center</a>&nbsp;for solutions &amp;&nbsp;tutorials.
						</p>
						<p class="small">
							Learn more about our amazing Support Team below 👇
						</p>
						<br />
					</div>
				</main>
			
				<main class="container-text mt-5" v-else key="divNotSent">
					<div class="text-center ">
						<h3>Contact Support</h3>
						<p class="small">
							We're serious about our support. Tell us how we can help and we'll get back to you lickety-split!
						</p>
					</div>

					<div class="container">
						<div class="row">
							<div class="col-sm-8 mx-auto">
								<form>
									<form-input
										label="Your Name"
										v-model="yourName"
										:form-id="zFormId"
										:is-horizontal="true"
										:validate-enum="$enums.Validation.REQUIRED" />

									<form-input
										label="Email"
										v-model="email"
										:form-id="zFormId"
										:is-horizontal="true"
										:validate-enum="$enums.Validation.EMAIL_REQUIRED" />
															
									<form-select
										label="Topic"
										:form-id="zFormId"
										:is-horizontal="true"
										:select-object="topicList"
										:validate-enum="$enums.Validation.REQUIRED"
										v-model="topic" />

									<form-textarea
										label="Message"
										:form-id="zFormId"
										:is-autosize="true"
										:is-horizontal="true"
										:validate-enum="$enums.Validation.REQUIRED"
										set-min-rows="9"
										v-model="message" />

									<div class="form-group row" v-if="$store.state.app.layoutEnum === $enums.Layout.PCOM">
										<label class="col-sm-3 col-form-label">&nbsp;</label>
										<div class="col-sm-9">
											<recaptcha />
											<div class="invalid-feedback d-block" v-if="isInvalidRecaptcha">
												Please verify that you are not a robot.
											</div>
										</div>
									</div>

									<div class="form-group row">
										<div class="col-sm-8 mx-auto">
											<div class="d-grid">
												<button type="submit" class="btn btn-success" @click.prevent="btnSubmitRequest">
													Submit Request
												</button>
											</div>
										</div>
									</div>
								</form>
							</div><!--col-->
						</div><!--row-->
					</div><!--container-->
				</main>
			</transition>
		</section>

		<section :class="calcSectionTextClasses">
			<main class="container-text mt-5">
				<div class="text-center mb-3">
					<h2>Our Awesome Support Team</h2>
					<img class="img-fluid" src="/static/images/support-team.png" role="presentation" />
				</div>

				<div class="row">
					<div class="col-6">
						<h4 class="text-center">
							When we work
						</h4>
						<p class="small">
							Our Support Team is available every day until 10pm Central Time &ndash;&nbsp;when most students are actually writing papers.
						</p>
						<p class="small">
							<strong>Monday &ndash; Friday</strong>:&nbsp;10am - 10pm Central<br />
							<strong>Saturday &amp;&nbsp;Sunday</strong>:&nbsp;1pm - 10pm Central
						</p>
						<br /><br />
						<h4 class="text-center">
							How we work
						</h4>
						<p class="small">
							Our responses are always quick! If you haven&#39;t heard back from us within a few hours&nbsp;(during our working hours), check your email&#39;s spam folder.
						</p>
						<p class="small">
							We also start out working via email because it helps us help everyone as quickly as possible.
						</p>
						<p class="small">
							If we aren&#39;t able to get your problem resolved via email, we&#39;ll set up a time to connect with you directly.
						</p>
					</div>
					<div class="col-6">
						<h4 class="text-center">
							Who we are
						</h4>
						<p class="small">
							We&#39;re a small US-based team, but we work hard to give top-quality service!
						</p>
						<p class="small">
							Depending on the day, you may work with April, Brian, Jeff, Rachael, Tommy, Shane, or Graysen.
						</p>
						<p class="small">
							No matter who you hear from, we&#39;re all dedicated to making sure you can write papers with ease!
						</p>
						
						<br /><br />

						<h4 class="text-center">
							What we promise
						</h4>

						<p class="small">
							We know that writing papers is tough. When you have a problem, it&#39;s even worse!
						</p>
						<p class="small">
							We promise that we&#39;re committed to doing everything we can to help you quickly and effectively so you can keep focusing on writing amazing papers.
						</p>
					</div>
				</div>
			</main>
		</section>
	</div>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_SyncData from '@/services/app/syncData';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormInput from '@/components/form/Input';
import FormTextarea from '@/components/form/Textarea';
import FormSelect from '@/components/form/Select';
import normalizeEmail from 'validator/lib/normalizeEmail';	//https://www.npmjs.com/package/validator
import Recaptcha from '@/components/Recaptcha'

export default {
	name: 'ContactSupport',
	computed:{
		calcSectionTextClasses(){
			let returnString = 'section-text ';
			// <section :class="['', {'bg-brand-100': $store.state.customer.themeId === $enums.Theme.REGULAR, 'bg-extra-dark': }]">
			
			if(this.$store.state.api.token === ''){
				// not logged in - dont apply a theme
				returnString += 'bg-brand-100 ';
			} else {
				// logged in look at the user theme
				if(this.$store.state.customer.themeId === config.enums.Theme.DARK){
					returnString += 'bg-extra-dark ';
				}
			}
			return returnString;
		},
	},
	data() {
		return {
			isFormSent: false,
			isInvalidRecaptcha: false,
			email: '',
			message: '',
			yourName: '',
			topic: '0',
			topicList: [
				{
					value: '0',
					name: 'Please choose a topic...',
				},
				{
					value: 'Word Add-in',
					name: 'PERRLA Add-in for Word',
				},
				{
					value: 'Online',
					name: 'PERRLA Online',
				},
				{
					value: 'Account',
					name: 'My Account or Subscription',
				},
				{
					value: 'Other',
					name: 'Other',
				},
			],
			zFormId: 'formContactSupport',
		}
	},
	methods: {
		btnSubmitRequest(){
			this.$store.commit('loaders/ADD_ID', 'App');
			this.isFormSent = false;
			
			// check for recaptcha (with pcom layout)
			if(this.$store.state.app.layoutEnum === config.enums.Layout.PCOM){
				let recaptchaResponse = grecaptcha.getResponse();
				if(recaptchaResponse === ''){
					this.isInvalidRecaptcha = true;
					this.$store.commit('loaders/REMOVE_ID', 'App');
					return false;
				}
			}

			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{

				// gather local logs
				this.$store.dispatch('logs/getAll').then((localLogs) =>{
					let sendData = {
						"ClientLogs": {
							logs: localLogs,
						},
						"Data": {
							'Browser': this.$store.state.api.browserName,
							'BrowserVersion': this.$store.state.api.browserVersion,
							'CustomerID': this.$store.state.api.customerId,
							'IsImpersonating': this.$store.state.customer.isImpersonating,
							"Origin": "PERRLA Online",
							'OS': this.$store.state.api.osName,
							'OS Version': this.$store.state.api.osVersion,
							'SourceApp': 'Online',
							'SourceAppVersion': VERSION,
							"Session": this.$store.state.api.sessionId,
						},
						"Email" : normalizeEmail(this.email),
						"LocalStorage": {
							// get some info from vuex persisted local storage
							'lastSyncDate': this.$store.state.lastSyncDate,
							'newRelicGuid': this.$store.state.api.sessionId,
							'customerEmail': this.$store.state.customer.email,
							'customerFullName': this.$store.state.customer.fullName,
						},
						"Message": this.message,
						"Name" : this.yourName,
						"Topic" : this.topic,
					}

					// console.log('sendData');
					// console.log(sendData);

					ApiS_MakeCall({
						body: sendData,
						ignoreAuth: true,
						method: 'POST',
						url: 'v3/Support',
					}).then(()=>{
						this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
							formId: this.zFormId
						});

						this.isFormSent = true;
						this.$store.commit('loaders/REMOVE_ID', 'App');
					});//e:ApiS_MakeCall
				
				});//e:logs/getAll

			}).catch(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');

			});//e:AS_ValidateForm
		},
	},
	
	mounted() {
		// with layout set from pathname, somethings you can auto fill 
		if(this.$store.state.app.layoutEnum === config.enums.Layout.PO){
			// PerrlaComplete Layout - a full Sync is ok they should be logged in to PO.com here
			AS_SyncData().then(()=>{
				this.yourName = this.$store.state.customer.fullName;
				this.email = this.$store.state.customer.email;
				this.$store.commit('loaders/REMOVE_ID', 'App');

				this.$store.commit('forms/ADD_FORM_TO_LIST',{
					formId: this.zFormId
				});
			});
		} else {
			// Perrla.com Layout - but user still may be logged in - email and fullName will be in local storage if so?
			this.yourName = this.$store.state.customer.fullName || '';
			this.email = this.$store.state.customer.email || '';
			
			this.$store.commit('forms/ADD_FORM_TO_LIST',{
				formId: this.zFormId
			});
		}
	},
	components: {
		FormInput,
		FormTextarea,
		FormSelect,
		Recaptcha
	},
}
</script>

<style lang="scss" scoped>
	.svg-sent{
		font-size: 75px;
		line-height: 1;
	}
</style>