var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { class: _vm.calcInstanceStyles, attrs: { id: "instancePaperBody" } },
      [
        _c("paper-running-head", { attrs: { "page-type": "body" } }),
        _vm._v(" "),
        _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
          _vm.$enums.Format.APA7 ||
        _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
          _vm.$enums.Format.MLA9
          ? _c(
              "h1",
              {
                class: [
                  "text-center",
                  {
                    "fw-bold":
                      _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                      _vm.$enums.Format.APA7,
                    "fw-normal":
                      _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                      _vm.$enums.Format.MLA9,
                  },
                ],
                attrs: { id: "h1PaperBodyTitle" },
              },
              [
                _vm._v(
                  "\n\t\t\t" + _vm._s(_vm.firstPageTitleDisplay) + "\n\t\t"
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("textarea", {
          staticClass: "d-none",
          attrs: { autocomplete: "off", id: "ckPaperBody" },
          domProps: { value: _vm.paperContentValue },
        }),
        _vm._v(" "),
        _vm.PaperType == _vm.$enums.PaperType.APA7_DiscussionPost ||
        _vm.PaperType == _vm.$enums.PaperType.MLA9_DiscussionPost ||
        _vm.PaperType == _vm.$enums.PaperType.Turabian9_DiscussionPost
          ? _c("paper-reference-section-discussion-post")
          : _vm._e(),
        _vm._v(" "),
        _vm.$store.state.paperEdit.config.renderCitationsAsFootnotes
          ? _c("paper-footnotes")
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }