<!--
- Paper Edit > Editor Tab > Refs & Cits Drawer > Ref Row > Citation Add
-->

<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title
				title="Add Citation"
				close-to-route-name="EditorReferencesCitations" />
		</div>

		<div class="drawer-body">
			<p class="mb-4">
				Before creating the citation, place your cursor where you want the citation to appear.
			</p>

			<form>
				<citation-form
					:paper-format-version-id="paperFormatVersionID"
					:reference-unique-id="$route.params.referenceUid.toUpperCase()"
					@enableCitationFormButton="isDisabled = false"
					@disableCitationFormButton="isDisabled = true" />

				<div class="row">
					<div class="col-6">
						<div class="d-grid">
							<router-link 
									:to="{name: 'EditorReferencesCitations'}"
									class="btn btn-outline-danger">
									Cancel
							</router-link>
						</div>
					</div>
					<div class="col-6">
						<div class="d-grid">
							<button type="submit" class="btn btn-success" @click.prevent="btnAddCitation" :disabled="isDisabled" ref="btnCreateCitation">
								Create Citation
							</button>
						</div>
					</div>
				</div>

			</form>
			
		</div>
		
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import CitationForm from '@/modules/CitationForm';
import config from '@/config';
import ErrS_Reroute from '@/services/app/error/reroute.js';
import InsertRawHtml from '@/helpers/insert-raw-html';
import PCS_InsertCitation from '@/services/paper/citations/insertCitation';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import PS_SaveDocument from '@/services/paper/saveDocument';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';
import { v4 as uuidv4 } from 'uuid';

export default {
	name: 'EditorCitationAdd',
	data() {
		return {
			isDisabled: true,
		}
	},
	computed:{
		paperFormatVersionID(){
			// on load the paper hasn't loaded it's meta data yet
			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === ''){
				return 0;
			}
			return this.$store.state.paperEdit.meta.PaperFormatVersionID;
		},
	},
	methods: {
		btnAddCitation(){
			let newCitationResult = _cloneDeep(this.$store.state.paperEdit.citations.tempCitationData);

			try {
				newCitationResult.citationData.citationUniqueID = uuidv4().toUpperCase();
				newCitationResult.citationData.referenceUniqueID = this.$route.params.referenceUid.toUpperCase();
			} catch {
				ErrS_Reroute({
					pushToName: 'EditorReferencesCitations'
				});

				return false;	// on catch stop
			}

			// clear tempCitationData now that you cloned it
			this.$store.commit('paperEdit/citations/SET_TEMP_CITATION_DATA', {});

			// add citation to citations.inPaper
			this.$store.commit('paperEdit/citations/ADD_TO_PAPER', newCitationResult);

			let valueFirst = '';
			let valueSubsequent = '';

			// calculate the first and subsequent values here
			if(this.paperFormatVersionID === config.enums.Format.APA7){
				// APA7 
				valueFirst = newCitationResult.apa7.first;
				valueSubsequent = newCitationResult.apa7.subsequent;

			} else if(this.paperFormatVersionID === config.enums.Format.MLA9){
				// MLA9
				valueFirst = newCitationResult.mla9.first;
				valueSubsequent = newCitationResult.mla9.subsequent;

			} else if(this.paperFormatVersionID === config.enums.Format.Turabian9){
				// Turabian9
				valueFirst = newCitationResult.turabian9.first;
				valueSubsequent = newCitationResult.turabian9.subsequent;
			}
			
			let isInsertTextInsteadOfCitation = false;

			if(this.paperFormatVersionID === config.enums.Format.Turabian9){
				let path = CKEDITOR.instances.ckPaperBody.elementPath();
				let tdElement = path.contains('td', 1);
				if(tdElement && tdElement.hasClass('td-source')){
					isInsertTextInsteadOfCitation = true;
				}
			}//e:if.paperFormatVersionID
			
			if(isInsertTextInsteadOfCitation){
				if(valueFirst){
					InsertRawHtml.insertText(valueFirst);

					this.$router.push({
						name: 'EditorReferencesCitations'
					}).catch(()=>{});
				
				}

			} else {
				// Insert as Citation (it will figure out the format and footnote stuff)
				PCS_InsertCitation({
					'citationUniqueID': newCitationResult.citationData.citationUniqueID,
					'referenceUniqueID': newCitationResult.citationData.referenceUniqueID,
					'valueFirst': valueFirst,
					'valueSubsequent': valueSubsequent,
				}).then((newGroupUniqueID)=>{
					if(this.$store.state.paperEdit.config.renderCitationsAsFootnotes){
						// console.log('newGroupUniqueID');
						// console.log(newGroupUniqueID);

						// insert this citation in the footnote content
						this.$store.dispatch('paperEdit/footnotes/addToPaper', {
							content: '<p>@@@</p>',
							findById: newGroupUniqueID, // if any citations are merged it will figure it out based on groupUniqueId
						}).then(()=>{
							// now you can save
							PS_BuildReferencesInPaper().then(()=>{
								PS_SaveDocument({
									isMetaDataChanges: true,
									message: 'Created Citation - ' + valueFirst,
								}).then(()=>{
									this.$router.push({
										name: 'EditorReferencesCitations'
									}).catch(()=>{});
								});//e:PS_SaveDocument
							});//e:PS_BuildReferencesInPaper
						});

					} else {
						// go right into save process
						PS_BuildReferencesInPaper().then(()=>{
							PS_SaveDocument({
								isMetaDataChanges: true,
								message: 'Created Citation - ' + valueFirst,
							}).then(()=>{
								this.$router.push({
									name: 'EditorReferencesCitations'
								}).catch(()=>{});
							});//e:PS_SaveDocument
						});//e:PS_BuildReferencesInPaper

					}//e:if:else:this.$store.state.paperEdit.config.renderCitationsAsFootnotes
					
				}).catch((error)=>{
					console.log('CitationAdd - error');
					console.log(error);
					this.$router.push({
						name: 'EditorReferencesCitations'
					}).catch(()=>{});
				});//e:PCS_InsertCitation

			}//e:if:else:isInsertTextInsteadOfCitation
			

		},//e:btnAddCitation
			
	},
	components: {
		CitationForm,
		ToolDrawerTitle
	},
	mounted(){
		this.$nextTick(()=>{
			if(this.$refs.btnCreateCitation){
				this.$refs.btnCreateCitation.focus();
			}
		});
	}
}
</script>
