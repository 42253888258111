<!--
- Tags - Tagify 
- https://github.com/yairEO/tagify
-->

<template>
	<div class="form-group">
		<label :for="$data['safeId']">
			Tags
		</label>
		<div class="help-text mb-1">
			Type your tags, separated by a comma.
		</div>
		<input
			autocomplete="off"
			type="text"
			class="form-control"
			:id="$data['safeId']"
			:pendo-grab-text="pendoGrabText"
			:value="value"
			@input="$emit('input', $event.target.value)" />
	</div>
</template>

<script>
import mixinId from '@/mixins/id';

export default {
	name: 'FormTags',
	mixins: [mixinId],
	props:{
		formId: {
			type: String,
		},
		pendoGrabText:{
			default: '',
			type: String,
		},
		value: {
			type: String,
		},
	},
	data() {
		return {
		}
	},
	//methods: {},
	//created() {},
	//mounted() {},
}
</script>
