<!--
- Papers > List > Row
-- clickable title to open a document for editing
-->

<template>
	<tr :class="['tr-entity-grid-row tr-entity-grid-primary', {'tr-entity-grid-row-success': isComplete}]" @mouseover="isHover = true" @mouseleave="isHover = false">
		<td class="td-entity-icon" width="25px">
			<a href="#" v-show="isComplete" title="Mark Finished" @click.prevent="togglePaperComplete">
				<span class="svg bi-check-lg" />
			</a>
			<div v-show="!isComplete">
				<a href="#" class="svg" v-show="isHover" title="Mark Unfinished" @click.prevent="togglePaperComplete">
					<span class="svg icons8-unchecked-checkbox" />
				</a>

				<span class="svg icons8-document" v-show="!isHover" />
			</div>
		</td>
		<td class="td-entity-title td-entity-click" @click.prevent="gotoGridElement">
			{{ decodePaperTitle }}
		</td> 
		<td class="table-spacer" />
		<td>
			{{ outputDocumentType }}
		</td>
		<td class="table-spacer" v-if="context !== 'ClassEdit'" />
		<td width="160px" :style="calcClassColorStyle" v-if="context !== 'ClassEdit'">
			{{ paperClassName }}
		</td>
		<td class="table-spacer" v-if="context !== 'ClassEdit'" />
		<td width="160px" :class="[{'text-end': context === 'ClassEdit' }]" v-if="context !== 'ClassEdit'">
			{{ outputDueDate }}
		</td>
		<td class="table-spacer" />
		<td width="160px" :class="[{'text-end': context === 'ClassEdit' }]">
			{{ outputLastModifiedDate }}
		</td>
		<td class="td-more-menu" v-if="context !== 'ClassEdit'">
			<div class="dropstart">
				<a href="#" :class="['svg bi-three-dots-vertical float-end']" data-bs-toggle="dropdown" aria-expanded="false" aria-label="More Options" />
				<ul class="dropdown-menu">
					<li>
						<a href="#" class="dropdown-item hover hover-primary" @click.prevent="gotoGridElement">
							Open Paper
						</a>
					</li>
					<li>
						<a href="#" :class="['dropdown-item hover hover-primary', {'disabled' : !isGenesisPaper}]" @click.prevent="btnCreatePaperCopy">
							Create a Copy
						</a>
					</li>
					<li>
						<a href="#" class="dropdown-item hover hover-primary" @click.prevent="togglePaperComplete">
							<span v-if="isComplete" key="spanMarkIncomplete">Mark Unfinished</span>
							<span v-else key="spanMarkComplete">Mark Finished</span>
						</a>
					</li>
					<li>
						<a href="#" class="dropdown-item hover hover-danger" @click.prevent="deletePaperShowConfirm">
							Delete Paper
						</a>
					</li>
					<li><hr class="dropdown-divider" /></li>
					<li>
						<a href="#" :class="['dropdown-item hover hover-primary', {'disabled' : !isGenesisPaper}]" @click.prevent="btnPrintPaper">
							Print Paper
						</a>
					</li>
					<li>
						<a href="#" :class="['dropdown-item hover hover-primary', {'disabled' : !isGenesisPaper}]" @click.prevent="openDownloadedDocument($enums.DownloadPaperContentType.WORD_SIMPLE)">
							Download Simplified Word File
						</a>
					</li>
					<li>
						<a href="#" :class="['dropdown-item hover hover-primary', {'disabled' : !isGenesisPaper}]" @click.prevent="openDownloadedDocument($enums.DownloadPaperContentType.WORD)">
							Download PERRLA Word File
						</a>
					</li>
					<li>
						<a href="#" :class="['dropdown-item hover hover-primary', {'disabled' : !isGenesisPaper}]" @click.prevent="openDownloadedDocument($enums.DownloadPaperContentType.PDF)">
							Download as PDF
						</a>
					</li>
				</ul>
			</div>
		</td>
	</tr>
</template>

<script>
import _has from 'lodash/has';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_SyncData from '@/services/app/syncData';
import ClassColorParse from '@/helpers/class-color-parse';
import config from '@/config';
import dateToLongString from '@/helpers/date-to-long-string';
import HelperIsGenesisPaper from '@/helpers/is-genesis-paper';
import ParseDocFormatType from '@/helpers/parse-doc-format-type';
import PS_DownloadDocumentVersionV4 from '@/services/paper/downloadDocumentVersionV4';
import Swal from 'sweetalert2';

export default {
	name: 'PaperRow',
	props:{
		context:{
			type: String,
		},
		classId:{
			type: [Number, String],
		},
		loaderId:{
			type: String,
		},
		paperClassName:{
			required: true,
			type: String,
		},
		paperDescription:{
			// required: true,
			type: String,
		},
		paperEncryptedId:{
			required: true,
			type: String,
		},
		paperStartDate:{
			// required: true,
			type: String,
		},
		paperDueDate:{
			required: true,
			type: String,
		},
		paperFormatVersionId:{
			required: true,
			type: Number,
		},
		paperId:{
			required: true,
			type: Number,
		},
		paperIsComplete:{
			required: true,
			type: Boolean,
		},
		paperLastModifiedDate:{
			required: true,
			type: String,
		},
		paperTitle: {
			required: true,
			type: String,
		},
		paperType: {
			required: true,
			type: Number,
		},
	},
	data() {
		return {
			isComplete: this.paperIsComplete,
			isHover: false,
		}
	},
	computed:{
		calcClassColorStyle(){
			if(this.classId !== ''){
				let thisClassData = this.$store.state.classes.list.find((classData)=>{
					return classData.classID === this.classId;
				});
				if(thisClassData){
					return ClassColorParse({
						'in': thisClassData.colorValue,
						'isOutBlock': false,
					});
				}
			}
			return null;
		},

		decodePaperTitle(){
			// there could be weird stuff in the paper title, so try to decode
			try {
				decodeURIComponent(this.paperTitle)
			} catch(err){
				// decode didn't work, so just send the paper title back
				return this.paperTitle;
			}
			return decodeURIComponent(this.paperTitle);
		},

		isGenesisPaper(){
			return HelperIsGenesisPaper.checkFormatVersion(this.paperFormatVersionId);
		},
		
		outputDocumentType(){
			return ParseDocFormatType({
				paperFormatVersionID: this.paperFormatVersionId,
				paperTypeEnum: this.paperType,
			});
		},

		outputDueDate(){
			return dateToLongString({
				dateValue: this.paperDueDate,
				showTime: false,
			});
		},

		outputLastModifiedDate(){
			return dateToLongString({
				dateValue: this.paperLastModifiedDate,
				showTime: false,
			});
		},
	},
	methods: {
		btnCreatePaperCopy(){
			ApiS_MakeCall({
				method: 'POST',
				params:{
					encryptedId: this.paperEncryptedId,
				},
				url: 'v3/Paper/Duplicate',
			}).then(()=>{
				this.$store.commit('loaders/ADD_ID', 'App');
				
				AS_SyncData({
					isStealthSync: true
				}).then(()=>{
					this.$emit('update-grid');

					this.emitter.emit('globalToasterOpen',{
						textContent: 'Copy created',
					});
				});
			});//e:ApiS_MakeCall
		},//e:btnCreatePaperCopy

		btnPrintPaper(){
			PS_DownloadDocumentVersionV4({
				contentType: config.enums.DownloadPaperContentType.PDF,
				isPrint: true,
				paperId: this.paperId,
				paperTitle: this.paperTitle,
				platform: 1, // just Online Papers here
			});
		},

		deletePaperShowConfirm(){
			Swal.fire({
				allowOutsideClick: false,
				buttonsStyling: false,
				title: 'Are you sure?',
				text: 'This will delete your paper.',
				icon: 'warning',
				cancelButtonText: 'No, Cancel',
				confirmButtonText: 'Yes, Delete',
				showCloseButton: false,
				showCancelButton: true,
				showConfirmButton: true,
				customClass:{
					cancelButton: 'btn btn-outline-danger',
					confirmButton: 'btn btn-success me-2',
				},
			}).then((result) => {
				if (result.value) {
					this.$store.commit('loaders/ADD_ID', 'App');

					// default to APA7 delete (change only if it's NOT apa7 or mla9)
					let fetchUrl = 'v3/Paper?encryptedId=' + this.paperEncryptedId;
					let fetchMethod = 'DELETE';
					let outsideSpa = false;

					if(!this.isGenesisPaper){
						// NON SPA Paper
						fetchUrl = 'Papers/DeletePaper?PaperID=' + this.paperId + '&includeChildren=false'
						fetchMethod = 'GET';
						outsideSpa = true;
					}

					ApiS_MakeCall({
						method: fetchMethod,
						outsideSpa: outsideSpa,
						url: fetchUrl,
					}).then(()=>{
						// remove this paper from the paper list
						let findPaperIndex = this.$store.state.paperList.list.findIndex((paper) =>{
							return paper.paperID === this.paperId;
						});
						if(findPaperIndex != -1){
							this.$store.commit('paperList/REMOVE_AT_INDEX', findPaperIndex);
						}
						
						this.$emit('update-grid');

						this.emitter.emit('globalToasterOpen',{
							textContent: 'Paper successfully deleted',
						});

						this.$store.commit('loaders/REMOVE_ID', 'App');

					});//e:ApiS_MakeCall
				}
			});
		},//e:deletePaperShowConfirm

		gotoGridElement(){
			if(this.isGenesisPaper){
				this.$router.push({
					name: 'EditorReferencesCitations',
					params:{
						encryptedId: this.paperEncryptedId
					},
				}).catch(()=>{});

			} else {
				// Non SPA Paper (APA6 or MLA8)
				if(this.$isDev){
					alert('APA6/MLA8 papers cant do that locally');

				} else {
					this.$store.commit('loaders/ADD_ID', 'App');
					window.location = '/Papers/Write/' + this.paperEncryptedId;

				}

			}
		},//e:gotoGridElement

		openDownloadedDocument(contentType){
			PS_DownloadDocumentVersionV4({
				contentType: contentType,
				paperId: this.paperId,
				paperTitle: this.paperTitle,
				platform: 1, // just Online Papers here
			});
		},//e:openDownloadedDocument

		togglePaperComplete(){
			this.$store.commit('loaders/ADD_ID', 'App');

			if(this.$isDev && !this.isGenesisPaper){
				alert('APA6/MLA8 papers cant do that locally');

			} else {
				this.isComplete = !this.isComplete;

				// for this specific case url and method will change based on SpaPaper or not
				let url = 'v3/MarkPaperComplete';
				let method = 'GET';
				let params = {
					paperId: this.paperId,
					isComplete: this.isComplete,
				};

				// get paper index 
				let findPaperIndex = this.$store.state.paperList.list.findIndex((paper) =>{
					return paper.paperID === this.paperId;
				});

				// guard from not found
				if(findPaperIndex === -1){
					this.$store.commit('loaders/REMOVE_ID', 'App');
					return false;
				}
				
				ApiS_MakeCall({
					method,
					params,
					url,
				}).then(()=>{
					this.$store.commit('paperList/TOGGLE_COMPLETE_AT_INDEX', findPaperIndex);
					this.$store.commit('loaders/REMOVE_ID', 'App');
				}).catch(()=>{
					this.$store.commit('loaders/REMOVE_ID', 'App');
				});//e:ApiS_MakeCall
			}
		},//e:togglePaperComplete
	},

}
</script>
