var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isDrawerOpen,
            expression: "isDrawerOpen",
          },
        ],
        key: "divDrawerWrpOpen",
        ref: "toolDrawerWrp",
        class: [
          "tool-drawer-open overflow-x-hidden",
          { "disable-text-selection": _vm.isDrawerDragOn },
        ],
        style: _vm.calcToolDrawerStyles,
      },
      [
        _c("div", { ref: "dragEdge", staticClass: "tool-drawer-drag-edge" }),
        _vm._v(" "),
        _c("side-menu", { attrs: { "is-editor": _vm.isEditor } }),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "route-fade", mode: "out-in", appear: "" } },
          [_c("router-view")],
          1
        ),
        _vm._v(" "),
        _c("drawer-toaster"),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isDrawerOpen,
            expression: "!isDrawerOpen",
          },
        ],
        key: "divDrawerWrpClosed",
        staticClass: "tool-drawer-closed",
        attrs: {
          "data-bs-toggle": "tooltip",
          "data-bs-title": "Open Tool Drawer",
          "data-bs-placement": "left",
        },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.openDrawer.apply(null, arguments)
          },
        },
      },
      [_c("span", { staticClass: "svg bi-chevron-left" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }