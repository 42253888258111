var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      class: [
        "tr-entity-grid-row tr-entity-grid-primary",
        { "tr-entity-grid-row-success": _vm.isComplete },
      ],
      on: {
        mouseover: function ($event) {
          _vm.isHover = true
        },
        mouseleave: function ($event) {
          _vm.isHover = false
        },
      },
    },
    [
      _c("td", { staticClass: "td-entity-icon", attrs: { width: "25px" } }, [
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isComplete,
                expression: "isComplete",
              },
            ],
            attrs: { href: "#", title: "Mark Finished" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.togglePaperComplete.apply(null, arguments)
              },
            },
          },
          [_c("span", { staticClass: "svg bi-check-lg" })]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isComplete,
                expression: "!isComplete",
              },
            ],
          },
          [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isHover,
                    expression: "isHover",
                  },
                ],
                staticClass: "svg",
                attrs: { href: "#", title: "Mark Unfinished" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.togglePaperComplete.apply(null, arguments)
                  },
                },
              },
              [_c("span", { staticClass: "svg icons8-unchecked-checkbox" })]
            ),
            _vm._v(" "),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isHover,
                  expression: "!isHover",
                },
              ],
              staticClass: "svg icons8-document",
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "td-entity-title td-entity-click",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.gotoGridElement.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.decodePaperTitle) + "\n\t")]
      ),
      _vm._v(" "),
      _c("td", { staticClass: "table-spacer" }),
      _vm._v(" "),
      _c("td", [_vm._v("\n\t\t" + _vm._s(_vm.outputDocumentType) + "\n\t")]),
      _vm._v(" "),
      _vm.context !== "ClassEdit"
        ? _c("td", { staticClass: "table-spacer" })
        : _vm._e(),
      _vm._v(" "),
      _vm.context !== "ClassEdit"
        ? _c(
            "td",
            { style: _vm.calcClassColorStyle, attrs: { width: "160px" } },
            [_vm._v("\n\t\t" + _vm._s(_vm.paperClassName) + "\n\t")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.context !== "ClassEdit"
        ? _c("td", { staticClass: "table-spacer" })
        : _vm._e(),
      _vm._v(" "),
      _vm.context !== "ClassEdit"
        ? _c(
            "td",
            {
              class: [{ "text-end": _vm.context === "ClassEdit" }],
              attrs: { width: "160px" },
            },
            [_vm._v("\n\t\t" + _vm._s(_vm.outputDueDate) + "\n\t")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("td", { staticClass: "table-spacer" }),
      _vm._v(" "),
      _c(
        "td",
        {
          class: [{ "text-end": _vm.context === "ClassEdit" }],
          attrs: { width: "160px" },
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.outputLastModifiedDate) + "\n\t")]
      ),
      _vm._v(" "),
      _vm.context !== "ClassEdit"
        ? _c("td", { staticClass: "td-more-menu" }, [
            _c("div", { staticClass: "dropstart" }, [
              _c("a", {
                class: ["svg bi-three-dots-vertical float-end"],
                attrs: {
                  href: "#",
                  "data-bs-toggle": "dropdown",
                  "aria-expanded": "false",
                  "aria-label": "More Options",
                },
              }),
              _vm._v(" "),
              _c("ul", { staticClass: "dropdown-menu" }, [
                _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item hover hover-primary",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.gotoGridElement.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\tOpen Paper\n\t\t\t\t\t")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      class: [
                        "dropdown-item hover hover-primary",
                        { disabled: !_vm.isGenesisPaper },
                      ],
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.btnCreatePaperCopy.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\tCreate a Copy\n\t\t\t\t\t")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item hover hover-primary",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.togglePaperComplete.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm.isComplete
                        ? _c("span", { key: "spanMarkIncomplete" }, [
                            _vm._v("Mark Unfinished"),
                          ])
                        : _c("span", { key: "spanMarkComplete" }, [
                            _vm._v("Mark Finished"),
                          ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item hover hover-danger",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.deletePaperShowConfirm.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\tDelete Paper\n\t\t\t\t\t")]
                  ),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      class: [
                        "dropdown-item hover hover-primary",
                        { disabled: !_vm.isGenesisPaper },
                      ],
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.btnPrintPaper.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\tPrint Paper\n\t\t\t\t\t")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      class: [
                        "dropdown-item hover hover-primary",
                        { disabled: !_vm.isGenesisPaper },
                      ],
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openDownloadedDocument(
                            _vm.$enums.DownloadPaperContentType.WORD_SIMPLE
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\tDownload Simplified Word File\n\t\t\t\t\t"
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      class: [
                        "dropdown-item hover hover-primary",
                        { disabled: !_vm.isGenesisPaper },
                      ],
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openDownloadedDocument(
                            _vm.$enums.DownloadPaperContentType.WORD
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\tDownload PERRLA Word File\n\t\t\t\t\t"
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      class: [
                        "dropdown-item hover hover-primary",
                        { disabled: !_vm.isGenesisPaper },
                      ],
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openDownloadedDocument(
                            _vm.$enums.DownloadPaperContentType.PDF
                          )
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\tDownload as PDF\n\t\t\t\t\t")]
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("hr", { staticClass: "dropdown-divider" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }