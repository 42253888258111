<!--
- Write/Edit Page for Genesis Apa7 Papers
-->

<template>
	<div class="paper-edit-wrp">
		<nav-tabs>
			<nav-tab
				:to="{
					name:'PaperTabOverview'
				}">
				Overview
			</nav-tab>
			
			<nav-tab
				:to="{
					name:'PaperTabResearch'
				}">
				Research
			</nav-tab>

			<nav-tab
				:to="{
					name:'OutlineCitationLastVisitedDrawer'
				}"
				:is-active="$router.currentRoute.meta.tab === $enums.Tab.OUTLINE">
				Outline
			</nav-tab>

			<nav-tab
				:to="{
					name:'EditorReferencesCitations'
				}"
				:is-active="$router.currentRoute.meta.tab === $enums.Tab.EDITOR">
				Editor
			</nav-tab>

			<nav-tab
				:to="{
					name:'PaperTabExport'
				}">
				Export / Print
			</nav-tab>
		</nav-tabs>

		<transition name="route-fade" mode="out-in" appear>
			<router-view />
		</transition>
		
		<modal-special-characters />

	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import CkPluginEntry from '@/ck/plugins/_entry';	// include here so everything can go into app.js (it will look grayed out)
import config from '@/config';
import ModalSpecialCharacters from '@/views/Paper/modals/SpecialCharacters';
import NavTab from '@/components/NavTab';
import NavTabs from '@/components/NavTabs';

export default {
	name: 'PaperEdit',
	components:{
		ModalSpecialCharacters,
		NavTab,
		NavTabs,
	},
	destroyed(){
		this.$store.commit('paperEdit/abstract/CONTENT', '');
		this.$store.commit('paperEdit/abstract/KEYWORDS', '');

		this.$store.commit('paperEdit/annotations/CLEAR_IN_PAPER');
		this.$store.commit('paperEdit/appendices/CLEAR_IN_PAPER');

		_forEach(config.metaDataKeys, (metaDataKey)=>{
			this.$store.commit('paperEdit/meta/SET_' + metaDataKey, '');
		});

		this.$store.commit('paperEdit/SET_PAPER_DATA_BODY', '');
		this.$store.commit('paperEdit/SET_OPEN_REFERENCE_UID', '');
		this.$store.commit('paperEdit/SET_ENCRYPTED_PAPER_LZ_STRING', '');

		this.$store.commit('paperEdit/SET_OPEN_OBJECT', {});

		this.$store.commit('loaders/REMOVE_ID', 'App');
	},
}
</script>

<style lang="scss" scoped>
	.paper-edit-wrp{
		// temporary until i get a better App.vue layout
		margin-top: -12px;
		margin-left: -6px;	
	}
</style>

