var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "element-row mb-2",
        {
          "test-data": _vm.referenceData.isTestData,
          "deleted-reference":
            _vm.referenceData.addFromLibraryMode ===
            _vm.$enums.AddFromLibraryMode.DISABLED,
        },
      ],
    },
    [
      _c("p", {
        directives: [
          { name: "add-blank-target", rawName: "v-add-blank-target" },
        ],
        class: [
          "mb-0 rounded reference-value py-1 neg-gutter",
          { "bg-active": _vm.isInPaper },
        ],
        domProps: { innerHTML: _vm._s(_vm.outputReferenceValue) },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isInPaper,
              expression: "!isInPaper",
            },
          ],
        },
        [
          _vm.referenceData.addFromLibraryMode ===
          _vm.$enums.AddFromLibraryMode.DISABLED
            ? _c(
                "p",
                {
                  key: "btnAddModeDisabled",
                  staticClass: "ps-2 mt-0 font-weight-bold incompatible-text",
                },
                [
                  _c("span", { staticClass: "svg bi-exclamation-lg" }),
                  _vm._v(" Incompatible Format\n\t\t"),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.referenceData.addFromLibraryMode ===
          _vm.$enums.AddFromLibraryMode.COMMING_SOON
            ? _c(
                "p",
                {
                  key: "btnAddModeComingSoon",
                  staticClass: "ps-2 mt-0 font-weight-bold incompatible-text",
                },
                [
                  _c("span", { staticClass: "svg bi-exclamation-lg" }),
                  _vm._v(" Reference type coming soon\n\t\t"),
                ]
              )
            : _vm.referenceData.addFromLibraryMode ===
              _vm.$enums.AddFromLibraryMode.NORMAL
            ? _c("div", { key: "btnAddModeNormal", staticClass: "d-grid" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-sm btn-hover btn-hover-success ps-2 mt-0",
                    attrs: { type: "button" },
                    on: { click: _vm.addReference },
                  },
                  [
                    _c("span", { staticClass: "svg bi-plus-lg" }),
                    _vm._v(" Add to Paper\n\t\t\t"),
                  ]
                ),
              ])
            : _vm.referenceData.addFromLibraryMode ===
              _vm.$enums.AddFromLibraryMode.UPDATE
            ? _c("div", { key: "btnAddModeUpdate", staticClass: "d-grid" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-sm btn-hover btn-hover-success ps-2 mt-0",
                    attrs: { type: "button" },
                    on: { click: _vm.convertReference },
                  },
                  [
                    _c("span", { staticClass: "svg bi-plus-lg" }),
                    _vm._v(" Update & Add to Paper\n\t\t\t"),
                  ]
                ),
              ])
            : _vm.referenceData.addFromLibraryMode ===
              _vm.$enums.AddFromLibraryMode.REVIEW
            ? _c("div", { key: "btnAddModeReview", staticClass: "d-grid" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-sm btn-hover btn-hover-success ps-2 mt-0",
                    attrs: { type: "button" },
                    on: { click: _vm.reviewReference },
                  },
                  [
                    _c("span", { staticClass: "svg bi-plus-lg" }),
                    _vm._v(" Review & Add to Paper\n\t\t\t"),
                  ]
                ),
              ])
            : _vm.referenceData.addFromLibraryMode ===
              _vm.$enums.AddFromLibraryMode.FORMAT_DISABLED
            ? _c(
                "div",
                { key: "btnAddModeFormatDisabled", staticClass: "d-grid" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-sm btn-hover btn-hover-danger ps-2 mt-0 disabled",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "svg bi-x-lg" }),
                      _vm._v(
                        " " + _vm._s(_vm.outputFormatDisabledText) + "\n\t\t\t"
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("slide-up-down", { attrs: { active: _vm.showRefDeleteWaring } }, [
        _c("p", { staticClass: "mt-1" }, [
          _vm._v(
            "\n\t\t\tRemoving the Reference from your paper will also delete its citations in your paper.  Are you sure you want to remove this reference?\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-grid gap-2" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-danger",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.removeReference.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n\t\t\t\tRemove Reference & Delete Citations\n\t\t\t")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-outline-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  _vm.showRefDeleteWaring = false
                },
              },
            },
            [_vm._v("\n\t\t\t\tCancel\n\t\t\t")]
          ),
        ]),
      ]),
      _vm._v(" "),
      !_vm.showRefDeleteWaring
        ? _c("div", { staticClass: "d-grid" }, [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isInPaper,
                    expression: "isInPaper",
                  },
                ],
                staticClass: "btn btn-sm btn-hover btn-hover-danger ps-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.removeReferenceConfim.apply(null, arguments)
                  },
                },
              },
              [
                _c("span", { staticClass: "svg bi-x-lg" }),
                _vm._v(" Remove from Paper\n\t\t"),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }