<!--
- Receipt
-- Cart > Step 4
-- https://magical-scratch.webflow.io/public-site/store/store-4-receipt
-->

<template>
	<div class="receipt-wrp mx-auto">
		<div class="row mb-4 d-print-none">
			<div class="col">
				<div class="text-center">
					<h1 class="mb-3">
						Thanks for purchasing!
					</h1>
					<p class="mb-4 org-size" v-if="isNewCustomer" key="pReceiptNewCustomer">
						We've sent "Getting Started Instructions" and a copy of your receipt to your email address.
					</p>
					<p class="mb-4 org-size" v-else key="pReceiptEmailElse">
						We've sent a copy of your receipt to the email address in your PERRLA account.
					</p>

					<h2 v-if="isNewCustomer">
						Ready to start your first paper?
					</h2>
				</div><!-- text-center -->
			</div><!--col-->
		</div><!--row-->
		
		<div class="row mb-4 d-print-none" v-if="isNewCustomer" key="divNewCustomer1">
			<div class="col-12 col-md-6 mb-2 mb-md-0">
				<div class="d-grid">
					<button
						class="btn btn-primary"
						type="button"
						@click.prevent="btnBackToUsingPerrla">
						<span class="fw-bold">Use PERRLA Online</span><br />
						- PERRLA's web-based editor
					</button>
				</div>
			</div>
			<div class="col-12 col-md-6 mb-2 mb-md-0">
				<div class="d-grid" v-if="isNewCustomer" key="divNewCustomer">
					<a href="https://www.perrla.com/#/word-setup" class="btn btn-primary" target="_blank" rel="noopener noreferrer">
						<span class="fw-bold">Use Microsoft Word</span><br />
						- PERRLA's Microsoft Word Add-in
					</a>
				</div>
			</div>
		</div><!--row divNewCustomer1-->
		
		<div class="row mb-4 d-print-none" v-else key="divNewCustomerElse">
			<div class="col-6 mx-auto">
				<div class="d-grid">
					<router-link
						class="btn btn-outline-primary"
						:to="{
							name: 'MyProfile'
						}">
						Go to Account Settings
					</router-link>
				</div>

			</div>
		</div><!--row-->
		
		<div class="row mb-4 d-print-none">
			<div class="col org-size" v-if="isNewCustomer" key="divNewCustomer3">
				<p>Remember: every PERRLA account includes <span class="fst-italic fw-bold">both PERRLA Online &amp; PERRLA for Word</span> - so you can use either at any time. For now, choose the one you&#39;d like to try first.</p>

				<p>
					<a href="https://perrla.zendesk.com/hc/en-us/articles/360034402514-PERRLA-Online-vs-PERRLA-for-Word-Add-in-" rel="noopener noreferrer" target="_blank">
						Need help deciding? Check out this article to see how PERRLA best fits your writing style.
					</a>
				</p>
			</div><!--col-->
			
		</div><!--row-->

		<div class="row mb-5">
			<div class="col org-size">

				<div class="boxset-wrp boxset-receipt-wrp">
					<h2>Receipt</h2>
					
					<table class="table table-borderless table-cart-totals">
						<tbody>
							<!-- I should loop through what was purchased to give an itemized list -->
							<tr v-for="productData in $store.state.cart.products" :key="productData.productID">
								<td>
									{{ productData.name }}
								</td>
								<td class="text-end" width="100px">
									${{ productData.price.toFixed(2) }}
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<div class="divider my-0 w-100" />
								</td>
							</tr>
							<tr>
								<td class="fw-light">
									Subtotal
								</td>
								<td class="fw-light text-end">
									{{ calcSubTotalDisplay }}
								</td>
							</tr>
							<tr v-if="calcDiscountDisplay !== '---'">
								<td class="fw-light">
									Discount
								</td>
								<td class="fw-light text-end text-danger">
									{{ calcDiscountDisplay }}
								</td>
							</tr>
							<tr>
								<td class="fw-light">
									Tax
								</td>
								<td class="fw-light text-end">
									{{ calcTaxDisplay }}
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<div class="divider my-0 w-100" />
								</td>
							</tr>
							<tr>
								<td>
									Total
								</td>
								<td class="text-end">
									{{ calcTotalDisplay }}
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<div class="divider my-0 w-100" />
								</td>
							</tr>
						</tbody>
					</table>
					
					<h2>Transaction Details</h2>

					<table class="table table-borderless table-cart-totals">
						<tbody>
							<tr>
								<td>
									Transaction ID
								</td>
								<td class="fw-light">
									{{ transactionId }}
								</td>
							</tr>
							<tr>
								<td>
									Purchase Number
								</td>
								<td class="fw-light">
									{{ purchaseId }}
								</td>
							</tr>
							<tr>
								<td>
									Payment Method
								</td>
								<td class="fw-light">
									{{ calcPaymentMethodName }}
								</td>
							</tr>
							<tr>
								<td>
									Expiration Date
								</td>
								<td class="fw-light">
									{{ syncedExpirationDateOutput }}
								</td>
							</tr>
							<tr>
								<td>
									Renewal
								</td>
								<td class="fw-light">
									{{ calcRenewalMessage }}
								</td>
							</tr>
						</tbody>
					</table>

					<button
						class="btn btn-outline-primary px-5 float-end d-print-none"
						type="button"
						@click.prevent="btnPrintReceipt">
						Print Receipt
					</button>

				</div><!--boxset-wrp-->
				
			</div><!--col-->
		</div><!--row-->
		
	</div>
</template>

<script>
import AS_SyncData from '@/services/app/syncData';
import config from '@/config';
import currencyFormat from '@/helpers/currency';
import dateFormat from "dateformat";

export default {
	name: 'ReceiptView',
	computed:{
		calcDiscountDisplay(){
			if(this.$store.state.cart.discount){
				return '-' + currencyFormat({
					value: this.$store.state.cart.discount
				});
			}
			return config.emptyText;
		},
		calcPaymentMethodName(){
			if(this.$store.state.cart.paymentMethod && this.$store.state.cart.paymentMethod.name){
				return this.$store.state.cart.paymentMethod.name;
			}
			return config.emptyText;
		},
		calcRenewalMessage(){
			if(this.$store.state.cart.renewalType === config.enums.SubscriptionRenewalType.AUTO){
				return 'Your subscription will be renewed automatically';
			} else if(this.$store.state.cart.renewalType === config.enums.SubscriptionRenewalType.MANUAL){
				return 'Your subscription must be renewed manually.';
			}
			return config.emptyText;
		},
		calcSubTotalDisplay(){
			return currencyFormat({
				value: this.$store.state.cart.subTotal
			});
		},
		calcTaxDisplay(){
			return currencyFormat({
				value: this.$store.state.cart.tax
			});
		},
		calcTotalDisplay(){
			return currencyFormat({
				value: this.$store.state.cart.grandTotal
			});
		},
		isNewCustomer(){
			if(!this.$store.state.cart.customerId || this.$store.state.cart.customerId === 0){
				return true;
			}
			return false;
		},
		purchaseId(){
			return this.$store.state.cart.purchaseId
		},
		syncedExpirationDateOutput(){
			// sets after sync it called in mounted()
			if(this.$store.state.cart.shoppingCartUserId !== 0) {
				let expirationDate = new Date(this.$store.state.customer.expirationDate);	
				let epirationDateOutput = dateFormat(expirationDate, 'mmm') + '. ' + dateFormat(expirationDate, 'd') + ', ' + dateFormat(expirationDate, 'yyyy');
				return epirationDateOutput;
			}
			return '';
		},
		transactionId(){
			return this.$store.state.cart.transactionId
		},
	},
	methods: {
		btnBackToUsingPerrla(){
			if(config.isDev){
				// i can't log this user in automatically from here
				if(this.isNewCustomer){
					this.$router.push({
						name: "Dashboard"
					}).catch(()=>{});
				} else {
					this.$router.push({
						name: "MyProfile"
					}).catch(()=>{});
				}
				
			} else {
				this.$store.commit('loaders/ADD_ID', 'App');

				this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PO);
				
				if(this.isNewCustomer){
					window.location = config.appUrl + '/App/#/dashboard';
				} else {
					window.location = config.appUrl + '/App/#/my-profile';
				}
			}
		},//e:btnBackToUsingPerrla

		btnPrintReceipt(){
			print();
		},

		logGtagValues(){
			var dataLayer = window.dataLayer || []; //this was recommended to verify tag manager is present

			var itemArray = [];

			this.$store.state.cart.products.forEach((product)=>{
				itemArray.push({
					'brand': 'Perrla',
					'name': this.$store.state.cart.productName,
					'id': product.productID,
					'price': product.price.toString(),
					'quantity': 1,
					'currency_type': 'USD',
					'customerID': this.$store.state.customer.id,
					'renewal': (this.$store.state.cart.isRenewal) ? 'automatic' : 'manual',
				});
			});

			dataLayer.push({
				'ecommerce': {
					'purchase': {
						'actionField': {
							'id': this.$store.state.cart.transactionId,				// Transaction ID. Required
							'affiliation': 'Perrla',
							'revenue': this.$store.state.cart.grandTotal.toString(),	// Total transaction value (incl. tax )
							'tax': this.$store.state.cart.tax.toString(),
							'coupon': this.$store.state.cart.promotionalCode.code || ''		//PROMO Code Value
						},
						'products': itemArray,
					}
				}
			});
			
			// console.log('itemArray');
			// console.log(itemArray);

			this.$gtag.event("transaction");
			
			this.$gtag.purchase({
				"transaction_id": this.$store.state.cart.transactionId, //required
				"revenue": this.$store.state.cart.grandTotal.toString(),
				"currency": "USD",
				"tax": this.$store.state.cart.tax.toString(),
				"customer_id": this.$store.state.cart.transactionId,
				"renewal": (this.$store.state.cart.isRenewal) ? 'automatic' : 'manual',
				"promo_code": this.$store.state.cart.promotionalCode.code,
				"items": itemArray,
			});
		},
	},
	mounted() {
		if(this.$store.state.cart.shoppingCartUserId === 0) {
			this.$router.push({
				name: 'IdentifyUser'
			}).catch(()=>{});
			
		} else {
			// call sync here, the user is now a full customer with a full account, so we can grab their expiration date safely
			AS_SyncData().then(()=>{
				this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.CART);

				// force remove any theme classes - this view will not have a customer theme
				document.documentElement.setAttribute('data-bs-theme', 'light')
				document.body.classList.remove('theme-serrena');
				document.body.classList.remove('bg-simple');
				
				// console.log('this.$store.state.api.token');
				// console.log(this.$store.state.api.token);

				// for some reason the api token goes away, set it in local storage here so i can get it back on the receipt page
				// localStorage.setItem("pc_PassToken", this.$store.state.api.token);

				this.$store.commit('loaders/REMOVE_ID', 'App');
				
				if(!config.isDev){
					this.logGtagValues();
				}
			});

			
		}
	},
}
</script>
