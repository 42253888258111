var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "p",
      { staticClass: "hanging-indent-instance word-break" },
      [
        _c("router-link", {
          staticClass: "fake-widget fake-widget-reference-row",
          attrs: {
            title: "Click to Edit Reference",
            to: {
              name: "EditorEditReference",
              params: {
                referenceUid: _vm.upperCaseRid,
              },
            },
          },
          domProps: { innerHTML: _vm._s(_vm.referenceObject.displayValue) },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("textarea", {
      staticClass: "d-none",
      attrs: { id: _vm.calcInstanceId, autocomplete: "off" },
      domProps: { value: _vm.annotationContentValue },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }