var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "layout-pcom":
          _vm.$store.state.app.layoutEnum === _vm.$enums.Layout.PCOM,
      },
    },
    [
      _c(
        "section",
        { staticClass: "section-text" },
        [
          _c("transition", { attrs: { name: "route-fade", mode: "out-in" } }, [
            _vm.isFormSent
              ? _c(
                  "main",
                  { key: "divSent", staticClass: "container-text mt-5" },
                  [
                    _c("div", { staticClass: "text-center" }, [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "svg svg-sent bi-check-lg text-success",
                      }),
                      _vm._v(" "),
                      _c("h3", { staticClass: "mt-0" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\tThank you! Your submission has been received!\n\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "small" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\tWhile you wait for our team to write back, you can always "
                        ),
                        _c(
                          "a",
                          { attrs: { href: "https://www.perrla.com/help" } },
                          [_vm._v("search our Help Center")]
                        ),
                        _vm._v(" for solutions & tutorials.\n\t\t\t\t\t"),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "small" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\tLearn more about our amazing Support Team below 👇\n\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                    ]),
                  ]
                )
              : _c(
                  "main",
                  { key: "divNotSent", staticClass: "container-text mt-5" },
                  [
                    _c("div", { staticClass: "text-center" }, [
                      _c("h3", [_vm._v("Contact Support")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "small" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\tWe're serious about our support. Tell us how we can help and we'll get back to you lickety-split!\n\t\t\t\t\t"
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "container" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-8 mx-auto" }, [
                          _c(
                            "form",
                            [
                              _c("form-input", {
                                attrs: {
                                  label: "Your Name",
                                  "form-id": _vm.zFormId,
                                  "is-horizontal": true,
                                  "validate-enum":
                                    _vm.$enums.Validation.REQUIRED,
                                },
                                model: {
                                  value: _vm.yourName,
                                  callback: function ($$v) {
                                    _vm.yourName = $$v
                                  },
                                  expression: "yourName",
                                },
                              }),
                              _vm._v(" "),
                              _c("form-input", {
                                attrs: {
                                  label: "Email",
                                  "form-id": _vm.zFormId,
                                  "is-horizontal": true,
                                  "validate-enum":
                                    _vm.$enums.Validation.EMAIL_REQUIRED,
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function ($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email",
                                },
                              }),
                              _vm._v(" "),
                              _c("form-select", {
                                attrs: {
                                  label: "Topic",
                                  "form-id": _vm.zFormId,
                                  "is-horizontal": true,
                                  "select-object": _vm.topicList,
                                  "validate-enum":
                                    _vm.$enums.Validation.REQUIRED,
                                },
                                model: {
                                  value: _vm.topic,
                                  callback: function ($$v) {
                                    _vm.topic = $$v
                                  },
                                  expression: "topic",
                                },
                              }),
                              _vm._v(" "),
                              _c("form-textarea", {
                                attrs: {
                                  label: "Message",
                                  "form-id": _vm.zFormId,
                                  "is-autosize": true,
                                  "is-horizontal": true,
                                  "validate-enum":
                                    _vm.$enums.Validation.REQUIRED,
                                  "set-min-rows": "9",
                                },
                                model: {
                                  value: _vm.message,
                                  callback: function ($$v) {
                                    _vm.message = $$v
                                  },
                                  expression: "message",
                                },
                              }),
                              _vm._v(" "),
                              _vm.$store.state.app.layoutEnum ===
                              _vm.$enums.Layout.PCOM
                                ? _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "col-sm-3 col-form-label",
                                      },
                                      [_vm._v(" ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-sm-9" },
                                      [
                                        _c("recaptcha"),
                                        _vm._v(" "),
                                        _vm.isInvalidRecaptcha
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "invalid-feedback d-block",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\tPlease verify that you are not a robot.\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group row" }, [
                                _c("div", { staticClass: "col-sm-8 mx-auto" }, [
                                  _c("div", { staticClass: "d-grid" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-success",
                                        attrs: { type: "submit" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.btnSubmitRequest.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t\t\tSubmit Request\n\t\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("section", { class: _vm.calcSectionTextClasses }, [_vm._m(0)]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("main", { staticClass: "container-text mt-5" }, [
      _c("div", { staticClass: "text-center mb-3" }, [
        _c("h2", [_vm._v("Our Awesome Support Team")]),
        _vm._v(" "),
        _c("img", {
          staticClass: "img-fluid",
          attrs: {
            src: "/static/images/support-team.png",
            role: "presentation",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("\n\t\t\t\t\t\tWhen we work\n\t\t\t\t\t"),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "small" }, [
            _vm._v(
              "\n\t\t\t\t\t\tOur Support Team is available every day until 10pm Central Time – when most students are actually writing papers.\n\t\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "small" }, [
            _c("strong", [_vm._v("Monday – Friday")]),
            _vm._v(": 10am - 10pm Central"),
            _c("br"),
            _vm._v(" "),
            _c("strong", [_vm._v("Saturday & Sunday")]),
            _vm._v(": 1pm - 10pm Central\n\t\t\t\t\t"),
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("\n\t\t\t\t\t\tHow we work\n\t\t\t\t\t"),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "small" }, [
            _vm._v(
              "\n\t\t\t\t\t\tOur responses are always quick! If you haven't heard back from us within a few hours (during our working hours), check your email's spam folder.\n\t\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "small" }, [
            _vm._v(
              "\n\t\t\t\t\t\tWe also start out working via email because it helps us help everyone as quickly as possible.\n\t\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "small" }, [
            _vm._v(
              "\n\t\t\t\t\t\tIf we aren't able to get your problem resolved via email, we'll set up a time to connect with you directly.\n\t\t\t\t\t"
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("\n\t\t\t\t\t\tWho we are\n\t\t\t\t\t"),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "small" }, [
            _vm._v(
              "\n\t\t\t\t\t\tWe're a small US-based team, but we work hard to give top-quality service!\n\t\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "small" }, [
            _vm._v(
              "\n\t\t\t\t\t\tDepending on the day, you may work with April, Brian, Jeff, Rachael, Tommy, Shane, or Graysen.\n\t\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "small" }, [
            _vm._v(
              "\n\t\t\t\t\t\tNo matter who you hear from, we're all dedicated to making sure you can write papers with ease!\n\t\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("\n\t\t\t\t\t\tWhat we promise\n\t\t\t\t\t"),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "small" }, [
            _vm._v(
              "\n\t\t\t\t\t\tWe know that writing papers is tough. When you have a problem, it's even worse!\n\t\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "small" }, [
            _vm._v(
              "\n\t\t\t\t\t\tWe promise that we're committed to doing everything we can to help you quickly and effectively so you can keep focusing on writing amazing papers.\n\t\t\t\t\t"
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }