var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("generic-modal-wrap", { attrs: { "modal-id": "PageCount" } }, [
    _c("h2", { staticClass: "nodal-title" }, [_vm._v("\n\t\tPage Count\n\t")]),
    _vm._v(" "),
    _vm.passData && _vm.passData.pageCount
      ? _c(
          "table",
          { staticClass: "table table-border table-condensed mb-0" },
          [
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("Title Page")]),
                _vm._v(" "),
                _c("td", { staticClass: "text-end" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.passData.pageCount.titlePage) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("Abstract")]),
                _vm._v(" "),
                _c("td", { staticClass: "text-end" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.passData.pageCount.abstract) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("Body")]),
                _vm._v(" "),
                _c("td", { staticClass: "text-end" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.passData.pageCount.body) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("References")]),
                _vm._v(" "),
                _c("td", { staticClass: "text-end" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.passData.pageCount.references) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("Appendices")]),
                _vm._v(" "),
                _c("td", { staticClass: "text-end" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.passData.pageCount.appendices) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tr",
                {
                  class: {
                    "bg-success-100":
                      _vm.$store.state.customer.themeId ===
                      _vm.$enums.Theme.REGULAR,
                    "bg-success-700":
                      _vm.$store.state.customer.themeId ===
                      _vm.$enums.Theme.DARK,
                  },
                },
                [
                  _c("td", [_c("strong", [_vm._v("Total")])]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-end" }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.passData.pageCount.total)),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }