<!--
- Paper Edit > Write Tab > Paper Body
-
-->

<template>
	<div>
		<div :class="calcInstanceStyles" id="instancePaperBody">
		
			<paper-running-head page-type="body" />

			<h1
				v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA7 || $store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.MLA9"
				:class="[
					'text-center',
					{
						'fw-bold': $store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA7,
						'fw-normal': $store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.MLA9
					}]
				"
				id="h1PaperBodyTitle"
				>
				{{ firstPageTitleDisplay }}
			</h1>
			
			<textarea
				autocomplete="off"
				class="d-none" 
				id="ckPaperBody"
				:value="paperContentValue" />

			<paper-reference-section-discussion-post v-if="PaperType == $enums.PaperType.APA7_DiscussionPost || PaperType == $enums.PaperType.MLA9_DiscussionPost || PaperType == $enums.PaperType.Turabian9_DiscussionPost" />
			
			<paper-footnotes v-if="$store.state.paperEdit.config.renderCitationsAsFootnotes" />

		</div>
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import config from '@/config';
import configCkEditor from '@/ck/config-ck-editor';
import ErrS_Reroute from '@/services/app/error/reroute.js';
import LzString from 'lz-string';
import PaperFootnotes from './Footnotes';
import PaperReferenceSectionDiscussionPost from './ReferenceSectionDiscussionPost';
import PaperRunningHead from './RunningHead';
import PCKS_OnChange from '@/services/paper/ck/onChange';
import PCKS_OnFocus from '@/services/paper/ck/onFocus';
import PCKS_NumberFigureCaptions from '@/services/paper/ck/numberFigureCaptions'
import PCKS_NumberTableCaptions from '@/services/paper/ck/numberTableCaptions'
import PCKS_wProofreaderDestroy from '@/services/paper/ck/wProofreaderDestroy';
import PCKS_wProofreaderInit from '@/services/paper/ck/wProofreaderInit';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
// import PS_SaveDocument from '@/services/paper/saveDocument';
import RS_UpdateAfterSync from '@/services/reference/updateAfterSync';
import store from '@/store';

export default {
	name: 'PaperBody',
	data() {
		return {
			isGrammarlyChecked: true,	// should only happen once during first focus
			paperContentValue: '',
			paperMetaData: {},
		}
	},
	computed:{
		calcInstanceStyles(){
			let returnClassList = []

			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
				if(this.$store.state.paperEdit.meta.DocumentType == config.enums.DocumentType.DISCUSSION_POST){
					returnClassList.push('page-group', 'page-group-min-height', 'page-group-apa7', 'discussion-post-padding');
				} else {
					returnClassList.push('page-group', 'page-group-min-height', 'page-group-apa7');
				}

			} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
				if(this.$store.state.paperEdit.meta.DocumentType == config.enums.DocumentType.DISCUSSION_POST){
					returnClassList.push('page-group', 'page-group-turabian9', 'discussion-post-padding');
				} else {
					returnClassList.push('page-group', 'page-group-turabian9');
				}

				if(this.$store.state.paperEdit.footnotes.inPaper.length === 0){
					returnClassList.push('page-group-min-height');
				}
				
			}

			return returnClassList;
		},
		encryptedPaperLZString(){
			return this.$store.state.paperEdit.encryptedPaperLZString;
		},
		isPaperFooterOn(){
			return this.$store.state.paperEdit.isPaperFooterOn;
		},
		PaperType(){
			return this.$store.state.paperEdit.meta.PaperType;
		},
		firstPageTitleDisplay(){
			return this.$store.state.paperEdit.meta.Title || '';
		},
	},
	methods: {
		initEditorCk4(){
			if(this.$store.state.paperEdit.paperDataBody){
				// Vuex store has paper data body, use it (probably coming back to the Editor Tab)
				this.paperContentValue = this.$store.state.paperEdit.paperDataBody;
				// console.log(this.paperContentValue);
				this.$store.commit('loaders/ADD_ID', 'App');
			} else {
				// Normal load based on LoadPaper (probably first time loading in the Editor Tab)
				// console.log(this.$store.state.paperEdit.encryptedPaperLZString);
				this.paperContentValue = LzString.decompressFromUTF16(this.encryptedPaperLZString);
			}
			
			// console.log('this.paperContentValue');
			// console.log(this.paperContentValue);

			if(CKEDITOR && _has(CKEDITOR, 'instances')){
				// CKEDITOR exisits in this space, so it was added properly to the DOM
				if(_has(CKEDITOR.instances, 'ckPaperBody')){
					// Ck Instance - set the content
					CKEDITOR.instances.ckPaperBody.setData(this.paperContentValue);

				} else {
					// No Ck Instance - make one
					var $this = this;

					let configCkPaperBody = configCkEditor.getSettings('ckPaperBody');
					configCkPaperBody.on = {
						change(evt) {
							PCKS_OnChange({
								editorName: evt.editor.name,
							});
						},//e:change

						focus(evt) {
							PCKS_OnFocus({
								editorName: evt.editor.name,
							});
							
							// disable wProofreader if the user has Grammarly Chrome Extension
							if(config.useWProofreader && $this.isGrammarlyChecked){
								setTimeout(() => {
									let $instancePaperBody = document.getElementById('instancePaperBody');
									if($instancePaperBody){
										let grammarlyExtensionElements = $instancePaperBody.getElementsByTagName('grammarly-extension');

										if(grammarlyExtensionElements.length > 0){
											// console.log('Grammarly ON');
											PCKS_wProofreaderDestroy();
										}	
									}
									
									$this.isGrammarlyChecked = false;
								}, 500);
							}
						},//e:focus
							
						instanceReady(evt) {
							CKEDITOR.on('dialogDefinition', function (ev) {
								ev.data.definition.resizable = CKEDITOR.DIALOG_RESIZE_NONE;
							});
							
							evt.editor.$ckEditorType = config.enums.CkEditorType.BODY;
							
							PCKS_wProofreaderInit({
								container: evt.editor.window.getFrame().$,
							}).then(()=>{
								PCKS_NumberTableCaptions({
									ckEditor: evt.editor
								}).then(()=>{
									PCKS_NumberFigureCaptions({
										ckEditor: evt.editor
									}).then(()=>{
										RS_UpdateAfterSync().then(()=>{
											PS_BuildReferencesInPaper().then(()=>{
												store.dispatch('paperEdit/toc/generateContent').then(()=>{
													window.$vm.emitter.emit('generateReferencePage', {
														isSaveAfter: false,
														isStealthSave: false,
													});//e:generateReferencePage
													
													// remove citations that may be in the paper content but are not actually in the inPaper object (sometimes happens when removing a reference from the organize tab)
													_forEach(CKEDITOR.instances, (ckInstance)=>{
														if(!ckInstance.document){
															return false;
														}
														// console.log(ckInstance.document);
														
														// get all SPAN tags in this instance
														let spansNodeList = ckInstance.document.find('span');

														// loop through all citations in this instnace
														_forEach(spansNodeList.toArray(), (spanElement)=>{
															// console.log('spanElement');
															// console.log(spanElement);

															// check css classes
															if(spanElement.hasClass('perrla_citation')){
																// is this citation part of a group?
																if(spanElement.hasAttribute('data-group-unique-id')){
																	// get this citations uid from the data attribute
																	let citationGroupId = spanElement.getAttribute('data-group-unique-id');

																	// look up this full citation in the vuex store object
																	let thisCitationData = $this.$store.state.paperEdit.citations.inPaper.find((citation)=>{
																		return citation.groupUniqueID === citationGroupId;
																	});
																	// console.log('thisCitationData');
																	// console.log(thisCitationData);

																	// incase there wasn't a real citation found replace whatever what here with plain text
																	if(!thisCitationData){
																		let $textToInsert = new CKEDITOR.dom.text( document.createTextNode(spanElement.getText()));
																		$textToInsert.replace(spanElement.getParent());
																	}

																} else {
																	// get this citations uid from the data attribute
																	let citationUniqueID = spanElement.getAttribute('data-citation-unique-id');

																	// look up this full citation in the vuex store object
																	let thisCitationData = $this.$store.state.paperEdit.citations.inPaper.find((citation)=>{
																		return citation.citationData.citationUniqueID === citationUniqueID;
																	});

																	// console.log('thisCitationData');
																	// console.log(thisCitationData);

																	// incase there wasn't a real citation found replace whatever what here with plain text
																	if(!thisCitationData){
																		let $textToInsert = new CKEDITOR.dom.text( document.createTextNode(spanElement.getText()));
																		$textToInsert.replace(spanElement.getParent());
																	}
																}

															// } else {
															// 	// get the class list for this span element
															// 	let currentClassContains = spanElement.$.classList;
															// 	if(currentClassContains.length <= 0){
															// 		// this span tag doesn't have any css classes 
															// 		let $textToInsert = new CKEDITOR.dom.text( document.createTextNode(spanElement.getText()));
															// 		$textToInsert.replace(spanElement);
															// 	}
															}//e:if.hasClass

														});//e:forEach:span
													});//e:forEach:instance
													
													$this.emitter.emit('loadOutlineDrawer');
													$this.emitter.emit('loadDrawerResearchNotes');
													$this.emitter.emit('loadCkReferencePage');
													$this.emitter.emit('loadCkAppendices');
													$this.emitter.emit('calculateFootnoteDisplay');

													// reset all instances to clean
													_forEach(CKEDITOR.instances, (ckInstance)=>{
														ckInstance.resetDirty();
													});

													// remove the final spinner now that everything is loaded
													store.commit('loaders/REMOVE_ID', 'App');
													
												});//e:dispatch.paperEdit/toc/generateContent
											});//e:PS_BuildReferencesInPaper

										});//e:RS_UpdateAfterSync

									});//e:PCKS_NumberFigureCaptions

								});//e:PCKS_NumberTableCaptions
								
							});//e:PCKS_wProofreaderInit
								
						},//e:instanceReady

					};//e:on

					CKEDITOR.replace('ckPaperBody', configCkPaperBody);
					
				}
				
			} else {
				// CKEDITOR doesn't exisit, back out just in case
				ErrS_Reroute({
					pushToName: 'Dashboard'
				});
				
			}//e:if:else
			
		},//e:initEditorCk4

		
	},
	created() {
		// should $emit from TabEditor after PS_LoadByRoute is done (load the Body first)
		this.emitter.on('loadCkBody', () => {
			this.initEditorCk4();
		});
	},
	destroyed(){
		this.emitter.off('loadCkBody');

		this.$store.dispatch('paperEdit/inactivityAlert/endTimer');

		// store what "was" in the paper editor body before leaving the Editor tab (this will also set with /Paper/Edit leaves, so just be aware )
		if(CKEDITOR.instances.ckPaperBody){
			this.$store.commit('paperEdit/SET_PAPER_DATA_BODY', CKEDITOR.instances.ckPaperBody.getData());
			CKEDITOR.instances.ckPaperBody.focusManager.blur(true);
			CKEDITOR.instances.ckPaperBody.destroy(true);
		}
	},
	components: {
		PaperFootnotes,
		PaperReferenceSectionDiscussionPost,
		PaperRunningHead,
	}
}
</script>

<style scoped>
	.discussion-post-padding{
		padding-top: 5em !important;		
	}
</style>

