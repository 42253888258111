
<!--
- Paper Drawer > Outline
-->

<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title title="Outline" />
			<div class="d-grid mt-2">
				<button type="button" class="btn btn-outline-success" @click.prevent="btnInsertAll">
					Insert entire outline
				</button>
			</div>
		</div><!-- drawer-header -->

		<div class="drawer-body" id="outlineDrawerWrp">
			<div v-for="(nodeData, index) in outlineCraft" :key="index">
				<div v-if="nodeData.content === '/HR/'" key="divOutlineNodeBlank">
					<hr />
				</div>
				<div v-else key="divOutlineNode">
					<outline-row :node-data="nodeData" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import InsertRawHtml from '@/helpers/insert-raw-html';
import OutlineRow from './Outline/Row';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import TDS_Open from '@/services/paper/toolDrawer/open';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';
import Vue from 'vue';

export default {
	name: 'EditorOutline',
	data() {
		return {
			prefixLevelCounter: 0,
			idCounter: 0,

			previousNodeChild: null,
			injectCitationData: '',

			outlineCraft:[
				/*
				{
					content: 'Text of the outline node',
					level: 0,
					citation: '', (citationObject?)
					tag:	'p',	//or li?
				}
				*/
			],	// new array created from the parsed json outlineOutputJson
		}
	},
	methods: {
		btnInsertAll(){
			this.$store.commit('loaders/ADD_ID', 'App');

			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(this.$store.state.paperEdit.outline.fullHtml, 'text/html');

			// get all citation elements in the outline html
			let citationElList = parsedHtml.querySelectorAll('span.perrla_citation');
			// console.log(citationElList);

			// loop through each citation in the list
			for (let citationEl of citationElList) {
				let citationDataRaw = citationEl.getAttribute('citation-data') || null;
				
				// console.log(citationDataRaw);

				let citationDataObj = null;
				if(citationDataRaw){
					// console.log(citationEl);
					
					// transform this into a valid perrla citation
					citationDataObj = JSON.parse(window.atob(citationDataRaw));

					// let citationUid = citationEl.data('citation-unique-id');
					let citationUid = citationEl.getAttribute('citation-unique-id') || citationEl.getAttribute('data-citation-unique-id') || '';
					citationDataObj.citationData.citationUniqueID = citationUid;
					
					if(citationUid != ''){
						// add relevant attributes
						citationEl.setAttribute('data-citation-unique-id', citationUid);
						citationEl.setAttribute('data-reference-unique-id', citationDataObj.citationData.referenceUniqueID);

						// remove irrelevant attributes
						citationEl.removeAttribute('citation-data');
						citationEl.removeAttribute('data-reference-id');

						// console.log(citationEl);
						this.$store.commit('paperEdit/citations/ADD_TO_PAPER', citationDataObj);

						if(this.$store.state.paperEdit.config.renderCitationsAsFootnotes){
							this.$store.commit('paperEdit/footnotes/ADD_TO_PAPER', {
								citationUids: [citationUid],
								content: '<p>@@@</p>',
							});
						}
					}
				}
			}//e:for
			
			// console.log('parsedHtml.body.innerHTML');
			// console.log(parsedHtml.body.innerHTML);

			InsertRawHtml.insertText(parsedHtml.body.innerHTML);
			
			// after the citation is inserted update the values (this pays off when you have a group author with abbreviation and subsequent citations)
			Vue.nextTick(()=>{
				PS_BuildReferencesInPaper().then(()=>{
					this.$store.commit('loaders/REMOVE_ID', 'App');
				});//e:PS_BuildReferencesInPaper
			});
			
		},//e:btnInsertAll

		craftOutlineFromJson(){
			this.$store.commit('loaders/ADD_ID', 'App');

			this.outlineCraft = [];
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(this.$store.state.paperEdit.outline.fullHtml, 'text/html');
			
			// console.log('parsedHtml.body');
			// console.log(parsedHtml.body);

			this.processChildren(parsedHtml.body, -1);
			
			let arrayOfIndexesToRemove = [];

			// loop through each outline node
			this.outlineCraft.forEach((outlineNodeData, index)=>{
				// check this content for any html tags (ex: <strong></strong>) - https://stackoverflow.com/questions/15458876/check-if-a-string-is-html-or-not

				// i need this so skip citations, so an html tag of SPAN is ok
				if(
					outlineNodeData.content.includes('<em>') ||
					outlineNodeData.content.includes('<sub>') ||
					outlineNodeData.content.includes('<sup>') ||
					outlineNodeData.content.includes('<strong>') ||
					outlineNodeData.content.includes('<u>')
				){
					// found html used, remove the next index (it will just be the extraxted text and a duplicate)
					arrayOfIndexesToRemove.push(index + 1);
				}
			});//e:this.outlineCraft.forEach

			// loop backwards through the array of indexs to remove
			// loop in the direction you are splicing!
			// https://stackoverflow.com/questions/32682962/javascript-loop-through-array-backwards-with-foreach
			arrayOfIndexesToRemove.slice().reverse().forEach((removeIndex)=>{
				this.outlineCraft.splice(removeIndex, 1);
			});//e:forEach
			
			// console.log('this.outlineCraft');
			// console.log(this.outlineCraft);

			this.setOutlinePrefixLevels();

		},//e:craftOutlineFromJson
		
		// called after the outline tool drawer loads - i need to dress the content up
		dressNodeContent(){
			// turabian has footnotes which will appear in the content, separate that out from the content
			let $outlineDrawerWrp = document.getElementById('outlineDrawerWrp');

			if($outlineDrawerWrp){
				let citationsNodeList = $outlineDrawerWrp.querySelectorAll('span.perrla_citation');

				_forEach(citationsNodeList, (citationElement)=>{
					let footnoteLevel = citationElement.innerHTML;	// if this really was a footnote then the inner html will be whatever the footnote number WAS
					citationElement.outerHTML = '<sup>'+footnoteLevel+'</sup>';
					
				});//e:forEach

			}//e:if:$outlineDrawerWrp
		},//e:dressNodeContent


		processChildren(html, currentLevel){
			// console.log('processChildren');
			// console.log(html);
			let outlineNodes = html.childNodes;
			if (outlineNodes != null) {
				for (let node of outlineNodes) {
					let nodeCitationData = '';
					
					if (node.tagName && (node.tagName.toLowerCase() === 'ol' || node.tagName.toLowerCase() === 'ul')){
						// OL or UL
						this.processChildren(node, currentLevel + 1);

					} else{
						// console.log('node');
						// console.log(node);

						let nodeValue = '';

						let hasCitation = false;


						if (node.tagName && node.tagName.toLowerCase() === 'p' && node.childNodes.length === 0){
							// blank p tag is in here

							nodeValue = '/HR/';

						} else {
							// loop through all child nodes under this node
							node.childNodes.forEach((childNode, index)=>{

								if(childNode.nodeValue){
									// console.log('childNode');
									// console.log(childNode);
									nodeValue += childNode.nodeValue;
									
								} else {
									if(childNode.tagName){
										if(childNode.tagName.toLowerCase() === 'span' && childNode.className.includes('perrla_citation')){
											hasCitation = true;
											
											nodeCitationData = childNode.getAttribute('citation-data');
											if(nodeCitationData){
												let parsedCitationData = JSON.parse(window.atob(nodeCitationData));

												nodeValue += `<span class="perrla_citation"
													replace-citation-unique-id="` + parsedCitationData.citationData.citationUniqueID + `" 
													data-reference-unique-id="` + parsedCitationData.citationData.referenceUniqueID + `" 
													reference-unique-id="` + parsedCitationData.citationData.referenceUniqueID + `">` +
													childNode.textContent + `</span>`;
											}
										} else if(childNode.tagName.toLowerCase() === 'em'){
											nodeValue += '<em>' + childNode.textContent + '</em>';
										} else if(childNode.tagName.toLowerCase() === 'sub'){
											nodeValue += '<sub>' + childNode.textContent + '</sub>';
										} else if(childNode.tagName.toLowerCase() === 'sup'){
											nodeValue += '<sup>' + childNode.textContent + '</sup>';
										} else if(childNode.tagName.toLowerCase() === 'strong'){
											nodeValue += '<strong>' + childNode.textContent + '</strong>';
										} else if(childNode.tagName.toLowerCase() === 'u'){
											nodeValue += '<u>' + childNode.textContent + '</u>';
										}
									}
								}
								// console.log('nodeValue');
								// console.log(nodeValue);
							});//e:forEach
						}

						let setLevel = currentLevel;
						
						if (nodeValue != null ) {
							if (nodeValue.length > 0) {
								// console.log('nodeValue');
								// console.log(nodeValue);

								this.pushToOutlineCraft({
									citationData: nodeCitationData,
									content: nodeValue,
									level: setLevel,
								});	
							}						
						}

						if (node.childNodes != null) {
							if(hasCitation){
								// only process OL's after a citation
								node.childNodes.forEach(childNode => {
									if (childNode.tagName == 'OL' || node.tagName == 'UL') {
										this.processChildren(childNode, currentLevel + 1);
									}
								});								
							} else{
								this.processChildren(node, currentLevel);		
							}
						}
					}//e:if:!OL
				}//e:for
			}//e:if:!null
		},//e:processChildren

		pushToOutlineCraft(nodeData){
			// console.log('pushToOutlineCraft');
			// console.log('nodeData');
			// console.log(nodeData);

			this.idCounter++;	// just increment everytime you push so all ids should be unique
			
			this.outlineCraft.push({
				id: this.idCounter,
				content: nodeData.content,
				citation: (nodeData.citationData) ? JSON.parse(window.atob(nodeData.citationData)) : '',
				level: nodeData.level,
			});
			// console.log('this.outlineCraft');
			// console.log(this.outlineCraft);
		},//e:pushToOutlineCraft

		setOutlinePrefixLevels(){
			let prefixLevelCounters = new Array();
			prefixLevelCounters[0] = 0;
			prefixLevelCounters[1] = 0;
			prefixLevelCounters[2] = 0;
			prefixLevelCounters[4] = 0;
			prefixLevelCounters[5] = 0;
			prefixLevelCounters[6] = 0;
			prefixLevelCounters[7] = 0;
			prefixLevelCounters[8] = 0;
			prefixLevelCounters[9] = 0;
			prefixLevelCounters[10] = 0;
		
			for(let i = 0; i < this.outlineCraft.length; i++){
				if(i === 0){
					// first iteration, there isn't a last level to compare to
					this.outlineCraft[i].prefixLevel = 0;
					
				} else {
					let currentLevel = this.outlineCraft[i].level;
					let previousLevel = currentLevel;

					// after the first iteration, act normal
					previousLevel = this.outlineCraft[i - 1].level;
				
					if(currentLevel === previousLevel){
						// console.log('currentLevel == previousLevel')
						prefixLevelCounters[currentLevel]++;
						this.outlineCraft[i].prefixLevel = prefixLevelCounters[currentLevel];
					} else if(currentLevel > previousLevel){
						// console.log('currentLevel > previousLevel');
						prefixLevelCounters[currentLevel] = 0;
						this.outlineCraft[i].prefixLevel = prefixLevelCounters[currentLevel];
					} else if(currentLevel < previousLevel){
						// console.log('currentLevel < previousLevel');
						prefixLevelCounters[currentLevel]++;
						this.outlineCraft[i].prefixLevel = prefixLevelCounters[currentLevel];
					}
					
					// console.log('--');
				}//e:if:i===0
			}//e:for
			
			// console.log(this.outlineCraft);

			this.$store.commit('loaders/REMOVE_ID', 'App');

			this.$nextTick(()=>{
				if(this.$store.state.paperEdit.config.renderCitationsAsFootnotes){
					this.dressNodeContent();
				}//e:if:renderCitationsAsFootnotes
			});

		},//e:setOutlinePrefixLevels

		
	},
	created() {
		this.emitter.on('loadOutlineDrawer', () => {
			this.craftOutlineFromJson();
		});
	},
	mounted() {
		TDS_Open({
			drawerName: 'EditorOutline'
		});

		this.craftOutlineFromJson();	
	},
	components: {
		OutlineRow,
		ToolDrawerTitle
	}
}
</script>
