var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-5" },
    [
      _c("div", { staticClass: "dropstart" }, [
        _c("a", {
          staticClass: "svg bi-three-dots-vertical float-end",
          attrs: {
            href: "#",
            type: "button",
            "data-bs-toggle": "dropdown",
            role: "button",
            "aria-expanded": "false",
            "aria-label": "More Options",
          },
        }),
        _vm._v(" "),
        _c("p", {
          directives: [
            { name: "add-blank-target", rawName: "v-add-blank-target" },
          ],
          staticClass: "reference-text word-break",
          domProps: { innerHTML: _vm._s(_vm.referenceObject.displayValue) },
        }),
        _vm._v(" "),
        _c("ul", { staticClass: "dropdown-menu" }, [
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-primary",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.editReference.apply(null, arguments)
                  },
                },
              },
              [
                _c("span", { staticClass: "svg bi-pencil-fill" }),
                _vm._v("\n\t\t\t\t\tEdit Reference\n\t\t\t\t"),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-danger",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.removeRefFromPaper.apply(null, arguments)
                  },
                },
              },
              [
                _c("span", { staticClass: "svg bi-x-lg" }),
                _vm._v("\n\t\t\t\t\tRemove Reference\n\t\t\t\t"),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.searchTerm === ""
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6 col-md-3" }, [
              _c("div", { staticClass: "d-grid" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-hover btn-hover-success me-2",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.btnAddResearchNote.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\t+ Add research note\t\n\t\t\t\t")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.filteredResearchNotesForThisReference &&
            _vm.filteredResearchNotesForThisReference.length > 0
              ? _c("div", { staticClass: "col-6 col-md-3" }, [
                  _c("div", { staticClass: "d-grid" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-hover btn-hover-success",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.toggleIsExpanded.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm.isExpanded
                          ? _c("span", { key: "hideExpanded" }, [
                              _vm._v("Hide"),
                            ])
                          : _c("span", { key: "showExpanded" }, [
                              _vm._v("Show"),
                            ]),
                        _vm._v(" research notes\n\t\t\t\t"),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "route-fade" } }, [
        _vm.isExpanded || _vm.searchTerm != ""
          ? _c(
              "div",
              { key: "researchNotesExpanded", staticClass: "mt-2 ms-3" },
              [
                _c("transition", { attrs: { name: "route-fade" } }, [
                  _vm.isAddingNew
                    ? _c("div", [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newNoteTextareaValue,
                              expression: "newNoteTextareaValue",
                            },
                          ],
                          ref: "newTextArea",
                          staticClass: "form-control mb-3",
                          attrs: { rows: "2" },
                          domProps: { value: _vm.newNoteTextareaValue },
                          on: {
                            blur: _vm.blurNewNote,
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.newNoteTextareaValue = $event.target.value
                              },
                              function ($event) {
                                return _vm.typeInput($event.target.value)
                              },
                            ],
                            keypress: [
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                if (!$event.shiftKey) return null
                                $event.preventDefault()
                                return _vm.saveNewNote(true)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                if (
                                  $event.ctrlKey ||
                                  $event.shiftKey ||
                                  $event.altKey ||
                                  $event.metaKey
                                )
                                  return null
                                $event.preventDefault()
                                return _vm.saveNewNote(false)
                              },
                            ],
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "esc", 27, $event.key, [
                                  "Esc",
                                  "Escape",
                                ])
                              )
                                return null
                              return _vm.cancelNewNote.apply(null, arguments)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.filteredResearchNotesForThisReference &&
                _vm.filteredResearchNotesForThisReference.length > 0
                  ? _c(
                      "div",
                      { key: "divResearchNotesForThisReference" },
                      _vm._l(
                        _vm.filteredResearchNotesForThisReference,
                        function (researchNoteData) {
                          return _c("research-note-row", {
                            key: researchNoteData.genesisResearchNoteUniqueId,
                            attrs: {
                              "research-note-citation":
                                researchNoteData.citation,
                              "research-note-genesis-reference-id":
                                researchNoteData.genesisReferenceId,
                              "research-note-genesis-research-note-id":
                                researchNoteData.genesisResearchNoteId,
                              "research-note-genesis-research-note-unique-id":
                                researchNoteData.genesisResearchNoteUniqueId,
                              "research-note-reference-unique-id":
                                researchNoteData.referenceUniqueID,
                              "research-note-text": researchNoteData.note,
                            },
                          })
                        }
                      ),
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }