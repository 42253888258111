<!--
- Paper Edit > Research Notes Tab > Reference Row
-
-->

<template>
	<div class="mb-5">
		<div class="dropstart">
			<a href="#" class="svg bi-three-dots-vertical float-end" type="button" data-bs-toggle="dropdown" role="button" aria-expanded="false" aria-label="More Options" />

			<p class="reference-text word-break" v-add-blank-target v-html="referenceObject.displayValue" />
			
			<ul class="dropdown-menu">
				<li>
					<a class="dropdown-item hover hover-primary" href="#" @click.prevent="editReference">
						<span class="svg bi-pencil-fill" />
						Edit Reference
					</a>
				</li>
				<li>
					<a class="dropdown-item hover hover-danger" href="#" @click.prevent="removeRefFromPaper">
						<span class="svg bi-x-lg" />
						Remove Reference
					</a>
				</li>
			</ul>
		</div><!--dropstart-->

		<div class="row" v-if="searchTerm === ''">
			<div class="col-6 col-md-3">
				<div class="d-grid">
					<button
						class="btn btn-sm btn-hover btn-hover-success me-2"	
						type="button"
						@click.prevent="btnAddResearchNote">
						+ Add research note	
					</button>
				</div>
			</div>
			<div class="col-6 col-md-3" v-if="filteredResearchNotesForThisReference && filteredResearchNotesForThisReference.length > 0">
				<div class="d-grid">
					<button
						class="btn btn-sm btn-hover btn-hover-success"	
						type="button"
						@click.prevent="toggleIsExpanded">
						<span v-if="isExpanded" key="hideExpanded">Hide</span>
						<span v-else key="showExpanded">Show</span> research notes
					</button> 
				</div>
			</div>
		</div><!--row-->
		
		<transition name="route-fade">
			<div
				v-if="isExpanded || searchTerm != ''"
				class="mt-2 ms-3"
				key="researchNotesExpanded">
				<transition name="route-fade">
					<div v-if="isAddingNew">
						<textarea
							class="form-control mb-3"
							ref="newTextArea"
							rows="2"
							v-model="newNoteTextareaValue"
							@blur="blurNewNote"
							@input="typeInput($event.target.value)"
							@keypress.shift.enter.prevent="saveNewNote(true)"
							@keypress.enter.exact.prevent="saveNewNote(false)"
							@keyup.esc="cancelNewNote" />
							<!--  -->
							<!-- @input="typeInput($event.target.value)"-->
					</div>
				</transition>
				
				<div v-if="filteredResearchNotesForThisReference && filteredResearchNotesForThisReference.length > 0" key="divResearchNotesForThisReference">
					<research-note-row
						v-for="researchNoteData in filteredResearchNotesForThisReference"
						:key="researchNoteData.genesisResearchNoteUniqueId"
						:research-note-citation="researchNoteData.citation"
						:research-note-genesis-reference-id="researchNoteData.genesisReferenceId"
						:research-note-genesis-research-note-id="researchNoteData.genesisResearchNoteId"
						:research-note-genesis-research-note-unique-id="researchNoteData.genesisResearchNoteUniqueId"
						:research-note-reference-unique-id="researchNoteData.referenceUniqueID"
						:research-note-text="researchNoteData.note" />
				</div>
				
			</div><!--researchNotesExpanded-->
		</transition>
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _findIndex from 'lodash/findIndex'
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import _orderBy from 'lodash/orderBy';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import config from '@/config';
import mixinExpandTextareaById from '@/mixins/expand-textarea-by-id';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import PS_RemoveReferenceFromPaper from '@/services/paper/removeReferenceFromPaper';
import PS_SaveDocument from '@/services/paper/saveDocument';
import ResearchNoteRow from '@/modules/ResearchNotes/RowLive';
import RNS_CalcSortValue from '@/services/paper/researchNotes/calcSortValue';
import RS_GetValueOutput from '@/services/reference/getValueOutput';
import Swal from 'sweetalert2';

export default {
	name: 'ResearchReferenceRow',
	mixins: [mixinExpandTextareaById],
	props:{
		referenceObject:{
			required: true,
			type: Object,
		},
		searchTerm:{
			default: '',
			type: String,
		},
		sortByEnum:{
			default: config.enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST,
			type: Number,
		},
	},
	data() {
		return {
			hasActiveCitations: false,
			isAddingNew: false,
			isExpanded: false,
			newNoteTextareaValue: '',
		}
	},
	computed:{
		filteredResearchNotesForThisReference(){
			let searchedArray = [];

			let filterResearchNotesByReferenceId = this.$store.state.researchNotes.list.filter((researchNoteData)=>{
				return researchNoteData.referenceUniqueID.toUpperCase() === this.referenceObject.referenceUniqueID.toUpperCase();
			});

			let _searchTerm = this.searchTerm && this.searchTerm.toLowerCase();
			
			if(_searchTerm){
				filterResearchNotesByReferenceId.forEach((researchNoteData)=>{
					if(researchNoteData.note.toLowerCase().includes(_searchTerm)){
						// search term found
						searchedArray.push(researchNoteData);
					}
				});//e:forEach

			} else {
				searchedArray = filterResearchNotesByReferenceId;

			}

			switch(this.sortByEnum){
				case config.enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST:
					return searchedArray.reverse();
				case config.enums.ResearchNotesSortBy.CREATED_DATE_OLDEST_FIRST:
					return searchedArray;
				case config.enums.ResearchNotesSortBy.CITATION_FIRST_TO_LAST:
				case config.enums.ResearchNotesSortBy.CITATION_LAST_TO_FIRST:
					// calculate a sort by score
					searchedArray = _cloneDeep(searchedArray); // clone so i can add a sortby score below

					// sort by citation location
					searchedArray.forEach((researchNoteData)=>{
						// console.log(researchNoteData)
						let sortByScore = 0;

						let parsedCitationData = '';
						if(researchNoteData.citation !== ''){
							parsedCitationData = JSON.parse(researchNoteData.citation);
						}
						
						if(_has(parsedCitationData, 'citationData')){
							sortByScore = RNS_CalcSortValue({
								citationData: parsedCitationData.citationData,
								sortByEnum: this.sortByEnum,
							});
						}//e:if:_has
					
						// console.log(sortByScore);
						researchNoteData.sortByScore = sortByScore;
					});//e:forEach

					return _orderBy(searchedArray, ['sortByScore'], ['desc']);
			}
			
			return null;

		},//e:filteredResearchNotesForThisReference
	},
	methods: {
		blurNewNote(){
			let _newNoteTextareaValue = this.newNoteTextareaValue.trim();

			// empty content for new research note
			if(_newNoteTextareaValue === ''){
				this.cancelNewNote();
				return false;
			} else {
				this.saveNewNote();
			}
		},//e:blurNewNote
		
		btnAddResearchNote(){
			// alert('btnAddResearchNote');
			// if sort was NOT by date, make it by date now
			if(this.sortByEnum !== config.enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST){
				this.$emit('set-sort-by-date-newest-first');
				this.emitter.emit('globalToasterOpen',{
					textContent: 'Sort changed to Created Date (newest first)',
					variant: 'warning'
				});
			}
			
			this.isAddingNew = true;
			this.isExpanded = true;

			this.$nextTick(()=>{
				this.$refs.newTextArea.focus();
			});

		},//e:btnAddResearchNote

		calcGridData(){
			// this function left intentionally blank
			
		},//e:calcGridData

		cancelNewNote(){
			this.isAddingNew = false;
			this.newNoteTextareaValue = '';
		},

		editReference(){
			// look up the reference in the real referenceLibraryGenesis object
			let thisReferenceFind = this.$store.state.referenceLibraryGenesis.find((ref) => {
				return ref.referenceUniqueID.toUpperCase() == this.referenceObject.referenceUniqueID.toUpperCase()
			});

			if(thisReferenceFind){
				this.$store.commit('paperEdit/SET_EDIT_REFERENCE', thisReferenceFind);

				this.emitter.emit('openRouteModal', {
					modalRoute: 'PaperTabResearchEditReferenceBody',
					modalParams: {
						referenceUid: thisReferenceFind.referenceUniqueID.toUpperCase(),
					},
				});
			}
		},//e:editReference

		removeReference(){
			PS_RemoveReferenceFromPaper({
				hasActiveCitations: this.hasActiveCitations,
				isDrawer: false,
				paperID: this.$store.state.paperEdit.meta.PaperID,
				reference: this.referenceObject,
			}).then(()=>{
				// this is in the organize tab, there isn't a reference page to generate / save so do that part "manually"
				PS_BuildReferencesInPaper().then(()=>{
					// craft a message that will eventually make it's way to saveDocument
					let referenceDisplayValue = RS_GetValueOutput({
						displayFormatVersionId: this.$store.state.customer.preferredFormatVersionID,
						referenceData: this.referenceObject,
					});

					PS_SaveDocument({
						isMetaDataChanges: true,
						isStealthSave: true,
						message: 'Removed reference - ' + referenceDisplayValue
					});
				});
			});
		},//e:removeReference

		removeRefFromPaper(){
			let findCitation = false;

			// loop through all the references - to find this citation
			_forEach(this.$store.state.paperEdit.referencesInPaper, (referenceObject)=>{
				if(_has(referenceObject, 'citations') && referenceObject.citations.length > 0){
					_forEach(referenceObject.citations, (citationObject)=>{
						if(citationObject.citationData.referenceUniqueID.toUpperCase() === this.referenceObject.referenceUniqueID.toUpperCase()){
							findCitation = true;
							return false;	// stop looping once it's found
						}
					});//e:forEach:citations
				}//e:if:_has
			});//e:forEach:referencesInPaper
			
			if(findCitation){
				// Active Citations - confirm delete
				this.hasActiveCitations = true;

				Swal.fire({
					allowOutsideClick: false,
					buttonsStyling: false,
					title: 'Are you sure?',
					text: 'Removing the Reference from your paper will also delete its citations in your paper.  Are you sure you want to remove this reference?',
					icon: 'warning',
					cancelButtonText: 'No, Cancel',
					confirmButtonText: 'Yes, Remove',
					showCloseButton: false,
					showCancelButton: true,
					showConfirmButton: true,
					customClass:{
						cancelButton: 'btn btn-outline-danger',
						confirmButton: 'btn btn-success me-2',
					},
				}).then((result) => {
					if (result.value) {
						this.removeReference();
					}
				});

			} else {
				// No Active Citations - Just Remove it
				this.hasActiveCitations = false;
				this.removeReference();

			}//e:if:else:findCitation

		},//e:removeRefFromPaper

		saveNewNote(isAddNewAfter){
			let _newNoteTextareaValue = this.newNoteTextareaValue.trim();

			// empty content for new research note
			if(_newNoteTextareaValue === ''){
				this.cancelNewNote();
				return false;
			}

			let sendData = {
				'Citation': '',
				"GenesisReferenceID": this.referenceObject.referenceID,
				"Note": _newNoteTextareaValue,
			}
			
			// console.log('sendData');
			// console.log(sendData);

			ApiS_MakeCall({
				body: sendData,
				method: 'POST',
				responseType: 'json',
				url: 'v3/GenesisResearchNote',
			}).then((responseData)=>{
				// console.log('responseData');
				// console.log(responseData);

				let addResearchNoteData = {
					citation: '',
					citationOutput: '',
					genesisReferenceId: responseData.genesisReferenceID,
					genesisResearchNoteId: responseData.genesisResearchNoteID,
					genesisResearchNoteUniqueId: responseData.genesisResearchNoteUniqueID,
					note: responseData.note,
					referenceUniqueID: this.referenceObject.referenceUniqueID.toUpperCase(),
				}

				this.$store.commit('researchNotes/ADD_TO_LIST', addResearchNoteData);

				this.isAddingNew = false;
				this.newNoteTextareaValue = '';
				
				this.emitter.emit('globalToasterOpen',{
					textContent: 'Research note created',
				});

				this.$nextTick(()=>{
					if(isAddNewAfter){
						this.btnAddResearchNote();
					}
				});
				
				// this.calcGridData();
			
			});//e:ApiS_MakeCall
		
		},//e:saveNewNote

		typeInput(value){
			this.$emit('input', value);
			this.expandTextarea('newTextArea');
		},//e:typeInput

		toggleIsExpanded(){
			this.isExpanded = !this.isExpanded;
		},
	},
	components: {
		ResearchNoteRow
	}
}
</script>

<style lang="scss" scoped>
	@import "../../../assets/styles/vars_perrla";
	
	@include color-mode(dark) {
		.dropstart {
			.bi-three-dots-vertical {
				&:hover {
					background: $gray-800;
				}
			}
		}

	}
	.dropstart {
		.bi-three-dots-vertical {
			border-radius: $border-radius;
			font-size: 1.25rem;
			&:hover {
				background: $gray-200;
			}
		}
	}


	.reference-text{
		margin-bottom: 0rem;
		font-size: 1rem;
		line-height: 1.5rem;
	}
</style>