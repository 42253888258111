/*
- Modals
-- new way to open/close Modals
-- ex: this.$store.state.modals.x
*/
export default {
	namespaced: true,
	state:{
		passData: {},	// Empty Object of data before a modal opens
		showArray: [],	// Array of modal ids (strings) that are open (each modalr component deals with this on it's own)
	},//e:state

	mutations: {
		CLEAR_MODAL_DATA(state){
			state.passData = {};
		},
		CLOSE(state, modalId){
			if(state.showArray.includes(modalId)){
				state.showArray.splice(state.showArray.indexOf(modalId), 1);
			}
		},
		OPEN(state, modalId){
			if(!state.showArray.includes(modalId)){
				state.showArray.push(modalId);
			}
		},
		SET_MODAL_DATA(state, passData){
			state.passData = passData;
		},
	},//e:mutations
}
