<template>
	<div :class="calcInstanceStyles" v-if="showReferencePage" id="instanceReferencePage">

        <paper-running-head page-type="references" />

		<h1 :class="['text-center', {'fw-bold': $store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA7, 'fw-normal': $store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.MLA9}]">
			{{ referenceTitleDisplay }}
		</h1>

		<div id="ckPaperReferences">
			<reference-page-row
				v-for="referenceObject in referenceObjectsInPaper"
				:key="referenceObject.referenceUniqueID"
				:reference-object="referenceObject"
				:reference-unique-id="referenceObject.referenceUniqueID" />
		</div>
    </div>

</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import config from '@/config';
import PaperRunningHead from './RunningHead';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import PS_SaveDocument from '@/services/paper/saveDocument';
import ReferencePageRow from './ReferencePageRow';

export default {
	name: 'PaperReferencePage',
	data() {
		return {
			referenceObjectsInPaper: [],	// starts empty - fills in after paper loads
		}
	},
	computed:{
		calcInstanceStyles(){
			let returnClassList = ['instance-references']

			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
				returnClassList.push('page-group page-group-min-height page-group-apa7');

			} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
				returnClassList.push('page-group page-group-min-height page-group-mla9');

			} else if (this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
				returnClassList.push('page-group page-group-min-height page-group-turabian9');
			}

			if(this.$store.state.paperEdit.meta.DocumentType == config.enums.DocumentType.DISCUSSION_POST){
				returnClassList.push('discussion-post-padding');
			}

			return returnClassList;
		},
		referenceTitleDisplay(){
			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
				return 'Bibliography';

			} else {
				if(this.$store.state.paperEdit.meta.DocumentType === config.enums.DocumentType.ANNOTATED_BIBLIOGRAPHY || this.$store.state.paperEdit.meta.DocumentType === config.enums.DocumentType.REFERENCE_LIST){
					// AB or RL - always return paper "title"
					return this.$store.state.paperEdit.meta.Title;

				} else {
					// RP or DP - check format
					if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
						// check for single or multiple referneces
						if(this.referenceObjectsInPaper.length === 1){
							return 'Work Cited';
						} else {
							return 'Works Cited';
						}//e:if
					}//e:if
				}//e:if
			}//e:if
			
			return 'References';
		},
		showReferencePage(){
			if(this.$store.state.paperEdit.meta.DocumentType === config.enums.DocumentType.ANNOTATED_BIBLIOGRAPHY || this.$store.state.paperEdit.meta.DocumentType === config.enums.DocumentType.REFERENCE_LIST){
				return true;
			} else if(this.referenceObjectsInPaper.length > 0){
				return true;
			}
			return false;
		},
	},
	methods:{
		generateReferencePage($opts){
			const _$opts = $opts || {};

			const _isSaveAfter = _has(_$opts, 'isSaveAfter') ? _$opts.isSaveAfter : false;
			const _isStealthSave = _has(_$opts, 'isStealthSave') ? _$opts.isStealthSave : false;
			const _saveMessage = _has(_$opts, 'saveMessage') ? _$opts.saveMessage : '';

			this.referenceObjectsInPaper = [];
			
			// let the reference page reset to empty - the next tick will put them all back in, which calls mounted() for each one
			this.$nextTick(()=>{
				this.$store.state.paperEdit.referencesInPaper.forEach((referenceObject)=>{
					// console.log(referenceObject);
					// do not show deleted references
					if(!referenceObject.isDeleted){
						switch(this.$store.state.paperEdit.meta.PaperFormatVersionID){
							case config.enums.Format.APA7:
								// do not show Interview type for APA7
								if(referenceObject.referenceTypeID != 23){
									this.referenceObjectsInPaper.push(referenceObject);
								}
								break;
							default:
								this.referenceObjectsInPaper.push(referenceObject);
								break;
						}
					}
				});
					
				if(_isSaveAfter){
					PS_SaveDocument({
						isMetaDataChanges: true,
						isStealthSave: _isStealthSave,
						message: _saveMessage,
					});
				}
			});
		},
	},
	created() {
		this.emitter.on('addReferenceAnnotations', ()=>{
			this.$store.commit('paperEdit/annotations/IS_INCLUDE', true);
			
			this.$nextTick(()=>{
				this.emitter.emit('globalToasterOpen',{
					textContent: 'Annotations added',
				});

				this.generateReferencePage();

				if(document.getElementById('instanceReferencePage')){
					document.getElementById('instanceReferencePage').scrollIntoView();
				}
			});
		});//e:addReferenceAnnotations

		// should rebuild all reference page row items (doing it this way because ckEditor does weird stuff when Vue changes the DOM)
		this.emitter.on('generateReferencePage', ($opts)=>{
			this.generateReferencePage($opts);
		});//e:generateReferencePage

		// should $emit from TabEditor after PS_LoadByRoute is done
		this.emitter.on('loadCkReferencePage', () => {
			if(this.$store.state.paperEdit.meta.DocumentType == config.enums.DocumentType.ANNOTATED_BIBLIOGRAPHY || this.$store.state.paperEdit.meta.DocumentType == config.enums.DocumentType.REFERENCE_LIST){
				// AB and RL don't have any body ck instances, so take over those instance ready callbacks here
				PS_BuildReferencesInPaper().then(()=>{
					this.emitter.emit('generateReferencePage', {
						isSaveAfter: false,
						isStealthSave: false,
					});
				});
			}
		});//e:loadCkReferencePage
	},
	components: {
		PaperRunningHead,
		ReferencePageRow,
	},
	destroyed(){
		this.emitter.off('addReferenceAnnotations');
		this.emitter.off('loadCkReferencePage');
		this.emitter.off('generateReferencePage');
	},
}
</script>
