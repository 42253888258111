<!--
- References > List
-- sort & search grid
-- each row is a document
-->

<template>
	<div>
		<div class="d-flex flex-row mb-2">
			<h1 class="float-start me-3 mb-0">
				References
			</h1>

			<button class="btn btn-success float-start me-3" type="button" @click.prevent="btnCreateReference">
				<span class="svg bi-plus-lg me-1" /> Create a new reference
			</button>

			<button v-if="$store.state.app.showBetaFeatures" class="btn btn-outline-warning float-start me-3" title="Rebuild References" @click.prevent="btnRebuildGrid" type="button">
				<span class="svg icons8-undo" />
			</button>

			<div class="flex-fill">
				<div class="dropdown float-end">
					<a class="btn-dropdown dropdown-toggle me-2" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
						{{ preferredFormatVersionIdOutput }}
					</a>
					
					<ul class="dropdown-menu dropdown-menu-end">
						<li>
							<a
								:class="[
									'dropdown-item', {
										'dropdown-item-selected': $store.state.references.displayFormatVersionId === $enums.Format.APA7,
										'hover hover-primary': $store.state.references.displayFormatVersionId !==  $enums.Format.APA7
									}
								]"
								href="#"
								@click.prevent="setPreferredFormatVersionId($enums.Format.APA7)">
								APA 7
							</a>
						</li>
						<li>
							<a
								:class="[
									'dropdown-item', {
										'dropdown-item-selected': $store.state.references.displayFormatVersionId === $enums.Format.MLA9,
										'hover hover-primary': $store.state.references.displayFormatVersionId !==  $enums.Format.MLA9
									}
								]"
								href="#"
								@click.prevent="setPreferredFormatVersionId($enums.Format.MLA9)">
								MLA 9
							</a>
						</li>
						<li>
							<a
								:class="[
									'dropdown-item', {
										'dropdown-item-selected': $store.state.references.displayFormatVersionId === $enums.Format.Turabian9,
										'hover hover-primary': $store.state.references.displayFormatVersionId !==  $enums.Format.Turabian9
									}
								]"
								href="#"
								@click.prevent="setPreferredFormatVersionId($enums.Format.Turabian9)">
								Turabian 9
							</a>
						</li>
					</ul>
				</div><!--dropdown-->
			</div>
			
			<grid-search
				class="ms-auto col-4"
				placeholder-text="Search References"
				@update-grid="calcGridData" />

		</div><!--flex-row-->


		<div class="row">
			<div class="col-12 min-height overflow-y-auto position-relative" ref="divReferenceListWrp">
				<table v-if="filteredGridData.length > 0" class="table table-borderless entity-grid table-fixed-header">
					<thead>
						<tr>
							<sortable-grid-th
								cell-text="Title"
								sort-key="title"
								:is-local-storage="true"
								:is-title="true"
								:entity-id="entityId"
								@update-grid="calcGridData" />
							<th class="table-spacer" />
							<sortable-grid-th
								cell-text="Created"
								set-width="175px"
								sort-key="createdDate"
								:is-local-storage="true"
								:is-title="true"
								:entity-id="entityId"
								@update-grid="calcGridData" />

							<th width="30px">
								&nbsp;	<!-- action menu -->
							</th>
						</tr>
					</thead>
					<tbody>
						<reference-row
							v-for="(reference, index) in filteredGridData"
							:key="index"
							:reference-data="reference"
							@update-grid="calcGridData" />
					</tbody>
				</table>
				<div v-else class="text-muted text-center mt-5">
					<p>No references found.</p>
				</div>
			</div>
		</div>

		<route-modal />
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import AS_SyncData from '@/services/app/syncData';
import CleanReferenceValue from '@/helpers/clean-reference-value';
import config from '@/config';
import GridSearch from '@/components/EntityGrid/Search';
import mixinResizer from '@/mixins/resizer';
import ReferenceRow from '@/components/EntityGrid/ReferenceRow';
import RouteModal from '@/components/modals/RouteModal';
import SortableGridTh from '@/components/SortableGridTh';

export default {
	name: 'ReferenceList',
	mixins: [mixinResizer],
	data() {
		return {
			filteredGridData: [],
			rzIgnoreBreakpoints: false,
			rzRef: 'divReferenceListWrp',
		}
	},
	computed:{
		entityId(){
			return config.enums.Entity.REFERENCE;
		},
		preferredFormatVersionIdOutput(){
			switch(this.$store.state.references.displayFormatVersionId){
				case config.enums.Format.MLA9:
					return 'MLA 9';
				case config.enums.Format.Turabian9:
					return 'Turabian 9';
			}
			return 'APA 7';
		},
	},
	methods: {
		btnCreateReference(){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'ReferenceListCreateReferenceStep1',
				modalParams: {
					openReferenceTab: 'common',
				},
			});
		},//e:btnCreateReference

		btnRebuildGrid(){
			// beta feature only for now
			this.$store.commit('loaders/ADD_ID', 'App');
			this.$store.commit('REFERENCE_LIBRARY', []);
			this.$store.commit('REFERENCE_LIBRARY_COMPRESSED_STRING', '');
			this.$store.commit('REFERENCE_LIBRARY_GENESIS', []);
			this.$store.commit('REFERENCE_LIBRARY_GENESIS_COMPRESSED_STRING', '');
			this.$store.commit('LAST_SYNC_DATE', '');

			// nexttick so the vuex store can get all the updates, before doing a full resync
			this.$nextTick(()=>{
				AS_SyncData({
					isStealthSync: true
				}).then(()=>{
					this.$store.commit('loaders/REMOVE_ID', 'App');
					this.emitter.emit('globalToasterOpen',{
						textContent: 'References rebuilt',
					});
				});
			});
		},//e:btnRebuildGrid
		
		calcGridData(){
			// console.log('calcGridData');

			let searchedArray = [];
			let isTagSearch = false;
			let _data = [];

			let dataLegacyReferences = _cloneDeep(this.$store.state.referenceLibrary);
			let dataGenesisReferences = _cloneDeep(this.$store.state.referenceLibraryGenesis);
			_data = dataLegacyReferences.concat(dataGenesisReferences);
			
			let _searchTerm = this.$store.state.gridSorts.searchTerm;

			if(typeof _searchTerm !== 'undefined' && _searchTerm.length > 0){
				if(_searchTerm.charAt(0) === '#'){
					isTagSearch = true;
					_searchTerm = _searchTerm.substring(1);
				} 
				_searchTerm = _searchTerm.toLowerCase();
			}

			// loop through each reference and prep for display
			_data.forEach((ref) =>{
				// skip references that have been deleted from the library
				if(ref.isDeleted){
					return false;
				}

				// Parse the display name 
				if(_has(ref, 'values')){
					// since it has values it should be a genesis reference
					
					// Parse the display name 
					let refValues = JSON.parse(ref.values);
					// console.log(refValues);
					if(_isEmpty(refValues)){
						ref.displayValue = '';
					} else {
						ref.displayValue = CleanReferenceValue(refValues.apa7.value);
					}

					ref.legacyReferenceData = '';
					
					// console.log(ref);
					
				} else {
					// APA 6 or MLA 8
					if(ref.formatVersionID === config.enums.Format.APA6){
						// APA6
						ref.displayValue = CleanReferenceValue(ref.value);
					} else {
						// Everything Else
						ref.displayValue = CleanReferenceValue(ref.value);
					}
				}

				// Search by value if exisits
				if(_searchTerm){
					if(isTagSearch){
						// search by tag
						if(ref.tags && ref.tags.toLowerCase().includes(_searchTerm)){
							searchedArray.push(ref);
						}
					} else {
						// search by all
						if(
							(ref.displayValue && ref.displayValue.toLowerCase().includes(_searchTerm)) ||
							(ref.legacyReferenceData && ref.legacyReferenceData.toLowerCase().includes(_searchTerm)) ||
							(ref.nickname && ref.nickname.toLowerCase().includes(_searchTerm)) ||
							(ref.tags && ref.tags.toLowerCase().includes(_searchTerm))
						){
							searchedArray.push(ref);
						}
					}
				} else {
					// Not searching just pass through
					searchedArray.push(ref);
				}//e:_searchTerm
			});//e:forEach

			switch(this.$store.state.gridSorts.referenceListOrderBy){
				case 'title':
					if(this.$store.state.gridSorts.referenceListSorts['title'] === 1){
						this.filteredGridData = _orderBy(_uniqBy(searchedArray, 'referenceID'), [(gridData) => gridData.displayValue.toLowerCase()], ['asc']);
					} else {
						this.filteredGridData = _orderBy(_uniqBy(searchedArray, 'referenceID'), [(gridData) => gridData.displayValue.toLowerCase()], ['desc']);
					}
					break;
				case 'createdDate':
					if(this.$store.state.gridSorts.referenceListSorts['createdDate'] === 1){
						this.filteredGridData = _orderBy(_uniqBy(searchedArray, 'referenceID'), ['createdDate', (gridData) => gridData.displayValue.toLowerCase()], ['asc', 'asc']);
					} else {
						this.filteredGridData = _orderBy(_uniqBy(searchedArray, 'referenceID'), ['createdDate', (gridData) => gridData.displayValue.toLowerCase()], ['desc', 'asc']);
					}
					break;
			}//e:switch

			this.$nextTick(()=>{
				this.calcHeight();
				this.$store.commit('loaders/REMOVE_ID', 'App');
			});
		},//e:calcGridData
		
		setPreferredFormatVersionId(enumUpdateTo){
			this.$store.commit('references/SET_DISPLAY_FORMAT_VERSION_ID', enumUpdateTo);
			this.calcGridData();
		},//e:setPreferredFormatVersionId
	},
	created(){
		this.emitter.on('updateReferenceList', ()=>{
			this.calcGridData();
		});
		
	},
	mounted(){
		this.$store.commit('loaders/ADD_ID', 'App');
		
		AS_SyncData({
			isStealthSync: true
		}).then(()=>{
			// this.$store.commit('loaders/REMOVE_ID', 'App');
			this.$store.dispatch('referenceRules/deepClear').then(()=>{
				this.$store.commit('references/SET_DISPLAY_FORMAT_VERSION_ID', this.$store.state.customer.preferredFormatVersionID);

				this.calcGridData();
			});
		});
	},
	components: {
		GridSearch,
		ReferenceRow,
		RouteModal,
		SortableGridTh
	},
	destroyed(){
		this.emitter.off('updateReferenceList');
		this.$store.commit('loaders/REMOVE_ID', 'App');
	},
}
</script>
