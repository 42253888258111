<!--
- PERRLA Account Settings
-- 2.0 to start 2023
-->

<template>
	<div class="px-3 py-2 settings-wrp">

		<div class="d-flex flex-row mb-2">
			<h1 class="float-start me-3 mb-0">
				PERRLA Settings
			</h1>
		</div><!--flex-row-->

		<div :class="['d-grid g-settings-header mb-3', {'g-settings-free-trial': $store.state.customer.role === $enums.Status.FREE_TRIAL}]" v-if="!isSuscriptionExpired">
			<router-link
				class="btn btn-primary"
				:to="{
					name: 'Dashboard'
				}">
				<span class="svg icons8-monitor" />
				<div class="button-text">
					<span class="fw-bolder">PERRLA Online:</span><br />
						Go to our web-based application for writing papers
				</div>
			</router-link>

			<a class="btn btn-primary header-button" :href="calcUrlToEnv($enums.URL_TO_ENV.PERRLA, 'word-setup')" target="_blank" rel="noopener noreferrer">
				<span class="svg icons8-microsoft-word" />
				<div class="button-text">
					<span class="fw-bolder">PERRLA for Word:</span><br />
					Instructions to install our Microsoft Word Add-in
				</div>
			</a>

			<a class="btn btn-primary header-button" href="https://perrla.zendesk.com/hc/en-us/articles/8198438301595" target="_blank" rel="noopener noreferrer">
				<span class="svg icons8-chrome" />
				<div class="button-text">
					<span class="fw-bolder">Chrome Extension:</span><br />
					Instructions to install our Google Chrome Extension
				</div>
			</a>

			<router-link
				class="btn btn-primary"
				:to="{
					name: 'ReferralRewards'
				}">
				<span class="svg icons8-friends" />
				<div class="button-text">
					<span class="fw-bolder">Refer A Friend:</span><br />
						Help your friends use PERRLA &amp; get 3 months of PERRLA free
				</div>
			</router-link>

		</div><!--g-setting-header-->

		<div class="row">
			<div class="col">
				<nav-tabs>
					<nav-tab :to="{name:'MyProfile'}">
						My Profile
					</nav-tab>

					<nav-tab :to="{name:'YourPapers'}" v-if="isSuscriptionExpired">
						Your Papers
					</nav-tab>

					<nav-tab :to="{name:'AccountBilling'}" v-if="!isSuscriptionExpired && $store.state.customer.role !== $enums.Status.FREE_TRIAL">
						Account &amp; Billing
					</nav-tab>

					<nav-tab :to="{name:'PerrlaOnline'}" v-if="!isSuscriptionExpired">
						PERRLA Online Settings
					</nav-tab>

				</nav-tabs>

				<transition name="route-fade" mode="out-in" appear>
					<router-view />
				</transition>
			</div><!--col-->
		</div><!--row-->
		
	</div>
</template>

<script>
import config from '@/config';
import mixinUrlToEnv from '@/mixins/url-to-env.js';
import NavTab from '@/components/NavTab';
import NavTabs from '@/components/NavTabs';

export default {
	name: 'AccountSettings',
	mixins: [mixinUrlToEnv],
	computed:{
		isCancelled(){
			return this.$store.state.customer.isCancelled;
		},
		isSuscriptionExpired(){
			if(this.$store.state.customer.expirationDate && (this.$store.state.customer.expirationDate < config.today)){
				return true;
			}
			return false;
		},
	},
	mounted() {
		// automatically open the My Profile tab
		if(this.$route.name === "Settings"){
			this.$router.push({
				name: 'MyProfile',
			}).catch(()=>{});
		}
	},
	components: {
		NavTab,
		NavTabs,
	}
}
</script>

<style lang="scss">
@import "../assets/styles/vars_perrla";
$icon-size: 64px !default;

@include color-mode(dark) {
	.settings-wrp{
		h3 {
			color: $dark-body-color !important;
		}
		.g-settings-header{
			a {
				.svg {
					color: $dark-body-color;
				}
			}
		}
	}
}

.settings-wrp{
	h3 {
		color: $body-color !important;
		margin-top: 1rem !important;
		margin-bottom: 0.5rem !important;
		font-size: 1.6rem !important;
		line-height: 2.4rem !important;
		font-weight: 600 !important;
	}
	
	.g-settings-header{
		column-gap: 0.75rem;
		grid-template-columns: 1fr;
		grid-template-rows: auto auto auto auto;
		row-gap: 0.75rem;

		@include media-breakpoint-up(sm) { 
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: auto auto;
		}

		@include media-breakpoint-up(lg) { 
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: auto;
		}

		// free trial users can't refer a friend - adjust the columns
		&.g-settings-free-trial{
			grid-template-columns: 1fr;
			grid-template-rows: auto auto auto;

			@include media-breakpoint-up(md) { 
				grid-template-columns: repeat(3, 1fr);
				grid-template-rows: auto;
			}
		}
		
		a {
			.svg {
				color: $white;
				display:none;
				float: left;
				font-size: $icon-size;
				line-height: 1;

				@include media-breakpoint-up(sm) { 
					display:block;
				}
			}

			.button-text {
				color: $white;
				margin-left: 0;
				text-align: center;

				@include media-breakpoint-up(sm) { 
					margin-left: $icon-size - 6px;	// width of image plus padding
				}
			}
		}
	}
	
}
</style>