/*
- Single Vuex Module for Paper Edit
-- should only contain items needed for the Paper/Edit page
-- keep this to items directly relevant to the current paper that is being edited
*/

// submodules under PaperEdit
import abstract from './paperEdit/abstract';
import annotations from './paperEdit/annotations';
import appendices from './paperEdit/appendices';
import authorsNote from './paperEdit/authorsNote';
import autoSave from './paperEdit/autoSave';
import citations from './paperEdit/citations';
import config from './paperEdit/config';
import ck from './paperEdit/ck';
import footnotes from './paperEdit/footnotes';
import inactivityAlert from './paperEdit/inactivityAlert';
import localVersion from './paperEdit/localVersion';
import meta from './paperEdit/meta';
import outline from './paperEdit/outline';
import review from './paperEdit/review';
import researchNotes from './paperEdit/researchNotes';
import toc from './paperEdit/toc';
import toolDrawer from './paperEdit/toolDrawer';

export default {
	namespaced: true,
	modules: {
		abstract,
		annotations,
		appendices,
		authorsNote,
		autoSave,
		citations,
		config,
		ck,
		footnotes,
		inactivityAlert,
		localVersion,
		meta,
		outline,
		researchNotes,
		review,
		toc,
		toolDrawer,
	},
	state: {
		// local storage states
		openReferenceUID: '',			// referenceUniqueID of which reference has an open arrow and displayed citations (refs and cits panel)
		organizeTabResearchNotesWidthPercentage: 49,

		// non local storage states
		activeVersionId: '',			// String: the versionID for the "active" version of a paper - sets in 
		editReference: {},						// an open reference the user is editing 
		encryptedPaperLZString: '',		// String: LZ Encoded response.data from /v3/Paper (happens in loadPaperData() in PaperEdit.vue) - only here so i can reload the paper during tab change
		isCitationContextMenuOpen: false,	// Bool: lets citaitonContextMenu.js know what keys to listen for
		isPaperUnsaved: false,					// Bool: sets to true anytime the paper saves - it becomes false again when a ck instance changes
		isPaperFooterOn: false,					// Bool: tracks word count at the bottom of the Paper Edit page
		
		openObject:	{},	// the paper open that is being edited
		organizeTabWidth: 0,

		paperDataBody: 	'',		// same as CKEDITOR.instances.ckPaperBody.getData(); but for when the Editor tab isn't active
		
		referencesInPaper: [], 	// new array of objects returned from Engine.js specialRules - https://trello.com/c/6BaGsdhl/14-apa-7-v2-engine

		referenceUniqueIdsInPaper: [],			// Array: each element is a Unique Reference IDs (referenceUniqueID) that have been activally added to the current paper
	},//e:state

	mutations: {
		ACTIVE_VERSION_ID(state, value){
			state.activeVersionId = value;
		},
		REFERENCE_ADD_TO_PAPER(state, referenceUniqueID){
			state.referenceUniqueIdsInPaper.push(referenceUniqueID);
		},
		REFERENCE_REMOVE_FROM_PAPER(state, referenceUniqueID){
			state.referenceUniqueIdsInPaper.splice(state.referenceUniqueIdsInPaper.indexOf(referenceUniqueID),1);
		},
		SET_REFERENCE_UNIQUE_IDS_IN_PAPER(state, references) {
			state.referenceUniqueIdsInPaper = references;
		},
		SET_EDIT_REFERENCE(state, value) {
			state.editReference = value;
		},
		SET_ENCRYPTED_PAPER_LZ_STRING(state, value) {
			state.encryptedPaperLZString = value;
		},
		SET_IS_CITATION_CONTEXT_MENU_OPEN(state, value) {
			state.isCitationContextMenuOpen = value;
		},
		SET_IS_PAPER_UNSAVED(state, value) {
			state.isPaperUnsaved = value;
		},
		SET_IS_PAPER_FOOTER_ON(state, value) {
			state.isPaperFooterOn = value;
		},
		SET_OPEN_OBJECT(state, value){
			state.openObject = value;
		},
		SET_OPEN_REFERENCE_UID(state, value) {
			state.openReferenceUID = value;
		},
		SET_ORGANIZE_TAB_RESEARCH_NOTES_WIDTH_PERCENTAGE(state, value) {
			state.organizeTabResearchNotesWidthPercentage = value;
		},
		SET_ORGANIZE_TAB_WIDTH(state, value) {
			state.organizeTabWidth = value;
		},
		SET_PAPER_DATA_BODY(state, value) {
			state.paperDataBody = value;
		},
		SET_REFERENCES_IN_PAPER(state, references) {
			state.referencesInPaper = references;
		},
	},//e:mutations
}
