<!--
- Add Reference From Library Body
-
-->

<template>
	<!--  -->
	<div class="pt-2 neg-gutter" v-if="isReferenceLibraryShown" key="divAddFromLibraryReferencesInPaper">
		<add-from-library-row
			v-for="referenceData in filteredReferenceLibrary"
			:is-drawer="isDrawer"
			:key="referenceData.referenceUniqueID"
			:reference-unique-id="referenceData.referenceUniqueID.toUpperCase()"
			:reference-data="referenceData" />
	</div>
	<div v-else key="divAddFromLibraryNoReferencesInPaper" class="text-empty mt-5">
		<p>The Reference Library saves all of your PERRLA references for future papers.</p>
		<p>
			<a href="#" @click.prevent="linkToReferenceCreate">
				Create a new reference
			</a>
			and you'll see it saved here for your next paper.</p>
	</div>

</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _find from 'lodash/find';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import AddFromLibraryRow from './AddFromLibraryRow';
import config from '@/config';
import CleanReferenceValue from '@/helpers/clean-reference-value';
import HelperCheckReferenceData from '@/helpers/check-reference-data';
import {v4 as uuidv4} from 'uuid';

export default {
	name: 'AddReferenceFromLibraryBody',
	props:{
		isDrawer:{
			default: true,
			type: Boolean,
		}
	},
	data() {
		return {
			filteredReferenceLibrary: [],
			searchTerm: '',
		}
	},
	computed:{
		isReferenceLibraryShown(){
			if(_isEmpty(this.$store.state.referenceLibrary) && _isEmpty(this.$store.state.referenceLibraryGenesis)){
				return false
			}
			return true;
		},
		PaperFormatVersionID(){
			return this.$store.state.paperEdit.meta.PaperFormatVersionID;
		},
	},
	methods: {
		calcFilterReferenceLibrary(){
			// console.log('calcFilterReferenceLibrary');

			let _data = [];
			let searchedArray = [];
			let isTagSearch = false;
			
			let dataApa6;
			let dataApa7;

			switch(this.PaperFormatVersionID){
				// case config.enums.Format.APA6:
				case config.enums.Format.APA7:
					dataApa6 = _cloneDeep(this.$store.state.referenceLibrary.filter((reference) => {
						return reference.formatVersionID === 2
					}));
					dataApa7 = _cloneDeep(this.$store.state.referenceLibraryGenesis);
					_data = dataApa6.concat(dataApa7);
					break;
				case config.enums.Format.MLA9:
				case config.enums.Format.Turabian9:
					_data = _cloneDeep(this.$store.state.referenceLibraryGenesis);
					break;
			} 

			let _searchTerm = this.searchTerm && this.searchTerm.toLowerCase();
			
			if(typeof _searchTerm !== 'undefined' && _searchTerm.length > 0){
				if(_searchTerm.charAt(0) === '#'){
					isTagSearch = true;
					_searchTerm = _searchTerm.substring(1);
				} 
				_searchTerm = _searchTerm.toLowerCase();
			}

			// append test data
			if(this.$store.state.app.showBetaFeatures){
				let testData = [
					// everything is good
					{
						createdDate: config.today.toISOString(),
						formatVersionID: this.PaperFormatVersionID,
						isGenesis: true,
						isTestData: true,
						referenceTypeID: 1, // Journal
						referenceUniqueID: uuidv4().toUpperCase(),
						values: JSON.stringify({
							"apa7": {
								"value": "5 APA7 Value",
							},
							"mla9": {
								"value": "5 MLA9 Value",
							},
							"turabian9": {
								"value": "5 Turabian 9 Value",
							}
						}),
					},
					// mla9 value is blank
					{
						createdDate: config.today.toISOString(),
						formatVersionID: this.PaperFormatVersionID,
						isGenesis: true,
						isTestData: true,
						referenceTypeID: 1, // Journal
						referenceUniqueID: uuidv4().toUpperCase(),
						values: JSON.stringify({
							"apa7": {
								"value": "4 APA7 Value When MLA9 is blank",
							},
							"mla9": {
								"value": "",
							},
							"turabian9": {
								"value": "4 Turabian9 Value When MLA9 is blank",
							}
						}),
					},
					// turabian9 value is blank
					{
						createdDate: config.today.toISOString(),
						formatVersionID: this.PaperFormatVersionID,
						isGenesis: true,
						isTestData: true,
						referenceTypeID: 1, // Journal
						referenceUniqueID: uuidv4().toUpperCase(),
						values: JSON.stringify({
							"apa7": {
								"value": "3 APA7 Value When Turabian9 is blank",
							},
							"mla9": {
								"value": "3 Journal MLA9 Value When Turabian9 is blank",
							},
							"turabian9": {
								"value": "",
							}
						}),
					},
					// reference is missing a node value for TURABIAN9 and MLA9
					{
						createdDate: config.today.toISOString(),
						formatVersionID: this.PaperFormatVersionID,
						isGenesis: true,
						isTestData: true,
						referenceUniqueID: uuidv4().toUpperCase(),
						referenceTypeID: 1, // Journal
						values: JSON.stringify({
							"apa7": {
								"value": "2 Journal APA7 Value When Turabian9 & MLA9 nodes are both is missing",
							},
						}),
					},

					// Advertisement reference type
					{
						createdDate: config.today.toISOString(),
						formatVersionID: this.PaperFormatVersionID,
						isGenesis: true,
						isTestData: true,
						referenceUniqueID: uuidv4().toUpperCase(),
						referenceTypeID: 5, // Advertisement
						values: JSON.stringify({
							"apa7": {
								"value": "",
							},
							"mla9": {
								"value": "1 MLA 9 has a value - APA7 is blank (Advertisement)",
							},
							"turabian9": {
								"value": "1 Turabian 9 has a value - APA7 is blank (Advertisement)",
							}
						}),
					},
					


				];//e:testData

				// testData.forEach((testDataInfo) =>{
				// 	console.log(testDataInfo);
				// });
				
				_data = _data.concat(testData);
								
			}//e:if:showBetaFeatures
			
		
			// loop through each reference and prep for display
			_data.forEach((ref) =>{
				// skip references that have been deleted from the library
				if(ref.isDeleted){
					return false;
				}
				// console.log(ref);

				let isPush = false;					// boolean to control reference formats that get pushed into the AddFromLibary list
				let displayThisReference = true;	// boolean to keep converted references from displaying
				let refValues = '';

				// don't display referneces already converted
				if(this.PaperFormatVersionID === config.enums.Format.APA7 && ref.formatVersionID === config.enums.Format.APA6){
					// Don't show APA6 references that have already been converted to APA7 (the extra IF lets apa6 papers to still show apa6 refs)
					let isConverted = _find(this.$store.state.referenceLibraryGenesis, ['legacyReferenceId', ref.referenceID])
					if(isConverted){
						displayThisReference = false;
					}
				// } else if(this.PaperFormatVersionID === config.enums.Format.MLA9){
					// Don't show APA7 reference in an MLA9 paper if they have already been converted
					// if(_has(ref, 'values')){
					// 	refValues = JSON.parse(ref.values);
					// 	if(_has(refValues, 'mla9')){
					// 		displayThisReference = true;
					// 		console.log(ref)
					// 	} else {
					// 		displayThisReference = false;
					// 		// console.log(ref)
					// 	}
					// }
				}

				if(displayThisReference){
					// Parse the display name 
					if(_has(ref, 'values')){
						// console.log('ref.values');
						// console.log(ref.values);

						if(refValues === ''){
							refValues = JSON.parse(ref.values);
						}

						ref.isGenesis = true;

						switch(this.PaperFormatVersionID){
							case config.enums.Format.APA7:
								if(_has(refValues, 'apa7') && _has(refValues.apa7, 'value')){
									if(refValues.apa7.value === ''){
										//ref.addFromLibraryMode = config.enums.AddFromLibraryMode.FORMAT_DISABLED;
										ref.addFromLibraryMode = config.enums.AddFromLibraryMode.REVIEW;

										if(_has(refValues, 'mla9') && _has(refValues.mla9, 'value') && refValues.mla9.value != ''){
											ref.displayValue = refValues.mla9.value;
										} else if(_has(refValues, 'turabian9') && _has(refValues.turabian9, 'value')){
											ref.displayValue = refValues.turabian9.value;
										}
										
									} else {
										ref.addFromLibraryMode = config.enums.AddFromLibraryMode.NORMAL;
										ref.displayValue = refValues.apa7.value;
									}

									ref.formatVersionID = this.PaperFormatVersionID;
									ref.legacyReferenceData = '';
									ref.sortByValue = CleanReferenceValue(refValues.apa7.value);
									isPush = true;
								}
								break;
							case config.enums.Format.MLA9:
								if(_has(refValues, 'mla9') && _has(refValues.mla9, 'value')){
									if(refValues.mla9.value === ''){
										//ref.addFromLibraryMode = config.enums.AddFromLibraryMode.FORMAT_DISABLED;
										ref.addFromLibraryMode = config.enums.AddFromLibraryMode.REVIEW;

										if(_has(refValues, 'apa7') && _has(refValues.apa7, 'value') && refValues.apa7.value != ''){
											ref.displayValue = refValues.apa7.value;
										} else if(_has(refValues, 'turabian9') && _has(refValues.turabian9, 'value')){
											ref.displayValue = refValues.turabian9.value;
										}
										
									} else {
										ref.addFromLibraryMode = config.enums.AddFromLibraryMode.NORMAL;
										ref.displayValue = refValues.mla9.value;
									}
									
									ref.formatVersionID = this.PaperFormatVersionID;
									ref.legacyReferenceData = '';
									ref.sortByValue = CleanReferenceValue(refValues.mla9.value);
									isPush = true;
								} else {
									// no MLA Value - use the APA one for now
									if(_has(refValues, 'apa7') && _has(refValues.apa7, 'value')){

										if(refValues.apa7.value === ''){
											//ref.addFromLibraryMode = config.enums.AddFromLibraryMode.FORMAT_DISABLED;
											ref.addFromLibraryMode = config.enums.AddFromLibraryMode.REVIEW;
											ref.displayValue = '';
										} else {
											ref.addFromLibraryMode = config.enums.AddFromLibraryMode.REVIEW;
											ref.displayValue = refValues.apa7.value;
										}
										ref.formatVersionID = config.enums.Format.APA7;
										ref.legacyReferenceData = '';
										ref.sortByValue = CleanReferenceValue(refValues.apa7.value);
										isPush = true;
									}
								}
								break;
							case config.enums.Format.Turabian9:
								if(_has(refValues, 'turabian9') && _has(refValues.turabian9, 'value')){
									if(refValues.turabian9.value === ''){
										//ref.addFromLibraryMode = config.enums.AddFromLibraryMode.FORMAT_DISABLED;
										ref.addFromLibraryMode = config.enums.AddFromLibraryMode.REVIEW;

										if(_has(refValues, 'apa7') && _has(refValues.apa7, 'value') && refValues.apa7.value != ''){
											ref.displayValue = refValues.apa7.value;
										} else if(_has(refValues, 'mla9') && _has(refValues.mla9, 'value')  && refValues.mla9.value != ''){
											ref.displayValue = refValues.mla9.value;
										}
										
									} else {
										ref.addFromLibraryMode = config.enums.AddFromLibraryMode.NORMAL;
										ref.displayValue = refValues.turabian9.value;
									}
									
									ref.formatVersionID = this.PaperFormatVersionID;
									ref.legacyReferenceData = '';
									ref.sortByValue = CleanReferenceValue(refValues.turabian9.value);
									isPush = true;

								} else {
									// no Turabian 9 Value - use APA
									if(_has(refValues, 'apa7') && _has(refValues.apa7, 'value')){
										ref.addFromLibraryMode = HelperCheckReferenceData(ref);
										ref.displayValue = refValues.apa7.value;
										ref.sortByValue = CleanReferenceValue(refValues.apa7.value);
										ref.formatVersionID = config.enums.Format.APA7;
										ref.legacyReferenceData = '';
										isPush = true;
									}

								}
								break;
						}//e:switch

					} else {
						// Everything Else (older formats)
						ref.displayValue = ref.value;
						ref.sortByValue = CleanReferenceValue(ref.value);

						if(this.PaperFormatVersionID === ref.formatVersionID){
							// the paper and this referece are the same format
							ref.addFromLibraryMode = config.enums.AddFromLibraryMode.NORMAL;

						} else {
							// the paper and this referece are different formats
							switch(ref.formatVersionID){
								case config.enums.Format.APA6:
									if(this.PaperFormatVersionID === config.enums.Format.APA7){
										// APA6 reference in an APA7 Paper can be updated
										ref.addFromLibraryMode = config.enums.AddFromLibraryMode.UPDATE;
									} else {
										ref.addFromLibraryMode = config.enums.AddFromLibraryMode.DISABLED;
									}
									break;
								case config.enums.Format.MLA8:
									if(this.PaperFormatVersionID === config.enums.Format.MLA9){
										// MLA8 reference in an MLA9 Paper can be updated
										ref.addFromLibraryMode = config.enums.AddFromLibraryMode.UPDATE;
									} else {
										ref.addFromLibraryMode = config.enums.AddFromLibraryMode.DISABLED;
									}
									break;
							}
						}

						isPush = true;	// push all older references, the display will let the user know if it's status

					}
					
					if(_searchTerm){
						if(isTagSearch){
							// search by tag
							if(ref.tags && ref.tags.toLowerCase().includes(_searchTerm)){
								searchedArray.push(ref);
								isPush = true;
							}
						} else {
							// search by all
							if(
								(ref.displayValue && ref.displayValue.toLowerCase().includes(_searchTerm)) ||
								(ref.legacyReferenceData && ref.legacyReferenceData.toLowerCase().includes(_searchTerm)) ||
								(ref.nickname && ref.nickname.toLowerCase().includes(_searchTerm)) ||
								(ref.tags && ref.tags.toLowerCase().includes(_searchTerm))
							){
								searchedArray.push(ref);
								isPush = true;
							}
						}
					} else {
						// Not searching just pass through
						if(isPush){
							searchedArray.push(ref);
						}
					}//e:_searchTerm

				}//e:if:displayThisReference

				// }//e:if:ref.isTestData
				
			});//e:forEach

			_data = searchedArray;
			
			// Final sort
			if(this.$store.state.gridSorts.addFromLibraryIsByName) {
				this.filteredReferenceLibrary = _sortBy(_data, ['sortByValue']);
			} else {
				this.filteredReferenceLibrary = _sortBy(_data, ['createdDate']).reverse();
			}

		},//e:calcFilterReferenceLibrary

		calcFilterReferenceLibraryPrep(){
			this.filteredReferenceLibrary = [];

			this.$nextTick(()=>{
				this.calcFilterReferenceLibrary();
			});
		},//e:calcFilterReferenceLibraryPrep

		linkToReferenceCreate(){
			if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
				this.$router.push({
					name:'EditorCreateReferenceStep1',
					params: {
						openReferenceTab: 'common'
					}
				});
			} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
				this.$router.push({
					name:'OutlineCreateReferenceStep1',
					params: {
						openReferenceTab: 'common'
					}
				});
			} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.RESEARCH){
				this.$store.commit('modals/CLOSE', 'ResearchNotesAddReferenceFromLibrary')
				
				this.$nextTick(()=>{
					this.emitter.emit('openRouteModal', {
						modalRoute: 'PaperTabResearchCreateReferenceStep1',
						modalParams: {
							openReferenceTab: 'common'
						},
					});
				});
			}
		},//e:linkToReferenceCreate

	},
	created() {
		this.emitter.on('calcFilterReferenceLibrary', ($opts) => {
			if($opts){
				this.searchTerm = $opts.searchTerm;
			}
			this.calcFilterReferenceLibraryPrep();
		});
	},
	// mounted() {
	// 	if(_isEmpty(this.$store.state.referenceLibrary) && _isEmpty(this.$store.state.referenceLibraryGenesis)){
	// 		this.$router.push({
	// 			name: 'EditorReferencesCitations'
	// 		}).catch(()=>{});
	// 	} else {
	// 		// i think this is causing it to calculate twice, comment it out and see if there was a good reason to keep it
	// 		// this.calcFilterReferenceLibraryPrep();
	// 	}
	// },
	components: {
		AddFromLibraryRow,
	},
	destroyed(){
		this.emitter.off('calcFilterReferenceLibrary');
	},
}
</script>
