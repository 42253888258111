<!--
- Dashboard > Work List > Row
-- checklist and what not
-- https://magical-design-system.webflow.io/online/home-full
-->

<template>
	<tr :class="['tr-entity-grid-row tr-entity-grid-primary', {'tr-entity-grid-row-success': isComplete}]">
		<td class="td-entity-icon" width="25px">
			<a href="#" v-show="isComplete" title="Mark Finished" @click.prevent="toggleComplete">
				<span class="svg bi-check-lg" />
			</a>
			<div v-show="!isComplete">
				<a href="#" class="svg" title="Mark Unfinished" @click.prevent="toggleComplete">
					<span class="svg icons8-unchecked-checkbox" />
				</a>
			</div> 
		</td>

		<td class="td-entity-title td-entity-click" @click.prevent="gotoGridElement" title="Open Work Item">
			{{ workDataName }}
		</td>
		<td class="text-end">
			{{ outputWorkDueDate }}
		</td>
	</tr>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import config from '@/config';
import dateToLongString from '@/helpers/date-to-long-string';

export default {
	name: 'WorkRow',
	props:{
		workDataEncryptedId:{
			required: true,
			type: String,
		},
		workDataEntityId:{
			required: true,
			type: Number,
		},
		workDataName:{
			required: true,
			type: String,
		},
		workDataType:{
			required: true,
			type: Number,
		},
		workDataDueDate:{
			type: String,
		},

	},
	data() {
		return {
			isComplete: false,	// local version for checking a row off
		}
	},
	computed:{
		outputWorkDueDate(){
			if(this.workDataDueDate){
				return dateToLongString({
					dateValue: this.workDataDueDate,
					showTime: false,
				});
			}
			return '';
		},
	},
	methods: {
		gotoGridElement(){
			if(this.$isDev){
				alert("Work items can't be loaded locally");
			} else {
				if(this.workDataType === config.enums.WorkType.ASSIGNMENT){
					window.location = '/Assignments/Edit/' + this.workDataEncryptedId;
				} else if(this.workDataType === config.enums.WorkType.TODO){
					window.location = '/ToDos/Edit/' + this.workDataEncryptedId;
				}
			}
		},//e:gotoGridElement

		toggleComplete(){
			ApiS_MakeCall({
				method: 'PUT',
				params:{
					id: this.workDataEntityId,
					isComplete: !this.isComplete,
					workType: this.workDataType
				},
				url: 'v4/Work/MarkComplete',
			}).then(()=>{
				this.isComplete = !this.isComplete;
			});//e:ApiS_MakeCall
		
		},//e:toggleComplete
		
	},
}
</script>
