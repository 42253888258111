<!--
- Single Citation Row in a list of under a ref in this paper
-->

<template>
	<div :class="['mb-1 element-row', {'deleted-reference': isExternal}]">
		<div class="dropstart">
			<a href="#" class="svg bi-three-dots float-end" data-bs-toggle="dropdown" role="button" aria-expanded="false" aria-label="More Options" />
			
			<p v-html="citationObject.displayValue" class="citation-value org-size mb-0 ms-3" />

			<ul class="dropdown-menu">
				<li v-if="isActive" key="liCitationActive">
					<a class="dropdown-item hover hover-default" href="#" @click.prevent="showCitationInPaper">
						<span class="svg bi-search" />
						Show in Paper
					</a>
				</li>
				<li v-else key="liCitationNotActive">
					<a :class="['dropdown-item hover hover-success', {'disabled hover-disabled': isExternal}]" href="#" title="Insert Citation" @click.prevent="insertOrphanCitation">
						<span class="svg bi-plus-lg" />
						Insert Citation
					</a>
				</li>
				<li v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.Turabian9" key="liFootnote">
					<router-link
						:class="['dropdown-item hover hover-primary', {'disabled hover-disabled': isExternal}]"
						:to="{
							name:'EditorCitationEdit',
							params: {
								citationUid: citationObject.citationData.citationUniqueID,
								openCitationTab: $enums.CitationTab.FOOTNOTE,
							}
						}"
						title="Edit Footnote Text">
						<span class="svg bi-pencil-fill" />
						Edit Footnote Text
					</router-link>
				</li>
				<li v-else key="liCitation">
					<router-link
						:class="['dropdown-item hover hover-primary', {'disabled hover-disabled': isExternal}]"
						:to="{
							name:'EditorCitationEdit',
							params: {
								citationUid: citationObject.citationData.citationUniqueID,
								openCitationTab: $enums.CitationTab.CITATION,
							}
						}"
						title="Edit Citation">
						<span class="svg bi-pencil-fill" />
						Edit Citation
					</router-link>
				</li>
				<li>
					<a class="dropdown-item hover hover-danger" href="#" title="Delete Citation" @click.prevent="deleteCitation">
						<span class="svg bi-x-lg" />
						Delete Citation
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import config from '@/config';
import InsertRawHtml from '@/helpers/insert-raw-html';
import PCS_InsertCitation from '@/services/paper/citations/insertCitation';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import PS_SaveDocument from '@/services/paper/saveDocument';

export default {
	name: 'CitationRow',
	props:{
		citationObject: {
			required: true,
			type: Object
		},
		isExternal: {
			type: Boolean
		},
	},
	data() {
		return {
			citationElementInPaper: null,
			isActive: false,	// boolean - tracks if citation is in the paper or not
		}
	},
	methods: {
		deleteCitation(){
			let saveMessage = '';	// resolves back so SaveDocument has a message of what was deleted
			let citationCopyContent;

			if(this.citationElementInPaper){
				// citation is active in paper - remove the ck element

				let isGroupCitation;

				let lookupById = this.citationElementInPaper.$.getAttribute('data-group-unique-id') || '';
				if(lookupById === ''){
					// no group id - check for single
					lookupById = this.citationElementInPaper.$.getAttribute('data-citation-unique-id') || '';
					isGroupCitation = false;
					if(lookupById === ''){
						// no group or single id - just bail
						alert('no group or single citation uid');
					}
				} else {
					// group id
					isGroupCitation = true;
				}

				if(isGroupCitation){
					// Group

					// remove citation from vuex store
					let findCitationIndex = this.$store.state.paperEdit.citations.inPaper.findIndex((citation)=>{
						return citation.citationData.citationUniqueID.toUpperCase() === this.citationObject.citationData.citationUniqueID.toUpperCase();
					});
					if(findCitationIndex !== -1){
						this.$store.commit('paperEdit/citations/REMOVE_AT_INDEX', findCitationIndex);
					}

					// alter ck element
					if (this.citationElementInPaper.getParent()) {
						saveMessage = 'Inside Group';
						
						// i need to know if there are other citations groupped here that still reamain (ex: 3 citations down to 2 should still be grouped)

						// look for any other citation with a matching group uid
						let findOtherGroupCitations = this.$store.state.paperEdit.citations.inPaper.filter((citation)=>{
							return _has(citation, 'groupUniqueID') && citation.groupUniqueID.toUpperCase() === this.citationObject.groupUniqueID.toUpperCase();
						});
						
						// console.log('findOtherGroupCitations');
						// console.log(findOtherGroupCitations);

						if(findOtherGroupCitations && findOtherGroupCitations.length <= 1){
							// there is only one citation left now, officially degroup it

							// a citation was found with a matching group uid
							if(findOtherGroupCitations && findOtherGroupCitations[0]){
								this.citationElementInPaper.$.setAttribute('data-citation-unique-id', findOtherGroupCitations[0].citationData.citationUniqueID.toUpperCase());
								this.citationElementInPaper.$.setAttribute('data-reference-unique-id', findOtherGroupCitations[0].citationData.referenceUniqueID.toUpperCase());
							}

							this.citationElementInPaper.$.removeAttribute('data-group-unique-id');
						}
						

					} else {
						console.log('There was a problem removing the citation from this paper');
					}

				} else {
					// Single

					// remove citation from vuex store
					let findCitationIndex = this.$store.state.paperEdit.citations.inPaper.findIndex((citation)=>{
						return citation.citationData.citationUniqueID.toUpperCase() === lookupById.toUpperCase();
					});
					if(findCitationIndex !== -1){
						citationCopyContent = _cloneDeep(this.$store.state.paperEdit.citations.inPaper[findCitationIndex]);
						this.$store.commit('paperEdit/citations/REMOVE_AT_INDEX', findCitationIndex);
					}
					
					// remove footnote from vuex store
					if(this.$store.state.paperEdit.config.renderCitationsAsFootnotes){
						this.$store.commit('paperEdit/footnotes/REMOVE_AT_CITATION_UID', lookupById.toUpperCase());
					}
					
					// remove the ck element
					if (this.citationElementInPaper && this.citationElementInPaper.getParent()) {
						// saveMessage = this.citationElementInPaper.getHtml();

						let $elToInsert = new CKEDITOR.dom.element('span');

						if(this.$store.state.paperEdit.config.renderCitationsAsFootnotes){
							// footnotes have a superscript number instead of a citation value
							if(_has(citationCopyContent, 'turabian9') && _has(citationCopyContent.turabian9, 'first')){
								saveMessage = citationCopyContent.turabian9.first;
							} else {
								saveMessage = 'Turabian 9 Citation';
							}

							$elToInsert.setText('CITATION DELETED');
							
						} else {
							saveMessage = this.citationElementInPaper.getHtml();

							if(this.$store.state.paperEdit.meta.UseNewCitationMode){
								$elToInsert.setText('CITATION DELETED');
							} else {
								$elToInsert.setText('(CITATION DELETED)');
							}

						}

						$elToInsert.replace(this.citationElementInPaper.getParent());
						
					} else {
						console.log('There was a problem removing the citation from this paper');
					}

				}//e:if:group|single
				
			} else {
				// citation was not active
				let findCitationIndex = this.$store.state.paperEdit.citations.inPaper.findIndex((citation)=>{
					return citation.citationData.citationUniqueID.toUpperCase() === this.citationObject.citationData.citationUniqueID.toUpperCase();
				});
				if(findCitationIndex !== -1){
					this.$store.commit('paperEdit/citations/REMOVE_AT_INDEX', findCitationIndex);
				}
				
				// still get a saveMessage  	
				if(_has(this.citationObject, 'apa7') && _has(this.citationObject.apa7, 'first')){
					saveMessage = this.citationObject.apa7.first;
				}
				
			}//e:if:else

			PS_BuildReferencesInPaper().then(()=>{
				PS_SaveDocument({
					message: 'Deleted Citation - ' + saveMessage,
				});
			});//e:PS_BuildReferencesInPaper
		},//e:deleteCitation

		checkIfCitationIsActive(){
			this.citationElementInPaper = null;
			this.isActive = false;
			
			_forEach(CKEDITOR.instances, (ckInstance)=>{
				if(ckInstance.document){
					// look for group citation first
					let returnElement = ckInstance.document.findOne('span[data-group-unique-id="' + this.citationObject.groupUniqueID + '"]');

					if(returnElement){
						this.isActive = true;
						this.citationElementInPaper = returnElement;
						return false; // stop looping, i only need one element
					} else {
						// no group found, look for single citation
						returnElement = ckInstance.document.findOne('span[data-citation-unique-id="' + this.citationObject.citationData.citationUniqueID + '"]');
						if(returnElement){
							this.isActive = true;
							this.citationElementInPaper = returnElement;
							return false; // stop looping, i only need one element
						}
					}
				}
			});//e:forEach

		},//e:checkIfCitationIsActive
		
		// insert a citation that is currently not in the paper (user could delete it from the paper by not using the proper remove/delete methods)
		insertOrphanCitation(){
			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
				// APA7 
				if(!_has(this.citationObject, 'apa7') || !_has(this.citationObject.apa7, 'first') || this.citationObject.apa7.first === ''){
					return false;
				}
			} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
				// MLA9
				if(!_has(this.citationObject, 'mla9') || !_has(this.citationObject.mla9, 'first') || this.citationObject.mla9.first === ''){
					return false;
				}
			} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
				// Turabian9
				if(!_has(this.citationObject, 'turabian9') || !_has(this.citationObject.turabian9, 'first') || this.citationObject.turabian9.first === ''){
					return false;
				}
			}

			let isInsertTextInsteadOfCitation = false;

			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
				let path = CKEDITOR.instances.ckPaperBody.elementPath();
				let tdElement = path.contains('td', 1);
				if(tdElement && tdElement.hasClass('td-source')){
					isInsertTextInsteadOfCitation = true;
				}
			}//e:if.paperFormatVersionID

			if(isInsertTextInsteadOfCitation){
				InsertRawHtml.insertText(this.citationObject.turabian9.first);

				this.$router.push({
					name: 'EditorReferencesCitations'
				}).catch(()=>{});

			} else {

				// Insert as Citation (it will figure out the format and footnote stuff)
				PCS_InsertCitation({
					'citationUniqueID': this.citationObject.citationData.citationUniqueID,
					'referenceUniqueID': this.citationObject.citationData.referenceUniqueID,
				}).then((newGroupUniqueID)=>{
					if(this.$store.state.paperEdit.config.renderCitationsAsFootnotes){
						// insert this citation in the footnote content
						this.$store.dispatch('paperEdit/footnotes/addToPaper', {
							content: '<p>@@@</p>',
							findById: newGroupUniqueID,
						}).then(()=>{
							PS_BuildReferencesInPaper().then(()=>{
								PS_SaveDocument({
									isMetaDataChanges: true,
									message: 'Inserted Citation - ' + this.citationObject.displayValue,
								}).then(()=>{

								}).catch((error)=>{
									console.log(error);
									this.$store.commit('loaders/REMOVE_ID', 'App');
								});//e:PS_SaveDocument
							});//e:PS_BuildReferencesInPaper

						});

					} else {
						// go right into save process
						PS_BuildReferencesInPaper().then(()=>{
							PS_SaveDocument({
								isMetaDataChanges: true,
								message: 'Inserted Citation - ' + this.citationObject.displayValue,
							}).then(()=>{

							}).catch((error)=>{
								console.log(error);
								this.$store.commit('loaders/REMOVE_ID', 'App');
							});//e:PS_SaveDocument
						});//e:PS_BuildReferencesInPaper

					}

				}).catch(()=>{
					console.log('CitationRow - error');
					this.$router.push({
						name: 'EditorReferencesCitations'
					}).catch(()=>{});
				});//e:PCS_InsertCitation

			}

		},//e:insertOrphanCitation

		showCitationInPaper(){
			// console.log(this.citationElementInPaper);
			// check to see if a real editor is found, the citation could have been manually deleted and can't be selected now
			let $thisEditor = this.citationElementInPaper.getEditor(false);

			if($thisEditor){
				this.citationElementInPaper.$.scrollIntoView();
				$thisEditor.getSelection().selectElement(this.citationElementInPaper)
			}
		},//e:showCitationInPaper
	},
	created(){
		// $emits from ReferencesCitations.vue > setPanelScroll()
		this.emitter.on('checkIfCitationIsActive', ($opts) => {
			if($opts.citationUniqueId === this.citationObject.citationData.citationUniqueID.toUpperCase()){
				this.checkIfCitationIsActive();
			}
		});
	},
	mounted(){
		// 2023.08.31 - i'm calling this for the Refs & Cits panel, so not sure why i'm calling it again here - leave it commented out for now
		// if(!_isEmpty(CKEDITOR.instances)){
		// 	this.$nextTick(()=>{
		// 		this.checkIfCitationIsActive();
		// 	});
		// }
	},
	destroyed(){
		this.emitter.off('checkIfCitationIsActive');
	},
}
</script>


<style lang="scss" scoped>
@import "../../../../../assets/styles/vars_perrla";

@include color-mode(dark) {
	.element-row{
		border: 1px solid $dark-body-bg;

		&:hover {
			background: $extra-dark;
			border-color: $secondary-500;
		}
	}


}

.element-row{
	border-radius: $border-radius;
	border: 1px solid $body-bg;
	padding: 0.25rem;
	
	&:hover {
		background: $card-bg;
		border-color: $primary-300;
	}

}
</style>