<!--
- References > List > Row
-- clickable title to open a document for editing
-->

<template>
	<tr :class="['tr-entity-grid-row tr-entity-grid-primary']">
		<td class=" td-entity-title td-entity-click">
			<div v-html="outputReferenceValue" class="hanging-indent word-break" @click.prevent="gotoEditReference" title="Open Reference" />
		</td>
		<td class="table-spacer" />
		<td width="160px">
			{{ outputCreatedDate }}
		</td>
		<td class="td-more-menu">
			<div class="dropstart">
				<a href="#" :class="['svg bi-three-dots-vertical float-end']" data-bs-toggle="dropdown" aria-expanded="false" aria-label="More Options" />
				<ul class="dropdown-menu">
					<li>
						<a class="dropdown-item hover hover-primary" @click.prevent="copyOutputReferenceValue" href="#">Copy Text</a>
					</li>
					<li>
						<a class="dropdown-item hover hover-danger" @click.prevent="deleteReferenceShowConfirm" href="#">Archive Reference</a>
					</li>
				</ul>
			</div>
		</td>
	</tr>
</template>

<script>
import _has from 'lodash/has';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import CopyToClipboard from 'copy-to-clipboard';
import dateToLongString from '@/helpers/date-to-long-string';
import mixinId from '@/mixins/id';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import RS_GetValueOutput from '@/services/reference/getValueOutput';
import RS_Compress from '@/services/reference/compress';
import Swal from 'sweetalert2';

export default {
	name: 'ReferenceRow',
	mixins: [mixinId],
	props:{
		referenceData:{
			required: true,
			type: Object,
		},
	},
	computed:{
		outputCreatedDate(){
			if(_has(this.referenceData, 'createdDate')){
				return dateToLongString({
					dateValue: this.referenceData.createdDate,
					showTime: false,
				});
			}
			return '';
		},
		outputReferenceValue(){
			let refValue = RS_GetValueOutput({
				displayFormatVersionId: this.$store.state.references.displayFormatVersionId,
				referenceData: this.referenceData,
			});
			if(refValue){
				return refValue;
			}
			return '';
		},
	},
	methods: {
		copyOutputReferenceValue(){
			let $this = this;

			CopyToClipboard(this.outputReferenceValue, {
				format: 'text/html',
				onCopy(){
					$this.emitter.emit('globalToasterOpen',{
						textContent: 'Copied to clipboard',
					});
				},
			});
		},

		deleteReferenceShowConfirm(){
			Swal.fire({
				allowOutsideClick: false,
				buttonsStyling: false,
				title: 'This will remove it from your library permanently.  Are you sure?',
				text: 'Archiving your reference will remove it from your library. It will be disabled in any papers where it was used.',
				icon: 'warning',
				cancelButtonText: 'Cancel',
				confirmButtonText: 'Delete it',
				showCloseButton: false,
				showCancelButton: true,
				showConfirmButton: true,
				customClass:{
					cancelButton: 'btn btn-outline-danger',
					confirmButton: 'btn btn-success me-2',
				},
			}).then((result) => {
				if (result.value) {
					
					// Genesis Reference (default)
					let fetchUrl = 'v3/GenesisReference?referenceID=' + this.referenceData.referenceID;

					if(!_has(this.referenceData, 'values')){
						// Non Genesis Reference
						fetchUrl = 'v3/Reference?referenceID=' + this.referenceData.referenceID;
					}

					ApiS_MakeCall({
						method: 'DELETE',
						url: fetchUrl,
					}).then(()=>{
						this.$store.commit('REFERENCE_REMOVE_FROM_LIBRARY', this.referenceData.referenceUniqueID.toUpperCase());

						PS_BuildReferencesInPaper().then(()=>{
							// dispatch to compress the referenceLibrary
							RS_Compress({
								isGenesis: (_has(this.referenceData, 'values')) ? true : false,
							}).then(()=>{
								this.$emit('update-grid');
								
								this.emitter.emit('globalToasterOpen',{
									textContent: 'Reference successfully deleted',
								});
							});
						});
					});//e:ApiS_MakeCall
				}
			});
		},//e:deletePaperShowConfirm

		gotoEditReference(){
			if(_has(this.referenceData, 'values')){
				// genesis reference
				this.$router.push({
					name:'ReferenceTabPapers',
					params:{
						referenceUid: this.referenceData.referenceUniqueID.toUpperCase()
					}
				}).catch(()=>{});

			} else {
				// legacy reference
				if(this.$isDev){
					alert('APA6/MLA8 references cant do that locally');
					console.log(this.referenceData);
				} else {
					// clearing local storage here will force a full resync if the user deletes or edits and APA6 reference then comes back
					localStorage.clear();
					this.$store.commit('loaders/ADD_ID', 'App');
					window.location = '/References/Edit/' + this.referenceData.referenceID;
				}
			}//e:if
		},
	},
}
</script>
