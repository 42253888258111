<!--
- Research Notes Module > Research Note Row
-- View version / read only 
-- add drag functionality 
-- can be called from a ToolDrawer
-->

<template>
	<div
		draggable="true"
		:class="['clearfix mb-2 px-1 py-2 research-note-row',
			{
				'research-note-row-in-paper': isActive
			}]"
		:id="calcResearchNoteRowId"
		@mouseover="isHover = true"
		@mouseleave="isHover = false">

		<div class="svg-wrp">
			<a href="#" class="svg-link svg-link-add float-end" @click.prevent="btnAddResearchNote" aria-label="Add Research Note">
				<span class="svg bi-plus-lg" />
			</a>

			<a href="#" v-if="$router.currentRoute.meta.tab === $enums.Tab.EDITOR" class="svg-link svg-link-toggle float-end" @click.prevent="toggleIsActive" title="Toggle Checked">
				<span class="svg bi-check-lg" />
			</a>
			<a href="#" v-else-if="$router.currentRoute.meta.tab === $enums.Tab.OUTLINE" class="svg-link svg-link-toggle float-end" @click.prevent="toggleIsActive" title="Toggle Checked">
				<span class="svg bi-check-lg" />
			</a>
		</div>

		<div
			class="research-note-text mx-1"
			title="Drag into your paper"
			v-html="researchNoteText" />

		<p
			v-if="calcCitationOutput != ''"
			:class="['research-note-citation mb-0 float-end']">
			<span v-html="calcCitationOutput" />
		</p>
	
	</div>
</template>

<script>
import _has from 'lodash/has';
import config from '@/config';
import HelperGetCitationOutputForResearchNote from '@/helpers/get-citation-output-for-research-note';
import InsertRawHtml from '@/helpers/insert-raw-html';
import PCKS_WrapCitation from '@/services/paper/ck/wrapCitation';
import PCS_InsertCitation from '@/services/paper/citations/insertCitation';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import PS_SaveDocument from '@/services/paper/saveDocument';
import {v4 as uuidv4} from 'uuid';
import Vue from 'vue';

export default {
	name: 'ResearchNoteRowLive',
	props:{
		researchNoteCitation:{
			required: true,
			type: String,
		},
		researchNoteCitationOutput:{
			required: true,
			type: String,
		},
		researchNoteGenesisReferenceId:{
			required: true,
			type: Number
		},
		researchNoteReferenceUniqueId:{
			required: true,
			type: String,
		},
		researchNoteGenesisResearchNoteId:{
			required: true,
			type: Number,
		},
		researchNoteGenesisResearchNoteUniqueId:{
			required: true,
			type: String,
		},
		
		researchNoteText:{
			required: true,
			type: String,
		},
	},
	data() {
		return {
			isHover: false,
			
			
		}
	},
	computed:{
		calcCitationOutput(){
			if(this.researchNoteCitation){
				return HelperGetCitationOutputForResearchNote(this.researchNoteCitation);
			}

			return '';
		},

		calcResearchNoteRowId(){
			return 'DragResearchNoteRow_' + this.researchNoteGenesisResearchNoteId;
		},
		isActive(){
			if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
				let findIndex = this.$store.state.paperEdit.researchNotes.activeResearchNotes.findIndex((researchNoteData)=>{
					return researchNoteData.noteId === this.researchNoteGenesisResearchNoteId && researchNoteData.paperId === this.$store.state.paperEdit.openObject.paperID
				});
				if(findIndex > -1){
					return true;
				}
			} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
				let findIndex = this.$store.state.paperEdit.researchNotes.activeOutlineResearchNotes.findIndex((researchNoteData)=>{
					return researchNoteData.noteId === this.researchNoteGenesisResearchNoteId && researchNoteData.paperId === this.$store.state.paperEdit.openObject.paperID
				});
				if(findIndex > -1){
					return true;
				}
			}

			return false;
		},
	},
	methods:{
		btnAddResearchNote(){
			let insertText = '<p>' + this.researchNoteText + '&nbsp;</p>';
			
			InsertRawHtml.insertText(insertText);
		
			let citationResultObject;

			if(this.researchNoteCitation){
				citationResultObject = JSON.parse(this.researchNoteCitation);
				
				// not sure why i only have apa7 here, should expand to be based on the type of paper im in maybe?

				if(citationResultObject && citationResultObject.apa7 && citationResultObject.apa7.first){
					citationResultObject.citationData['citationUniqueID'] = uuidv4().toUpperCase();
					citationResultObject.citationData['referenceUniqueID'] = this.researchNoteReferenceUniqueId.toUpperCase();
				
					if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
						this.$store.commit('paperEdit/citations/ADD_TO_PAPER', citationResultObject);
						
						// Insert as Citation (it will figure out the format and footnote stuff)					
						PCS_InsertCitation({
							'citationUniqueID': citationResultObject.citationData.citationUniqueID,
							'referenceUniqueID': this.researchNoteReferenceUniqueId.toUpperCase(),
						}).then(()=>{
							// insert this citation in the footnote content
							if(this.$store.state.paperEdit.config.renderCitationsAsFootnotes){
								this.$store.commit('paperEdit/footnotes/ADD_TO_PAPER', {
									citationUids: [citationResultObject.citationData.citationUniqueID],
									content: '<p>@@@</p>',
								});
							}

							PS_BuildReferencesInPaper().then(()=>{
								PS_SaveDocument({
									isMetaDataChanges: true,
									message: 'Inserted Research Note with Citation',
								}).then(()=>{
									this.$store.commit('paperEdit/researchNotes/RESEARCH_NOTE_ADD_ACTIVE', this.researchNoteGenesisResearchNoteId);

								}).catch((error)=>{
									console.log(error);
									this.$store.commit('loaders/REMOVE_ID', 'App');
								});
							});

						}).catch(()=>{
							console.log('RowView - error');
							this.$router.push({
								name: 'EditorReferencesCitations'
							}).catch(()=>{});
						});//e:PCS_InsertCitation

					} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
						let citationDataBase64 = window.btoa(JSON.stringify(citationResultObject).replace(/[\u00A0-\u2666]/g, function(c) {
							return '&#' + c.charCodeAt(0) + ';';
						}));

						CKEDITOR.instances[this.$store.state.paperEdit.ck.editorLastFocused].execCommand('outlineCitation', {
							startupData: {
								'citationDataBase64': citationDataBase64,
								'citationUniqueID': citationResultObject.citationData.citationUniqueID,
								'referenceUniqueID': this.researchNoteReferenceUniqueId.toUpperCase(),
								'valueFirst': this.researchNoteCitationOutput,
								'valueSubsequent': this.researchNoteCitationOutput,
							}
						});

						// after the citation is inserted update the values (this pays off when you have a group author with abbreviation and subsequent citations)
						Vue.nextTick(()=>{
							PS_BuildReferencesInPaper().then(()=>{
								// CKEDITOR.instances.ckPaperBody.execCommand[this.$store.state.paperEdit.ck.editorLastFocused].execCommand('autocorrect');

								if(this.researchNoteCitation){
									PCKS_WrapCitation({
										citationUniqueID: citationResultObject.citationData.citationUniqueID,
									});
								}
								
								this.$store.commit('paperEdit/researchNotes/OUTLINE_RESEARCH_NOTE_ADD_ACTIVE', this.researchNoteGenesisResearchNoteId);
							});
						});
					}
				}
			}//e:this.researchNoteCitation
		
		},//e:btnAddResearchNote

		toggleIsActive(){
			if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
				this.$store.commit('paperEdit/researchNotes/RESEARCH_NOTE_TOGGLE_ACTIVE', this.researchNoteGenesisResearchNoteId);
			} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
				this.$store.commit('paperEdit/researchNotes/OUTLINE_RESEARCH_NOTE_TOGGLE_ACTIVE', this.researchNoteGenesisResearchNoteId);
			}
		},//e:toggleIsActive
	},
	mounted(){
		// // register the draggable items in this reference row with ckEditor so they will go in the paper body or abstract
		CKEDITOR.document.getById(this.calcResearchNoteRowId).on('dragstart', (dragstartEvt) => {
			CKEDITOR.plugins.clipboard.initDragDataTransfer(dragstartEvt);

			let dataTransfer = dragstartEvt.data.dataTransfer;

			if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
				dataTransfer.setData('researchNoteFull', {
					citation: this.researchNoteCitation,
					note: this.researchNoteText,
					referenceUniqueID: this.researchNoteReferenceUniqueId.toUpperCase(),
					researchNoteId: this.researchNoteGenesisResearchNoteId
				});
				
			} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
				dataTransfer.setData('researchNoteFull', {
					citation: this.researchNoteCitation,
					note: this.researchNoteText,
					referenceID: this.researchNoteGenesisReferenceId,
					referenceUniqueID: this.researchNoteReferenceUniqueId.toUpperCase(),
					researchNoteId: this.researchNoteGenesisResearchNoteId
				});
			}

			// text note only fallback
			dataTransfer.setData('text/html', this.researchNoteText);
		});
	},
}
</script>


<style lang="scss">
	@import "../../assets/styles/vars_perrla.scss";
	
	@include color-mode(dark) {
		.research-note-row{
			background: $extra-dark;
			border: 1px solid $extra-dark;
			
			&:hover{
				border-color: $secondary-500;
			}

			div.svg-wrp {
				background-color: $extra-dark;

				a.svg-link{
					&.svg-link-add {
						background: $dark-primary;
						&:hover {
							background-color: $extra-dark;
							color: $dark-primary;
						}
					}
					&.svg-link-toggle {
						background: $success;
						&:hover {
							background-color: $extra-dark;
							color: $success;
						}
					}
				}
			}

			&.research-note-row-in-paper{
				background: $success-300;
				border-color: $success-300;
				
				div.svg-wrp {
					background-color: $success-300;
				}

				&:hover {
					border-color: $success-300;

					div.svg-wrp {
						a.svg-link{
							&.svg-link-add {
								&:hover {
									background-color: $success-300;
								}
							}
							&.svg-link-toggle {
								&:hover {
									background-color: $success-300;
								}
							}
						}
					}
				}

				.research-note-text {
					color: $success-700;
				}

			}
		}
	}

	.research-note-row {
		background-color: $card-bg;
		border: 1px solid $card-bg;
		border-radius: $border-radius;
		cursor: move;
		margin-bottom: 0.75rem;
		padding: 0.5rem 0.5rem 1rem;
		position: relative;

		&:hover{
			border-color: $primary-300;
		}

		div.svg-wrp {
			background-color: $card-bg;
			padding-left: 0.5rem;
			position: absolute;
			right: 0.5rem;
			top: 0.5rem;
			visibility: hidden;

			a.svg-link{
				border-radius: $border-radius;
				color: $body-bg;
				float: right;
				
				&.svg-link-add {
					background: $primary;
					margin-left: 4px;
					&:hover {
						background-color: $card-bg;
						color: $primary;
					}
				}
				&.svg-link-toggle {
					background: $success;
					&:hover {
						background-color: $card-bg;
						color: $success;
					}
				}

				.svg {
					font-size: 16px;
					line-height: 1;
					padding: 0.25rem 0.25rem 0.25rem 0.25rem;
				}
			}
		}

		&:hover{
			div.svg-wrp {
				visibility: visible;
			}
		}
	
		.research-note-text {
			font-size: 1rem;
			line-height: 1.5rem;
			padding-bottom: 0.5rem;
		}

		.research-note-citation {
			color: $success-500;
			font-size: 0.8rem;
			font-weight: 400;
			line-height: 0.8rem;
		}

		&.research-note-row-in-paper{
			background: $success-100;
			border-color: $success-100;
			
			div.svg-wrp {
				background-color: $success-100;
			}

			&:hover {
				border-color: $success-300;

				div.svg-wrp {
					a.svg-link{
						&.svg-link-add {
							&:hover {
								background-color: $success-100;
							}
						}
						&.svg-link-toggle {
							&:hover {
								background-color: $success-100;
							}
						}
					}
				}
			}
		}
		
	}
		
	
</style>

