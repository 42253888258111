var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { class: ["tr-entity-grid-row tr-entity-grid-primary"] }, [
    _c("td", { staticClass: "td-entity-title td-entity-click" }, [
      _c("div", {
        staticClass: "hanging-indent word-break",
        attrs: { title: "Open Reference" },
        domProps: { innerHTML: _vm._s(_vm.outputReferenceValue) },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.gotoEditReference.apply(null, arguments)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "table-spacer" }),
    _vm._v(" "),
    _c("td", { attrs: { width: "160px" } }, [
      _vm._v("\n\t\t" + _vm._s(_vm.outputCreatedDate) + "\n\t"),
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "td-more-menu" }, [
      _c("div", { staticClass: "dropstart" }, [
        _c("a", {
          class: ["svg bi-three-dots-vertical float-end"],
          attrs: {
            href: "#",
            "data-bs-toggle": "dropdown",
            "aria-expanded": "false",
            "aria-label": "More Options",
          },
        }),
        _vm._v(" "),
        _c("ul", { staticClass: "dropdown-menu" }, [
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-primary",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.copyOutputReferenceValue.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Copy Text")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-danger",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.deleteReferenceShowConfirm.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Archive Reference")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }